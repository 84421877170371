import devLog from "../log"
import { GenericObjectStore, type GenericObject, type MenuEntry, type Label, type ItemlistDetail, type GetPropertiesObjectList } from "../genericObjectStore"
import router from "@/router/router"
import { T } from "../i18n"
import requestHandler from "@/queries/requests"
import Button from "../buttons"
import tenantHelpers from '@/helpers/helpers.tenants'
import config from '../config'

export interface VppLicense {
  "adamId":number,
  "adamIdStr":string,
  "clientUserIdStr":string,
  "isIrrevocable":boolean,
  "itsIdHash":string,
  "licenseIdStr":string,
  "pricingParam": "STDQ"|"PLUS", // Standard quality | High Quality
  "productTypeId": 7 | 8 | 10, // 7 = macOS software, 8 = iOS or macOS app from the App Store, 10 Book
  "productTypeName":string,
  "serialNumber":string,
  "status": "Associated" | "Available" | "Refunded",
  "userId": number,
  "userIdStr": string
}
export interface VppUserOnRegister {
  "clientUserIdStr":string,
  "managedAppleIDStr"?:string,
  "email"?: string,
  "userId"?: number
}

export interface VppUser extends GenericObject<VppUser> {
  "clientUserIdStr": string,
  "email"?:string,
  "inviteCode"?:string,
  "inviteUrl"?:string,
  "itsIdHash"?:string,
  "licenses"?:VppLicense[]
  "status"?: "Registered" | "Associated" | "Retired" | "Deleted",
  "userId"?: number,
}

export default class VppUsers extends GenericObjectStore<VppUser> {
  constructor() {
    super()
    this.settings.primaryKeyProperty = this.settings.nameProperty.primary = "clientUserIdStr"
    this.settings.productType = "mobileSecurity"
    this.settings.objectType = "vppUsers"
    this.settings.appearance.iconClass = "fal fa-user"
    this.settings.appearance.text.singular = "User"
    this.settings.appearance.text.plural = "Users"
    this.settings.appearance.text.title = "Users"
    this.settings.apiInfo.listPath = "/tenants/{tenantDomain}/users"
    this.settings.apiInfo.objectListPropertyInResponse = "users"

    this.itemlist.getToolbar = (accountId, itemlistComponent) => {
      let toolBarEntries = []
      return []
    }

    // setup itemlist options
    this.itemlist.isDisabled = (accountId, item): boolean => {
      return false
    }
    this.itemlist.isClickable = (accountId, item): boolean => {
      return true
    }
    this.itemlist.onClick = (accountId: string, item) => {
      let objectId = item[this.settings.primaryKeyProperty] as string
      router.navigate('edit-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-user-' + objectId)
    }
    this.itemlist.hasCheckBox = true
    this.itemlist.getTitle = (item) => {
      return {
        title: item.clientUserIdStr
      }
    }
    this.itemlist.getStatus = (accountId: string, item) => {
      return undefined
    }

    this.itemlist.getMenuEntries = (accountId: string, item) => {
      let menuEnties: MenuEntry[] = []
      if (item) {
        let objectId = item[this.settings.primaryKeyProperty] as string
        menuEnties.push(
          new Button({
            "text": T('Edit'),
            "title": T('Edit'),
            "icon": 'fal fa-edit',
            "onClick": () => {
              router.navigate('edit-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-user-' + objectId)
            }
          }),
          new Button({
            "text": T('Delete'),
            "title": T('Delete'),
            "icon": 'fal fa-trash',
            "onClick": () => {
              this.dialogs.getDeleteObjectDialog(accountId, item)
            }
          })
        )
      }

      return menuEnties
    }
    this.itemlist.getLabels = (accountId: string, item) => {
      let result: Label[] = []
      return result
    }
    this.itemlist.getDetails = (accountId: string, item?) => {
      let result: ItemlistDetail[] = []     
      return result
    }

    this.itemlist.sortingOptions = [{
      "id": "clientUserIdStr",
      "text": "Apple ID"
    }]
  }

  async getCountFromApi(accountId: string, updateLocalStore: boolean = true) {
    let result: number | Error = 0
    try {
      const thisResult = await requestHandler.request("GET", config.mgtApiUri + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/ios/vpp/getUsersSrvUrl")
      if (typeof thisResult.totalCount === "number") {
        result = thisResult.totalCount
        if (updateLocalStore) {
          this.setCount(accountId, result as number)
        }
      }
      else if (thisResult.error) {
        throw new Error(thisResult.error)
      }
      else {
        throw new Error('Error getting count for users')
      }
    }
    catch(e:any) {
      if (e instanceof Error) {
        result = new Error(e.message)
      }
      else {
        result = new Error('Error getting count for users')
      }
      devLog.log("VppUsersStoreClass", result.message, result, "error")
    }
    return result
  }

  async getObjectsFromApi(accountId: string, props?: GetPropertiesObjectList | undefined, updateLocalStore?: boolean, newApi?: boolean, batchToken?: string, sinceModifiedToken?: string, includeRetired?: string, includeRetiredOnly?: string): Promise<VppUser[] | Error> {
    let result : VppUser[]|Error = []
    try {
      const propertiesString: string = props ? this.getPropertiesString(props) : ""

      let payload: any = {
        "batchToken": batchToken || null,
        "sinceModifiedToken": sinceModifiedToken || null,
        "includeRetired": includeRetired || 1,
        "includeRetiredOnly": includeRetiredOnly || false,
        "ifModifiedSince": null,
        "overrideIndex": null
      }

      const response = await requestHandler.request("POST", config.mgtApiUri + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/ios/vpp/getUsersSrvUrl" + propertiesString, payload)
      if (response.users) {
        result = response.users as VppUser[]
        if (updateLocalStore) {
          this.addOrUpdateObjectsInStore(accountId, result) // Add VppUser to store
        }
      }
      else if (response.error) {
        throw new Error(response.error)
      }
      else {
        throw new Error('Error getting VppUsers')
      }
    }
    catch(e:any) {
      if (e instanceof Error) {
        result = new Error(e.message)
      }
      else {
        result = new Error('Error getting VppUsers')
      }

      devLog.log("VppVppUsersStoreClass", result.message, result, "error")
    }
    return result
  }

  async getObjectFromApi(accountId: string, objectId: VppUser["clientUserIdStr"], props?: GetPropertiesObjectList, updateLocalStore: boolean = true, userId?: VppUser['userId'], itsIdHash?: string) {
    let result: VppUser | Error
    try {
      const propertiesString: string = props ? this.getPropertiesString(props) : ""

      let payload: any = {
      }
      if (userId) { payload.userId = userId }
      if (objectId) { payload.clientUserIdStr = objectId }
      if (itsIdHash) { payload.itsIdHash = itsIdHash }

      const response = await requestHandler.request("POST", config.mgtApiUri + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/ios/vpp/getUserSrvUrl" + propertiesString,payload)
      if (response.user) {
        result = response.user as VppUser
        if (updateLocalStore) {
          this.addOrUpdateObjectsInStore(accountId, result) // Add User to store
        }
      }
      else if (response.error) {
        throw new Error(response.error)
      }
      else {
        throw new Error('Error getting VppUser')
      }
    }
    catch(e:any) {
      if (e instanceof Error) {
        result = new Error(e.message)
      }
      else {
        result = new Error('Error getting VppUser')
      }

      devLog.log("VppUsersStoreClass", result.message, result, "error")
    }
    return result
  }

  async addObjectToApi(accountId: string, object: VppUserOnRegister, updateLocalStore?: boolean): Promise<VppUser | Error> {
    let result: VppUser | Error
    try {
      
      if(object.email && !object.managedAppleIDStr) {
        object.managedAppleIDStr = object.email
      }
      else if (!object.email && object.managedAppleIDStr) {
        object.email = object.managedAppleIDStr
      }

      const response = await requestHandler.request("POST", config.mgtApiUri + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/ios/vpp/registerUserSrvUrl", object)

      if (response.status == 0) {
        result = response.user as VppUser
        if (updateLocalStore) {
          this.addOrUpdateObjectsInStore(accountId, result) // Add VppUser to store
        }
      }
      else if (response.errors) {
        throw response.errors
      }
      else if (response.error) {
        throw new Error(response.error)
      }
      else {
        throw new Error('Error adding VppUser')
      }
    }
    catch (e:any) {
        result = new Error(e.message)
        devLog.log("VppUsersStoreClass", result.message, result, "error")
    }
    return result
  }

  async updateObjectFromApi(accountId: string, objectId: VppUser["clientUserIdStr"], object: VppUserOnRegister, updateLocalStore?: boolean, updateProperties?: boolean | undefined, properties?: string[] | undefined): Promise<VppUser | Error> {
    let result: VppUser | Error 
    try {
      const response = await requestHandler.request("POST", config.mgtApiUri + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/ios/vpp/editUserSrvUrl", object)
      if (response.status == 0) {
        result = response.user as VppUser
        if (updateLocalStore) {
          this.addOrUpdateObjectsInStore(accountId, result) // Add VppUser to store
        }
      }
      else if (response.errors) {
        throw response.errors
      }
      else if (response.error) {
        throw new Error(response.error)
      }
      else {
        throw new Error('Error updating VppUser')
      }
    }
    catch (e:any) {
        result = new Error(e.message)
        devLog.log("VppUsersStoreClass", result.message, result, "error")
    }
    return result
  }

  async deleteObjectFromApi(accountId: string, objectId: VppUser["clientUserIdStr"], updateLocalStore?: boolean): Promise<true | Error> {
    let result: true | Error
    try {
      let payload : any = {}
      if (objectId) { payload.clientUserIdStr = objectId }
      const response = await requestHandler.request("POST", config.mgtApiUri + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/ios/vpp/retireUserSrvUrl",payload)
      if (response.error) {
        throw new Error(response.error)
      }
      else {
        result = true
        if (updateLocalStore) {
          this.removeObjectFromStore(accountId, objectId) // Remove VppUser from store
        }
      }
    }
    catch(e:any) {
      if (e instanceof Error) {
        result = new Error(e.message)
      }
      else {
        result = new Error('Error deleting VppUser')
      }
      devLog.log("VppUsersStoreClass", result.message, result, "error")
    }
    return result
  }

}
