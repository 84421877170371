import jsonHelpers from "../../helpers/helpers.json";
import apis from "../../classes/apis";
import { useGlobalMixin } from "@/mixins/mixins.global";
import depInfosMixin from "@/mixins/mixins.dep-infos";
import requestHandler from "@/queries/requests";
import { ActionTypes, MutationTypes } from "@/store/vuex.store";
import queries from "@/queries/queries";
import encodingHelpers from "@/helpers/helpers.encoding";
import Button from "@/classes/buttons";
import i18n, { T } from "@/classes/i18n";
import moment from "moment";
import $ from "jquery";
import router from "@/router/router";
import { sprintf } from "sprintf-js";
import tenantHelpers from "@/helpers/helpers.tenants";
//@ts-ignore
import Dropzone from 'dropzone';
import dialogs from "@/dialogs/dialogs";
import { debounce } from "throttle-debounce";
import loaderComponent from "../components/loader.vue";
import table2Component from "../components/table2";
import googleMapComponent from "../components/map";
import deviceInlineRenameComponent from "../components/device-inline-rename";
import inlineItemlistInputComponent from "../components/inline-itemlist-input.vue";
import itemlistItemComponent from "../components/itemlist-item.vue";
import regularlyUpdatedDataComponent from "../components/regularly-updated-data";
import { useStore } from '@/store/vuex.store';
import arrayHelpers from "@/helpers/helpers.arrays";
import stringHelpers from "@/helpers/helpers.strings";
import ManagedApplicationStatusToInfo from "../../resources/ios/managedApplicationStatusToInfo.json";
import useRouterStore from "@/router/routerStore";
import licenseHelpers from "@/helpers/helpers.license";
import deviceHelpers from "@/helpers/helpers.devices";
import config from "@/classes/config";
import products from "@/classes/objectTypes";
import { vueApp } from "@/app";

declare var datepicker: any;
const deviceDetailsComponent =  {
    name: 'sms-page-device-details',
    mixins: [useGlobalMixin(),depInfosMixin],
    data() {
        return {
            "mounted":false,
            "activeTab": "overview",
            "refreshCount":0,
            "initialized": false,
            "item": undefined,
            "deviceProperties":{
                alias:"",
                billDate:"",
                billNumber:"",
                contractDate:"",
                contractDuration:"",
                contractNumber:"",
                handoverDate:"",
                hardwareDescription:"",
                inventoryExternalLink:"",
                inventoryLocation:"",
                licenseUUID:"",
                ownership:"",
                providerCustomerNumber:"",
                providerName:"",
                providerTariff:"",
                username:"",
                warrantyEndDate:"",
                warrantyStartDate:""
            },
            "batteryPercentage":0,
            "storageMax":0,
            "storageValue":0,
            "position":undefined,
            "appsLoading":true,
            "operationsLogLoading":true,
            "profilesLoading":true,
            "dropZoneInitialized":false,
            "deviceActionStates": {
                'APPLICATION_LIST':{
                    'status':undefined,
                    'created':0
                },
                'MANAGED_APPLICATION_LIST':{
                    'status':undefined,
                    'created':0
                },
                'DEVICE':{
                    'status':undefined,
                    'created':0
                },
                'ENABLE_LOST_MODE':{
                    'status':undefined,
                    'created':0
                },
                'DISABLE_LOST_MODE':{
                    'status':undefined,
                    'created':0
                },
                'LOCATION':{
                    'status':undefined,
                    'created':0
                },
                'START_RINGTONE':{
                    'status':undefined,
                    'created':0
                },
                'STOP_RINGTONE':{
                    'status':undefined,
                    'created':0
                },
                'MUTE':{
                    'status':undefined,
                    'created':0
                },
                'UNMUTE':{
                    'status':undefined,
                    'created':0
                },
                'NOTIFICATION':{
                    'status':undefined,
                    'created':0
                },
                'LOCK':{
                    'status':undefined,
                    'created':0
                },
                'REBOOT':{
                    'status':undefined,
                    'created':0
                },
                'SHUTDOWN':{
                    'status':undefined,
                    'created':0
                },
                'CLEAR_PASSCODE':{
                    'status':undefined,
                    'created':0
                },
                'ENTERPRISE_WIPE':{
                    'status':undefined,
                    'created':0
                },
                'WIPE':{
                    'status':undefined,
                    'created':0
                },
                'PROFILE_LIST':{
                    'status':undefined,
                    'created':0
                },
                'USER_LIST':{
                    'status':undefined,
                    'created':0
                },
                'SCHEDULE_OS_UPDATE':{
                    'status':undefined,
                    'created':0
                },
            },
            "contractDataLoaded": false,
            "savingProperties":false,
            "propertiesForm": {
                "billNumber":"",
                "billDate":"",
                "hardwareDescription":"",
                "providerName":"",
                "providerTariff":"",
                "contractDate":"",
                "contractDuration":"",
                "handoverDate":"",
                "contractNumber":"",
                "providerCustomerNumber":"",
                "warrantyStartDate":"",
                "warrantyEndDate":"",
                "inventoryExternalLink":""
            },
            "permissionData":[],
            "managedAppStatusToInfo":undefined,
            "restrictionsTableData":{
                "loading":false,
                "tableData": {
                    "titles":[],
                    "rows":[]
                }
            },
            "operationLogTableData": {
                "loading": false,
                "tableData": {
                    "titles": [],
                    "rows": []
                }
            },
            "perUserChannelUsersTableData": {
                "loading": false,
                "tableData": {
                    "titles": [],
                    "rows": []
                }
            },
            "profilesTableData": {
                "loading": false,
                "tableData": {
                    "titles": [
                        {
                            "id": "profile",
                            "text": "Profile",
                            "sortable": true
                        },
                        {
                            "id": "part",
                            "text": "Part",
                            "sortable": true
                        },
                        {
                            "id": "user",
                            "text": "User",
                            "sortable": true
                        },
                        {
                            "id": "actions",
                            "text": "Actions",
                            "sortable": false
                        }
                    ],
                    "rows": []
                }
            },
            "appsTableData": {
                "loading": false,
                "tableData": {
                    "titles": [],
                    "rows": []
                }
            },
            "restrictions": {
                "channel":"global"
            }
        };
    },
    template: `
        <section class="padding-xs padding-md-2 padding-lg-4 pagecontent" v-if="initialized == false">
            <div class="loaderbox text-center">
                <loader
                    class="color-red"
                    :progress="$parent.loadingProgress"
                ></loader>
            </div>
        </section>
        <section class="padding-xs padding-md-2 padding-lg-4 pagecontent" v-else-if="initialized == true">
            <div class="row itemlist show-details view-grid">
                <div class="col-xs-24 col-print-24 col-lg-12 padding-xs">
                    <itemlist-item
                        :item="device"
                        :index="1"
                        :showInfo="true"
                        :clickable="false"
                        key="itemlistItem"
                        ref="itemlistItem"
                        :showMenu="false"
                        :refreshCounterProp="refreshCount"
                    />
                </div>
                <div class="col-xs-24 col-print-24 col-lg-12 padding-xs">
                    <div class="box-shadow box-fullheight content-2">
                        <template
                            v-if="
                                device
                                && device.inventory
                                && !isObjectEmpty(device.inventory.inventoryLocation)
                            "
                        >
                            <h3 class="float-xs-left">${T('Inventory location')}</h3>
                            <a 
                                v-on:click="getInventoryLocationDialog()" 
                                class="btn padding-xs-y-0 float-xs-right" 
                                :class="darkmode == '1' ? 'btn-darkbluegrey' : 'btn-white color-gray'" 
                                :title="T('Set inventory device location')"
                            ><i class="fal fa-edit"></i></a>
                        </template>
                        <div class="clearfix"></div>
                        <template 
                            v-if="device 
                                && device.inventory 
                                && device.inventory.inventoryLocation 
                                && device.inventory.inventoryLocation.latitude 
                                && device.inventory.inventoryLocation.longitude
                            "
                        >
                            <googleMap
                                id="inventoryMap"
                                :entries="[{
                                    'position':{
                                        'lat':device.inventory.inventoryLocation.latitude,
                                        'lng':device.inventory.inventoryLocation.longitude
                                    },
                                    'infoHtml':getInfoBoxText() 
                                }]"
                            />
                        </template>
                        <template v-if="isObjectEmpty(device.inventory ? device.inventory.inventoryLocation : {})">
                            <div :style="
                                (darkmode == '1' ? 'background: rgba(0, 0, 0, 0.2);' : 'background: rgba(0, 0, 0, 0.08);')
                                + 'top: 16px;'
                                + 'height:calc(100% - 32px);'
                                + 'left: 16px;'
                                + 'right: 16px;'
                                + 'position: absolute;'
                            ">
                                <div style="position:absolute;top:50%;transform: translate(0, -50%); width: 100%;" class="text-center">
                                    <h3 style="width: 100%;text-align: center;">
                                        {{ T('Inventory location is not set') }}
                                    </h3>
                                    <p>{{ T('Click the following link to set the inventory location:') }} <br>
                                    <br><a class="btn" :title="T('Set inventory device location')" v-on:click="getInventoryLocationDialog()"> <i class="fal fa-fw fa-edit"></i> {{T('Set location')}}</a></p>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-xs-24 padding-xs">
                    <div class="box-shadow">
                        <div class="tabs">
                            <nav>
                                <ul class="nav nav-tabs hide-on-print">
                                    <li>
                                        <a :class="{'active':activeTab == 'overview'}" v-on:click="setActiveTab('overview')">
                                            <i class="fal fa-fw fa-mobile-alt"></i> ${T('Overview')}
                                        </a>
                                    </li>
                                    <li class="hidden">
                                        <a :class="{'active':activeTab == 'inventory'}" v-on:click="setActiveTab('inventory')">
                                            <i class="fal fa-fw fa-info"></i> ${T('Inventory')}
                                        </a>
                                    </li>
                                    <li>
                                        <a :class="{'active':activeTab == 'operations'}" v-on:click="setActiveTab('operations')">
                                            <i class="fal fa-fw fa-wrench"></i> ${T('Operations')}
                                        </a>
                                    </li>

                                    <!--li>
                                        <a :class="{'active':activeTab == 'compliance'}" v-on:click="setActiveTab('compliance')">
                                            ${T('Profile compliance')}
                                        </a>
                                    </li-->

                                    <li>
                                        <a :class="{'active':activeTab == 'applications'}" v-on:click="setActiveTab('applications');setThWidth('appsTable')">
                                            <i class="fal fa-fw fa-rocket"></i> ${T('Applications')}
                                        </a>
                                    </li>
                                    <li>
                                    <a :class="{'active':activeTab == 'profiles'}" v-on:click="setActiveTab('profiles');setThWidth('profilesTable');initDropZone('#droparea')">
                                            <i class="fal fa-fw fa-shield-alt"></i> ${T('Profiles')}
                                        </a>
                                    </li>
                                    
                                    <li v-if="device.info.IsMultiUser">
                                        <a :class="{'active':activeTab == 'users'}" v-on:click="setActiveTab('users');setThWidth('perUserChannelUsersTable')">
                                            <i class="fal fa-fw fa-users"></i> ${T('Users')}
                                        </a>
                                    </li>
                                    <li>
                                        <a v-if="device.ownership != 'BYOD'" :class="{'active':activeTab == 'restrictions'}" v-on:click="setActiveTab('restrictions');getRestrictions();setThWidth('restrictionsTable');">
                                            <i class="fal fa-fw fa-ban"></i> ${T('Restrictions')}
                                        </a>
                                    </li>
                                    <li>
                                        <a :class="{'active':activeTab == 'operationsLog'}" v-on:click="setActiveTab('operationsLog');setThWidth('operationsLog')">
                                            <i class="fal fa-fw fa-file-alt"></i> ${T('Operations log')}
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <div class="tabcontent">
                                <div :class="{'hidden':activeTab != 'overview'}" id="device-summary">
                                    <div class="box-content-2">
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <div class="text-right">
                                                    <button
                                                        id="f18ddfac-d6dd-4b23-b037-2b820f8583ef"
                                                        :disabled="deviceActionStatus('DEVICE')[1] || deviceActionStates['DEVICE'].status == 'PENDING' && !isTimedOut(deviceActionStates['DEVICE'].created) || null"
                                                        v-on:click="deviceAction('DEVICE',device.deviceId)"
                                                        class="btn btn-loader twist-in"
                                                        :class="{'btn-loading': deviceActionStates['DEVICE'].status == 'PENDING' && !isTimedOut(deviceActionStates['DEVICE'].created)}"
                                                    >
                                                        <span class="animate">
                                                            <loader
                                                                v-if="deviceActionStates['DEVICE'].status == 'PENDING' && !isTimedOut(deviceActionStates['DEVICE'].created)"
                                                                style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"></loader>
                                                        </span>
                                                        <span>
                                                        <i class="fal fa-sync"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                                <hr class="margin-xs-y-2">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <h4>${T('Device Status')} <small>${T('last update:')} <span>{{ getDate(device.lastContact,true) }}</span></small></h4>
                                                <table class="noborder border-h ">
                                                    <tbody>
                                                        <tr>
                                                            <td class="title">
                                                                <i class="fal fa-fw fa-battery-full" aria-hidden="true"></i>
                                                                ${T('Battery Level')}
                                                            </td>
                                                            <td>
                                                                <span></span>
                                                                <div class="row">
                                                                    <div class="col-xs-24">
                                                                        <div v-if="batteryPercentage >= 0" class="progress-bar progress-bar-sm" :class="getBatteryColor(batteryPercentage)" :data-progress="batteryPercentage"></div>
                                                                        <div class="row" v-if="batteryPercentage >= 0">
                                                                            <div class="col-xs-24">
                                                                                <span>{{ batteryPercentage >= 0 ? batteryPercentage+'%' : '' }}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div v-else>
                                                                            ${T('')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-hdd"></i>  ${T('Storage Capacity')}</td>
                                                            <td>
                                                                <div class="row">
                                                                    <div class="col-xs-24">
                                                                        <div v-if="storageMax > 0" class="progress-bar progress-bar-sm" :class="getHddColor(getStoragePercentage(storageValue,storageMax))" :data-progress="getStoragePercentage(storageValue,storageMax)" :title="getStoragePercentage(storageValue,storageMax) +'% ${T('used')}'"></div>
                                                                        <div class="row" v-if="storageMax > 0">
                                                                            <div class="col-xs-24">
                                                                                <span>{{ getStoragePercentage(storageValue,storageMax) }}</span>% ${T('used')} | ${T('Total')}:
                                                                                <span>{{ bytesToSize(storageMax * Math.pow(2, 30)) }}</span>
                                                                                (<span>{{ bytesToSize(storageValue * Math.pow(2, 30)) }}</span> ${T('free')})
                                                                            </div>
                                                                        </div>
                                                                        <div v-else>
                                                                            ${T('')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="device.declarativeManagement && device.declarativeManagement.passCode && typeof device.declarativeManagement.passCode.present == 'boolean'">
                                                            <td class="title"><i class="fal fa-fw fa-key"></i>  ${T('Passcode')}</td>
                                                            <td>
                                                                <div class="row">
                                                                    <div class="col-xs-24">
                                                                      <template v-if="device.declarativeManagement.passCode.present == true">
                                                                        <template v-if="device.declarativeManagement.passCode.compliance === false">
                                                                            <i class="fal fa-triangle color-red"></i> &nbsp;{{ T("Non-compliant") }}
                                                                        </template>
                                                                        <template v-else>
                                                                            <i class="fal fa-check color-green"></i> &nbsp;{{ T("Passcode set") }}
                                                                        </template>
                                                                      </template>
                                                                      <template v-else>
                                                                        <i class="fal fa-exclamation-triangle color-red"></i> &nbsp;{{ T("Not set") }}
                                                                      </template>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xs-24 col-lg-12">
                                                <h4>${T('Device Summary')}</h4>
                                                <table class="noborder border-h ">
                                                    <tbody>
                                                        <tr v-if="device.enrolledAt">
                                                            <td class="title"><i class="fal fa-fw fa-calendar"></i> ${T('Enrolled at')}</td>
                                                            <td>
                                                                {{ moment(device.enrolledAt * 1000).format('DD.MM.YYYY HH:mm') }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-hashtag"></i> ${T('Device ID')}</td>
                                                            <td>
                                                                {{ device.deviceId }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-microchip"></i> ${T('Device Type')}</td>
                                                            <td>
                                                                {{ device.deviceType || '' }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-mobile-alt"></i> ${T('Model')}</td>
                                                            <td>
                                                                <template v-if="device.deviceFamily">
                                                                    {{ device.deviceFamily }} ({{ device.productName || '' }})
                                                                </template>
                                                                <template v-else>
                                                                    {{ device.info.productName || '' }}
                                                                </template>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-building"></i> ${T('Manufacturer')}</td>
                                                            <td>
                                                                {{ device.info.manufacturer || '' }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-microchip"></i> ${T('OS')}</td>
                                                            <td>
                                                            
                                                                <template v-if="device && !device.availableUpdates || device.availableUpdates.length == 0">
                                                                    {{ device.info.os || '' }} <span title="Version">{{ device.info.version || device.info.OSVersion || '' }}</span>
                                                                </template>

                                                                <template v-else-if="device && device.availableUpdates && device.availableUpdates.length > 0">
                                                                    <a 
                                                                        
                                                                        class="btn btn-primary"  
                                                                        :disabled="deviceActionStatus('SCHEDULE_OS_UPDATE')[1] || deviceActionStates['SCHEDULE_OS_UPDATE'].status == 'PENDING' && !isTimedOut(deviceActionStates['SCHEDULE_OS_UPDATE'].created) || null"
                                                                        v-on:click="deviceAction('SCHEDULE_OS_UPDATE',device.deviceId)"
                                                                        :title="deviceActionStatus('SCHEDULE_OS_UPDATE')[1] ? T('Only available for supervised devices') : T('Update')"  
                                                                    >
                                                                        <i class="fal fa-sync"></i> 
                                                                        {{T('Update from iOS')+' '+(device.info.version || device.info.OSVersion || '')+' '+T('to')+' '+(device.availableUpdates[0].ProductVersion || '')}}
                                                                    </a>
                                                                    <template v-if="deviceActionStatus('SCHEDULE_OS_UPDATE')[1]">
                                                                        <span class="notification padding-xs" style="top: 1px; position: relative; display:inline-block">{{ T('Only available for supervised devices') }}</span>
                                                                    </template>
                                                                </template>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="device.info.agent">
                                                            <td class="title">
                                                                <i class="fal fa-fw fa-code-branch"></i> ${T('Agent')}
                                                            </td>
                                                            <td>
                                                                {{ device.info.agent.versionCode || '' }} |
                                                                <span title="Version">{{ device.info.agent.versionName || '' }}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-hashtag"></i>  ${T('Serial Number')}</td>
                                                            <td>
                                                            {{ device.serialNumber || '' }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <template v-if="device.depDevice == true">
                                                    <h4>${T('Apple DEP')}</h4>
                                                    <table class="noborder border-h ">
                                                        <tbody>
                                                            <tr>
                                                                <td class="title">
                                                                    <i class="fab fa-fw fa-apple"></i> ${T('Profile')}
                                                                </td>
                                                                <td>
                                                                    {{ thisDeviceDepProfile ? thisDeviceDepProfile.profile_name : "" }} ({{ (device.depDeviceInfo.profile_uuid || "").substring(0,6) }})
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="title">
                                                                    <i class="fab fa-fw fa-apple"></i> ${T('Profile status')}
                                                                </td>
                                                                <td>
                                                                    {{ T(depProfileStatusInfo[device.depDeviceInfo.profile_status]) }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="title">
                                                                    <i class="fab fa-fw fa-apple"></i> ${T('Profile assignment time')}
                                                                </td>
                                                                <td>
                                                                    {{ device.depDeviceInfo.profile_assign_time ? moment(device.depDeviceInfo.profile_assign_time).format("DD.MM.YYYY HH:mm") : '' }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </template>
                                                <template v-if="deviceProperties">
                                                    <h4>${T('Inventory')}</h4>
                                                    <table class="noborder border-h ">
                                                        <tbody>
                                                            <tr>
                                                                <td class="title"><i class="fal fas-fw fa-money-bill"></i> ${T('Bought on')}</td>
                                                                <td v-if="deviceProperties.warrantyStartDate">
                                                                    {{ getDate(deviceProperties.warrantyStartDate) }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                            <td class="title"><i class="fal fa-fw fa-shield"></i> ${T('Warranty ends on')}</td>
                                                            <td v-if="deviceProperties.warrantyEndDate">
                                                                {{ getDate(deviceProperties.warrantyEndDate) }}
                                                            </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="title"><i class="fal fa-fw fa-link"></i> ${T('External inventory link')}</td>
                                                                <td v-if="deviceProperties.inventoryExternalLink">
                                                                    <a :href="deviceProperties.inventoryExternalLink" target="_blank" rel="noreferrer noopener">${T('Open link')}</a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </template>
                                            </div>
                                            <div class="col-xs-24 col-lg-12">
                                                <h4>${T('Network Summary')}</h4>
                                                <table class="noborder border-h ">
                                                    <tbody>
                                                        <template v-if="device.info.ServiceSubscriptions && device.info.ServiceSubscriptions.length > 0">

                                                        </template>
                                                        <template v-else>
                                                            <tr>
                                                                <td class="title"><i class="fal fa-fw fa-hashtag"></i> ${T('IMEI Number')}</td>
                                                                <td>
                                                                    {{ device.info.imei || '' }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="title"><i class="fal fa-fw fa-phone"></i> ${T('Phone Number')}</td>
                                                                <td>
                                                                    {{ device.info.PhoneNumber || '' }}
                                                                </td>
                                                            </tr>
                                                        </template>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-hashtag"></i> ${T('ICCID')}</td>
                                                            <td>
                                                                {{ device.info.ICCID || '' }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-server"></i> ${T('Network Operator')}</td>
                                                        <td>
                                                            {{ unescape(device.info.networkOperator) || '' }}
                                                        </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title"><i class="fab fa-fw fa-bluetooth"></i> ${T('Bluetooth MAC')}</td>
                                                        <td>
                                                            {{ device.info.BluetoothMAC || '' }}
                                                        </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-wifi"></i> ${T('WIFI MAC')}</td>
                                                        <td>
                                                            {{ device.info.wifiMAC || '' }}
                                                        </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-globe"></i> ${T('Roaming Enabled')}</td>
                                                            <td>
                                                                <i 
                                                                    class="fa fa-fw" 
                                                                    style="font-size: 1.25em;"
                                                                    :title="(device.info.DataRoamingEnabled === true ? T('Yes') : device.info.DataRoamingEnabled === false ? T('No') : '')"
                                                                    :class="[
                                                                        'color-' + (device.info.DataRoamingEnabled === true ? 'green' : device.info.DataRoamingEnabled === false ? 'red' : (darkMode == '1' ? 'darkgrey' : 'lightgray')),
                                                                        {
                                                                            'fa-circle': device.info.DataRoamingEnabled === undefined,
                                                                            'fa-times-circle': device.info.DataRoamingEnabled === false,
                                                                            'fa-circle-check': device.info.DataRoamingEnabled === true
                                                                        }
                                                                    ]"
                                                                ></i>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-globe"></i> ${T('Data Roaming')}</td>
                                                            <td>
                                                                <i 
                                                                    class="fa fa-fw" 
                                                                    style="font-size: 1.25em;"
                                                                    :title="(device.info.IsRoaming === true ? T('Yes') : device.info.IsRoaming === false ? T('No') : '')"
                                                                    :class="[
                                                                        'color-' + (device.info.IsRoaming === true ? 'green' : device.info.IsRoaming === false ? 'red' : (darkMode == '1' ? 'darkgrey' : 'lightgray')),
                                                                        {
                                                                            'fa-circle': device.info.IsRoaming === undefined,
                                                                            'fa-times-circle': device.info.IsRoaming === false,
                                                                            'fa-circle-check': device.info.IsRoaming === true
                                                                        }
                                                                    ]"
                                                                ></i>                                                            
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-globe"></i> ${T('Device Locator Enabled')}</td>
                                                            <td>
                                                                <i 
                                                                    class="fa fa-fw" 
                                                                    style="font-size: 1.25em;"
                                                                    :title="(device.info.IsDeviceLocatorServiceEnabled === true ? T('Yes') : device.info.IsDeviceLocatorServiceEnabled === false ? T('No') : '')"
                                                                    :class="[
                                                                        'color-' + (device.info.IsDeviceLocatorServiceEnabled === true ? 'green' : device.info.IsDeviceLocatorServiceEnabled === false ? 'red' : (darkMode == '1' ? 'darkgrey' : 'lightgray')),
                                                                        {
                                                                            'fa-circle': device.info.IsDeviceLocatorServiceEnabled === undefined,
                                                                            'fa-times-circle': device.info.IsDeviceLocatorServiceEnabled === false,
                                                                            'fa-circle-check': device.info.IsDeviceLocatorServiceEnabled === true
                                                                        }
                                                                    ]"
                                                                ></i>                                                            
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title"><i class="fal fa-fw fa-globe"></i> ${T('Network tethered')}</td>
                                                            <td>
                                                            
                                                                <i 
                                                                    class="fa fa-fw" 
                                                                    style="font-size: 1.25em;"
                                                                    :title="(device.info.IsNetworkTethered === true ? T('Yes') : device.info.IsNetworkTethered === false ? T('No') : '')"
                                                                    :class="[
                                                                        'color-' + (device.info.IsNetworkTethered === true ? 'green' : device.info.IsNetworkTethered === false ? 'red' : (darkMode == '1' ? 'darkgrey' : 'lightgray')),
                                                                        {
                                                                            'fa-circle': device.info.IsNetworkTethered === undefined,
                                                                            'fa-times-circle': device.info.IsNetworkTethered === false,
                                                                            'fa-circle-check': device.info.IsNetworkTethered === true
                                                                        }
                                                                    ]"
                                                                ></i>   
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <template v-if="device.info.ServiceSubscriptions">
                                                    <template v-for="slot,index in device.info.ServiceSubscriptions">

                                                        <h4>Sim slot {{ index }}
                                                            <template v-if="slot.Label">
                                                                <small>{{ slot.Label }}</small>
                                                            </template>
                                                        </h4>
                                                        <table class="noborder border-h ">
                                                            <tr>
                                                                <td class="title">
                                                                    <i class="fal fa-fw fa-hashtag"></i> {{ T('IMEI') }}
                                                                </td>
                                                                <td>
                                                                    {{ slot.IMEI || "" }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="title">
                                                                    <i class="fal fa-fw fa-phone"></i> {{ T('Phone Number') }}
                                                                </td>
                                                                <td>
                                                                    {{ slot.PhoneNumber || "" }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="title">
                                                                    <i class="fal fa-fw fa-info-circle"></i> {{ T('Carrier info') }}
                                                                </td>
                                                                <td>
                                                                    <template v-if="slot.CurrentCarrierNetwork">
                                                                        {{ slot.CurrentCarrierNetwork || "" }}
                                                                        <template v-if="slot.IsDataPreferred">
                                                                            <br> {{ T('Prefered for data') }}
                                                                        </template>
                                                                        <template v-if="slot.IsRoaming">
                                                                            <br> {{ T('Roaming activated') }}
                                                                        </template>
                                                                        <template v-if="slot.IsVoicePreferred">
                                                                            <br> {{ T('Prefered for voice') }}
                                                                        </template>
                                                                    </template>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </template>
                                                </template>

                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div :class="{'hidden':activeTab != 'inventory'}" id="device-contract">
                                    <div class="box-content-2">
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <div class="row">
                                                    <div class="col-xs-24">
                                                        <h4>${T('Device')}</h4>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xs-24">
                                                <div class="row">
                                                    <div class="col-xs-24 padding-xs-x">
                                                        <div class="row form-group validation-base border-bottom padding-xs-y">
                                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                                <label class="control-label" for="billNumber">
                                                                    ${T('Bill number')}
                                                                </label>
                                                            </div>
                                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                                <label class="form-field margin-xs-b-0">
                                                                    <input id="billNumber" type="text" class="form-control user-selection" placeholder="${T('Bill number')}" v-model="propertiesForm.billNumber" data-validation-property="/billNumber">
                                                                </label>
                                                            </div>
                                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                                <p class="input-description">
                                                                    ${T('Bill number')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row form-group validation-base border-bottom padding-xs-y">
                                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                                <label class="control-label" for="billDate">
                                                                    ${T('Bill date')}
                                                                </label>
                                                            </div>
                                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                                <label class="form-field margin-xs-b-0">
                                                                    <input id="billDate" type="text" class="form-control user-selection" placeholder="${T('Select a date')}" v-model="propertiesForm.billDate" data-validation-property="/billDate"  data-plugin="datepicker" data-language='${i18n.getLanguage()}'>
                                                                </label>
                                                            </div>
                                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                                <p class="input-description">
                                                                    ${T('Bill date')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row form-group validation-base border-bottom padding-xs-y">
                                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                                <label class="control-label" for="warrantyStartDate">
                                                                    ${T('Warranty starts on')}
                                                                </label>
                                                            </div>
                                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                                <label class="form-field margin-xs-b-0">
                                                                    <input id="warrantyStartDate" type="text" class="form-control user-selection" placeholder="${T('Select a date')}" v-model="propertiesForm.warrantyStartDate" data-validation-property="/warrantyStartDate"  data-plugin="datepicker" data-language='${i18n.getLanguage()}'>
                                                                </label>
                                                            </div>
                                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                                <p class="input-description">
                                                                    ${T('Warranty start')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row form-group validation-base border-bottom padding-xs-y">
                                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                                <label class="control-label" for="warrantyEndDate">
                                                                    ${T('Warranty ends on')}
                                                                </label>
                                                            </div>
                                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                                <label class="form-field margin-xs-b-0">
                                                                    <input id="warrantyEndDate" type="text" class="form-control user-selection" placeholder="${T('Select a date')}" v-model="propertiesForm.warrantyEndDate" data-validation-property="/warrantyEndDate"  data-plugin="datepicker" data-language='${i18n.getLanguage()}'>
                                                                </label>
                                                            </div>
                                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                                <p class="input-description">
                                                                    ${T('Warranty end')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row form-group validation-base border-bottom padding-xs-y">
                                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                                <label class="control-label" for="inventoryExternalLink">
                                                                    ${T('External inventory link')}
                                                                </label>
                                                            </div>
                                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                                <label class="form-field margin-xs-b-0">
                                                                    <input id="inventoryExternalLink" type="text" class="form-control user-selection" placeholder="${T('URL')}" v-model="propertiesForm.inventoryExternalLink" data-validation-property="/inventoryExternalLink">
                                                                </label>
                                                            </div>
                                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                                <p class="input-description">
                                                                    ${T('Set link to inventory software')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row form-group validation-base border-bottom padding-xs-y">
                                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                                <label class="control-label" for="hardwareDescription">
                                                                    ${T('Description')}
                                                                </label>
                                                            </div>
                                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                                <label class="form-field margin-xs-b-0">
                                                                    <textarea id="hardwareDescription" class="form-control user-selection" placeholder="${T('Description')}" v-model="propertiesForm.hardwareDescription" data-validation-property="/hardwareDescription"></textarea>
                                                                </label>
                                                            </div>
                                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                                <p class="input-description">
                                                                    ${T('Device description')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xs-24 padding-xs-t-4">
                                                <div class="row">
                                                    <div class="col-xs-24">
                                                        <h4>${T('Contract')}</h4>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xs-24">
                                                <div class="row">
                                                    <div class="col-xs-24 padding-xs-x">
                                                        <div class="row form-group validation-base border-bottom padding-xs-y">
                                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                                <label class="control-label" for="providerName">
                                                                    ${T('Provider')}
                                                                </label>
                                                            </div>
                                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                                <label class="form-field margin-xs-b-0">
                                                                    <input id="providerName" type="text" class="form-control user-selection" placeholder="${T('Provider')}" v-model="propertiesForm.providerName" data-validation-property="/providerName">
                                                                </label>
                                                            </div>
                                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                                <p class="input-description">
                            										${T('The name of the provider')}
                            									</p>
                                                            </div>
                                                        </div>
                                                        <div class="row form-group validation-base border-bottom padding-xs-y">
                                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                                <label class="control-label" for="providerTariff">
                                                                    ${T('Tariff')}
                                                                </label>
                                                            </div>
                                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                                <label class="form-field margin-xs-b-0">
                                                                    <input id="providerTariff" type="text" class="form-control user-selection" placeholder="${T('Tariff')}" v-model="propertiesForm.providerTariff" data-validation-property="/providerTariff">
                                                                </label>
                                                            </div>
                                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                                <p class="input-description">
                            										${T('Tariff')}
                            									</p>
                                                            </div>
                                                        </div>
                                                        <div class="row form-group validation-base border-bottom padding-xs-y">
                                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                                <label class="control-label" for="providerCustomerNumber">
                                                                    ${T('Customer number')}
                                                                </label>
                                                            </div>
                                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                                <label class="form-field margin-xs-b-0">
                                                                    <input id="providerCustomerNumber" type="text" class="form-control user-selection" placeholder="${T('Customer number')}" v-model="propertiesForm.providerCustomerNumber" data-validation-property="/providerCustomerNumber">
                                                                </label>
                                                            </div>
                                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                                <p class="input-description">
                                                                    ${T('Customer number')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row form-group validation-base border-bottom padding-xs-y">
                                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                                <label class="control-label" for="contractNumber">
                                                                    ${T('Contract number')}
                                                                </label>
                                                            </div>
                                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                                <label class="form-field margin-xs-b-0">
                                                                    <input id="contractNumber" type="text" class="form-control user-selection" placeholder="${T('Contract number')}" v-model="propertiesForm.contractNumber" data-validation-property="/contractNumber">
                                                                </label>
                                                            </div>
                                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                                <p class="input-description">
                                                                    ${T('Contract number')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row form-group validation-base border-bottom padding-xs-y">
                                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                                <label class="control-label" for="contractDate">
                                                                    ${T('Start of contract')}
                                                                </label>
                                                            </div>
                                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                                <label class="form-field margin-xs-b-0">
                                                                    <input id="contractDate" type="text" class="form-control user-selection" placeholder="${T('Select a date')}" v-model="propertiesForm.contractDate" data-validation-property="/contractDate" data-plugin="datepicker" data-language='${i18n.getLanguage()}'>
                                                                </label>
                                                            </div>
                                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                                <p class="input-description">
                            										${T('Start of contract')}
                            									</p>
                                                            </div>
                                                        </div>
                                                        <div class="row form-group validation-base border-bottom padding-xs-y">
                                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                                <label class="control-label" for="contractDuration">
                                                                    ${T('Contract term')}
                                                                </label>
                                                            </div>
                                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                                <label class="form-field margin-xs-b-0">
                                                                    <input id="contractDuration" type="number" min="0" class="form-control user-selection" placeholder="${T('Months')}" v-model="propertiesForm.contractDuration" data-validation-property="/contractDuration">
                                                                </label>
                                                            </div>
                                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                                <p class="input-description">
                            										${T('Contract term')} (${T('In months')})
                            									</p>
                                                            </div>
                                                        </div>
                                                        <div class="row form-group validation-base border-bottom padding-xs-y">
                                                            <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                                <label class="control-label" for="handoverDate">
                                                                    ${T('Handover date')}
                                                                </label>
                                                            </div>
                                                            <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                                <label class="form-field margin-xs-b-0">
                                                                    <input id="handoverDate" type="text" class="form-control user-selection" placeholder="${T('Select a date')}" v-model="propertiesForm.handoverDate" data-validation-property="/handoverDate" data-plugin="datepicker" data-language='${i18n.getLanguage()}'>
                                                                </label>
                                                            </div>
                                                            <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                                <p class="input-description">
                            										${T('Handover date')}
                            									</p>
                                                            </div>
                                                        </div>
                                                        <button
                                                            class="btn margin-xs-b-0 margin-xs-t-2 btn-loader twist-in float-xs-right"
                                                            v-on:click="saveInventory()"
                                                            :class="{'btn-loading' : savingProperties == true }"
                                                            :disabled="savingProperties == true || null"
                                                            id="saveButton"
                                                            title="Save"
                                                            type="submit"
                                                        >
                                                            <span class="animate">
                                                                <loader
                                                                    v-if="savingProperties == true"
                                                                    style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
                                                                ></loader>
                                                            </span>
                                                            <span><i class="fal fa-save"></i> <span class="display-xs-none display-lg-inline">${T('Save')}</span></span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div :class="{'hidden':activeTab != 'operations'}" id="device-operations">
                                    <div class="box-content-2">
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <div class="row">
                                                    <div class="col-xs-24">
                                                        <h4>${T('Device Operations')}</h4>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xs-24 padding-xs-t">
                                                <div class="row">
                                                
                                                    <template v-if="device.availableUpdates && device.availableUpdates.length > 0 && (device.info?.supervised === true || device.info?.IsSupervised === true)">
                                                        <div class="col-lg-6 col-xl-3 padding-xs-x">
                                                            <button
                                                                id="6269cae0-a2a2-4dee-b150-d5a8bec921c2"
                                                                v-if="deviceActionStatus('SCHEDULE_OS_UPDATE')[0]"
                                                                :disabled="deviceActionStatus('SCHEDULE_OS_UPDATE')[1] || deviceActionStates['SCHEDULE_OS_UPDATE'].status == 'PENDING' && !isTimedOut(deviceActionStates['SCHEDULE_OS_UPDATE'].created) || null"
                                                                v-on:click="deviceAction('SCHEDULE_OS_UPDATE',device.deviceId)"
                                                                class="btn-loader twist-in col-xs-24"
                                                                :class="{'btn-loading': deviceActionStates['SCHEDULE_OS_UPDATE'].status == 'PENDING' && !isTimedOut(deviceActionStates['SCHEDULE_OS_UPDATE'].created)}"
                                                                :title="T('Update')"
                                                            >
                                                                <span class="animate">
                                                                    <loader
                                                                        v-if="deviceActionStates['SCHEDULE_OS_UPDATE'].status == 'PENDING' && !isTimedOut(deviceActionStates['SCHEDULE_OS_UPDATE'].created)"
                                                                        style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
                                                                    ></loader>
                                                                </span>
                                                                <span>
                                                                    <i class="fal fa-sync"></i> 
                                                                    {{T('Update from iOS')+' '+(device.info.version || device.info.OSVersion || '')+' '+T('to')+' '+(device.availableUpdates[0].ProductVersion || '')}}
                                                                </span>
                                                            </button>

                                                        </div>
                                                    </template>


                                                    <div class="col-lg-6 col-xl-3 padding-xs-x">
                                                        <button
                                                            id="6269cae0-a2a2-4dee-b150-d5a8bec921a6"
                                                            v-if="deviceActionStatus('ENABLE_LOST_MODE')[0]"
                                                            :disabled="deviceActionStatus('ENABLE_LOST_MODE')[1] || deviceActionStates['ENABLE_LOST_MODE'].status == 'PENDING' && !isTimedOut(deviceActionStates['ENABLE_LOST_MODE'].created) || null"
                                                            v-on:click="deviceAction('ENABLE_LOST_MODE',device.deviceId)"
                                                            class="btn-loader twist-in col-xs-24"
                                                            :class="{'btn-loading': deviceActionStates['ENABLE_LOST_MODE'].status == 'PENDING' && !isTimedOut(deviceActionStates['ENABLE_LOST_MODE'].created)}"
                                                        >
                                                            <span class="animate">
                                                                <loader
                                                                    v-if="deviceActionStates['ENABLE_LOST_MODE'].status == 'PENDING' && !isTimedOut(deviceActionStates['ENABLE_LOST_MODE'].created)"
                                                                    style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
                                                                ></loader>
                                                            </span>
                                                            <span><i class="fal fa-search-plus"></i> ${T('Enable Lost Mode')}</span>
                                                        </button>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-3 padding-xs-x">
                                                        <button
                                                            id="7f1ace89-76d5-4669-9e3c-da59d4103b86"
                                                            v-if="deviceActionStatus('DISABLE_LOST_MODE')[0]"
                                                            :disabled="deviceActionStatus('DISABLE_LOST_MODE')[1] || deviceActionStates['DISABLE_LOST_MODE'].status == 'PENDING' && !isTimedOut(deviceActionStates['DISABLE_LOST_MODE'].created) || null"
                                                            v-on:click="deviceAction('DISABLE_LOST_MODE',device.deviceId)"
                                                            class="btn-loader twist-in col-xs-24"
                                                            :class="{'btn-loading': deviceActionStates['DISABLE_LOST_MODE'].status == 'PENDING' && !isTimedOut(deviceActionStates['DISABLE_LOST_MODE'].created)}"
                                                        >
                                                            <span class="animate">
                                                                <loader
                                                                    v-if="deviceActionStates['DISABLE_LOST_MODE'].status == 'PENDING' && !isTimedOut(deviceActionStates['DISABLE_LOST_MODE'].created)"
                                                                    style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
                                                                ></loader>
                                                            </span>
                                                            <span><i class="fal fa-search-minus"></i> ${T('Disable Lost Mode')}</span>
                                                        </button>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-3 padding-xs-x">
                                                        <button
                                                            id="4b6cb0ba-8896-429e-8196-5f22eade2a75"
                                                            v-if="deviceActionStatus('LOCATION')[0]"
                                                            :disabled="(deviceActionStatus('LOCATION')[1] || deviceActionStates['LOCATION'].status == 'PENDING') && !isTimedOut(deviceActionStates['LOCATION'].created) || null"
                                                            v-on:click="deviceAction('LOCATION',device.deviceId)"
                                                            class="btn-loader twist-in col-xs-24"
                                                            :class="{'btn-loading': deviceActionStates['LOCATION'].status == 'PENDING' && !isTimedOut(deviceActionStates['LOCATION'].created)}"
                                                        >
                                                            <span class="animate">
                                                                <loader
                                                                    v-if="deviceActionStates['LOCATION'].status == 'PENDING' && !isTimedOut(deviceActionStates['LOCATION'].created)"
                                                                    style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
                                                                ></loader>
                                                            </span>
                                                            <span><i class="fal fa-map-marker-alt"></i> ${T('Locate')}</span>
                                                        </button>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-3 padding-xs-x">
                                                        <button
                                                            id="6e83fdec-9028-4597-a1ce-23fea68ea0c6"
                                                            v-if="deviceActionStatus('START_RINGTONE')[0]"
                                                            :disabled="deviceActionStatus('START_RINGTONE')[1] || deviceActionStates['START_RINGTONE'].status == 'PENDING' && !isTimedOut(deviceActionStates['START_RINGTONE'].created) || null"
                                                            v-on:click="deviceAction('START_RINGTONE',device.deviceId)"
                                                            class="btn-loader twist-in col-xs-24"
                                                            :class="{'btn-loading': deviceActionStates['START_RINGTONE'].status == 'PENDING' && !isTimedOut(deviceActionStates['START_RINGTONE'].created)}"
                                                        >
                                                            <span class="animate">
                                                                <loader
                                                                    v-if="deviceActionStates['START_RINGTONE'].status == 'PENDING' && !isTimedOut(deviceActionStates['START_RINGTONE'].created)"
                                                                    style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
                                                                ></loader>
                                                            </span>
                                                            <span><i class="fal fa-phone-volume"></i> ${T('Start Ringtone')}</span>
                                                        </button>
                                                    </div>
                                                    <!--
                                                    <div class="col-lg-6 col-xl-3 padding-xs-x">
                                                        <button
                                                            id="d76b0df6-e8b9-416b-b9d5-23aa4770ade2"
                                                            v-if="deviceActionStatus('STOP_RINGTONE')[0]"
                                                            :disabled="deviceActionStatus('STOP_RINGTONE')[1] || deviceActionStates['STOP_RINGTONE'].status == 'PENDING' && !isTimedOut(deviceActionStates['STOP_RINGTONE'].created) || null"
                                                            v-on:click="deviceAction('STOP_RINGTONE',device.deviceId)"
                                                            class="btn-loader twist-in col-xs-24"
                                                            :class="{'btn-loading': deviceActionStates['STOP_RINGTONE'].status == 'PENDING' && !isTimedOut(deviceActionStates['STOP_RINGTONE'].created)}"
                                                        >
                                                        <span class="animate">
                                                            <loader
                                                                v-if="deviceActionStates['STOP_RINGTONE'].status == 'PENDING' && !isTimedOut(deviceActionStates['STOP_RINGTONE'].created)"
                                                                style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
                                                            ></loader>
                                                        </span>
                                                        <span><i class="fal fa-phone-volume"></i> ${T('Stop Ringtone')}</span>
                                                        </button>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-3 padding-xs-x">
                                                        <span class="animate">
                                                        <button
                                                            id="f751c13f-43ed-4b6f-8816-e728ad4b260c"
                                                            v-if="deviceActionStatus('MUTE')[0]"
                                                            :disabled="deviceActionStatus('MUTE')[1] || deviceActionStates['MUTE'].status == 'PENDING' && !isTimedOut(deviceActionStates['MUTE'].created) || null"
                                                            v-on:click="deviceAction('MUTE',device.deviceId)"
                                                            class="btn-loader twist-in col-xs-24"
                                                            :class="{'btn-loading': deviceActionStates['MUTE'].status == 'PENDING' && !isTimedOut(deviceActionStates['MUTE'].created)}"
                                                        >
                                                            <loader
                                                                v-if="deviceActionStates['MUTE'].status == 'PENDING' && !isTimedOut(deviceActionStates['MUTE'].created)"
                                                                style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
                                                            ></loader>
                                                        </span>
                                                        <span><i class="fal fa-volume-off"></i> ${T('Mute')}</span>
                                                        </button>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-3 padding-xs-x">
                                                        <button
                                                            id="be755331-a4ed-434e-b564-904dac6a2de1"
                                                            v-if="deviceActionStatus('UNMUTE')[0]"
                                                            :disabled="deviceActionStatus('UNMUTE')[1] || deviceActionStates['UNMUTE'].status == 'PENDING' && !isTimedOut(deviceActionStates['UNMUTE'].created) || null"
                                                            v-on:click="deviceAction('UNMUTE',device.deviceId)"
                                                            class="btn-loader twist-in col-xs-24"
                                                            :class="{'btn-loading': deviceActionStates['UNMUTE'].status == 'PENDING' && !isTimedOut(deviceActionStates['UNMUTE'].created)}"
                                                        >
                                                        <span class="animate">
                                                            <loader
                                                                v-if="deviceActionStates['UNMUTE'].status == 'PENDING' && !isTimedOut(deviceActionStates['UNMUTE'].created)"
                                                                style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
                                                            ></loader>
                                                        </span>
                                                        <span><i class="fal fa-volume-up"></i> ${T('Unmute')}</span>
                                                        </button>
                                                    </div>-->
                                                    <div class="col-lg-6 col-xl-3 padding-xs-x">
                                                        <button
                                                            id="f41d6df6-5ea6-4c6e-b2be-846b9e6118e7"
                                                            v-if="deviceActionStatus('NOTIFICATION')[0]"
                                                            :disabled="deviceActionStatus('NOTIFICATION')[1] || deviceActionStates['NOTIFICATION'].status == 'PENDING' && !isTimedOut(deviceActionStates['NOTIFICATION'].created) || null"
                                                            v-on:click="deviceAction('NOTIFICATION',device.deviceId)"
                                                            class="btn-loader twist-in col-xs-24"
                                                            :class="{'btn-loading': deviceActionStates['NOTIFICATION'].status == 'PENDING' && !isTimedOut(deviceActionStates['NOTIFICATION'].created)}"
                                                        >
                                                            <span class="animate">
                                                                <loader
                                                                    v-if="deviceActionStates['NOTIFICATION'].status == 'PENDING' && !isTimedOut(deviceActionStates['NOTIFICATION'].created)"
                                                                    style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
                                                                ></loader>
                                                            </span>
                                                            <span><i class="fal fa-comment"></i> ${T('Message')}</span>
                                                        </button>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-3 padding-xs-x">
                                                        <button
                                                            id="7341eba7-02c0-4e28-9a74-6f9bf62cbfeb"
                                                            v-if="deviceActionStatus('LOCK')[0]"
                                                            :disabled="deviceActionStatus('LOCK')[1] || deviceActionStates['LOCK'].status == 'PENDING' && !isTimedOut(deviceActionStates['LOCK'].created) || null"
                                                            v-on:click="deviceAction('LOCK',device.deviceId)"
                                                            class="btn-loader twist-in col-xs-24"
                                                            :class="{'btn-loading': deviceActionStates['LOCK'].status == 'PENDING' && !isTimedOut(deviceActionStates['LOCK'].created)}"
                                                        >
                                                            <span class="animate">
                                                                <loader
                                                                    v-if="deviceActionStates['LOCK'].status == 'PENDING' && !isTimedOut(deviceActionStates['LOCK'].created)"
                                                                    style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
                                                                ></loader>
                                                            </span>
                                                            <span><i class="fal fa-lock"></i> ${T('Lock')}</span>
                                                        </button>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-3 padding-xs-x">
                                                        <button
                                                            id="edf54ba0-dbbd-4ec2-a115-057d8f56b068"
                                                            v-if="deviceActionStatus('REBOOT')[0] && device.lostMode === false"
                                                            :disabled="deviceActionStatus('REBOOT')[1] || deviceActionStates['REBOOT'].status == 'PENDING' && !isTimedOut(deviceActionStates['REBOOT'].created) && device.lostMode === false || null"
                                                            v-on:click="deviceAction('REBOOT',device.deviceId)"
                                                            class="btn-loader twist-in col-xs-24"
                                                            :class="{'btn-loading': deviceActionStates['REBOOT'].status == 'PENDING' && !isTimedOut(deviceActionStates['REBOOT'].created)}"
                                                        >
                                                            <span class="animate">
                                                                <loader
                                                                    v-if="deviceActionStates['REBOOT'].status == 'PENDING' && !isTimedOut(deviceActionStates['REBOOT'].created)"
                                                                    style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
                                                                ></loader>
                                                            </span>
                                                            <span><i class="fal fa-redo"></i> ${T('Reboot')}</span>
                                                        </button>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-3 padding-xs-x">
                                                        <button
                                                            id="5c28686a-4cbb-407e-82ee-b41c1a06281c"
                                                            v-if="deviceActionStatus('SHUTDOWN')[0] && device.lostMode === false"
                                                            :disabled="deviceActionStatus('SHUTDOWN')[1] || deviceActionStates['SHUTDOWN'].status == 'PENDING' && !isTimedOut(deviceActionStates['SHUTDOWN'].created) && device.lostMode === false || null"
                                                            v-on:click="deviceAction('SHUTDOWN',device.deviceId)"
                                                            class="btn-loader twist-in col-xs-24"
                                                            :class="{'btn-loading': deviceActionStates['SHUTDOWN'].status == 'PENDING' && !isTimedOut(deviceActionStates['SHUTDOWN'].created)}"
                                                        >
                                                            <span class="animate">
                                                                <loader
                                                                    v-if="deviceActionStates['SHUTDOWN'].status == 'PENDING' && !isTimedOut(deviceActionStates['SHUTDOWN'].created)"
                                                                    style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
                                                                ></loader>
                                                            </span>
                                                            <span><i class="fal fa-power-off"></i> ${T('Shutdown')}</span>
                                                        </button>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-3 padding-xs-x">
                                                        <button
                                                            id="f1f9a2f2-af10-4776-8662-949661147ea3"
                                                            v-if="deviceActionStatus('CLEAR_PASSCODE')[0]"
                                                            :disabled="deviceActionStatus('CLEAR_PASSCODE')[1] || deviceActionStates['CLEAR_PASSCODE'].status == 'PENDING' && !isTimedOut(deviceActionStates['CLEAR_PASSCODE'].created) || null"
                                                            v-on:click="deviceAction('CLEAR_PASSCODE',device.deviceId)"
                                                            class="btn-loader twist-in col-xs-24"
                                                            :class="{'btn-loading': deviceActionStates['CLEAR_PASSCODE'].status == 'PENDING' && !isTimedOut(deviceActionStates['CLEAR_PASSCODE'].created)}"
                                                        >
                                                            <span class="animate">
                                                                <loader
                                                                    v-if="deviceActionStates['CLEAR_PASSCODE'].status == 'PENDING' && !isTimedOut(deviceActionStates['CLEAR_PASSCODE'].created)"
                                                                    style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
                                                                ></loader>
                                                            </span>
                                                            <span><i class="fal fa-key"></i> ${T('Clear Password')}</span>
                                                        </button>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-3 padding-xs-x">
                                                        <button
                                                            id="217f3302-a5af-499c-a81a-88f8ca1133c5"
                                                            v-if="deviceActionStatus('ENTERPRISE_WIPE')[0]"
                                                            :disabled="deviceActionStatus('ENTERPRISE_WIPE')[1] || deviceActionStates['ENTERPRISE_WIPE'].status == 'PENDING' && !isTimedOut(deviceActionStates['ENTERPRISE_WIPE'].created) || null"
                                                            v-on:click="deviceAction('ENTERPRISE_WIPE',device.deviceId)"
                                                            class="btn-loader twist-in col-xs-24"
                                                            :class="{'btn-loading': deviceActionStates['ENTERPRISE_WIPE'].status == 'PENDING' && !isTimedOut(deviceActionStates['ENTERPRISE_WIPE'].created)}"
                                                        >
                                                            <span class="animate">
                                                                <loader
                                                                    v-if="deviceActionStates['ENTERPRISE_WIPE'].status == 'PENDING' && !isTimedOut(deviceActionStates['ENTERPRISE_WIPE'].created)"
                                                                    style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
                                                                ></loader>
                                                            </span>
                                                            <span><i class="fal fa-eraser"></i> ${T('Enterprise Wipe')}</span>
                                                        </button>
                                                    </div>
                                                    <div class="col-lg-6 col-xl-3 padding-xs-x">
                                                        <button
                                                            id="d967c9b4-fa3c-4fa4-acaf-7b5f1f091ad7"
                                                            v-if="deviceActionStatus('WIPE')[0]"
                                                            :disabled="deviceActionStatus('WIPE')[1] || deviceActionStates['WIPE'].status == 'PENDING' && !isTimedOut(deviceActionStates['WIPE'].created) || null"
                                                            v-on:click="deviceAction('WIPE',device.deviceId)"
                                                            class="btn-loader twist-in col-xs-24"
                                                            :class="{'btn-loading': deviceActionStates['WIPE'].status == 'PENDING' && !isTimedOut(deviceActionStates['WIPE'].created)}"
                                                        >
                                                            <span class="animate">
                                                                <loader
                                                                    v-if="deviceActionStates['WIPE'].status == 'PENDING' && !isTimedOut(deviceActionStates['WIPE'].created)"
                                                                    style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
                                                                ></loader>
                                                            </span>
                                                            <span><i class="fal fa-database"></i> ${T('Wipe Data')}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xs-24">
                                                <div id="device-location-map" v-if="(device.ownership != 'BYOD') && (device.lostMode == true)">
                                                    <googleMap
                                                        id="locateDevice"
                                                        v-if="(position != undefined) && ((device != undefined) && (device.lostMode == true))"
                                                        :entries="[position]"
                                                        style="min-height:375px"
                                                    />
                                                    <div class="content" v-else>
                                                        ${T('No location data availabe. A map will automatically appear here, if data is available.')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--div :class="{'hidden':activeTab != 'compliance'}" id="device-profile-compliance">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <table id="device-profile-compliance-table" class="table table-striped table-bordered" cellspacing="0" width="100%">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Incident</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                </div-->
                                <div :class="{'hidden':activeTab != 'applications'}" id="device-applications">
                                    <div class="box-content-2">
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <div class="text-right">
                                                    <button
                                                        class="btn btn-primary btn-loader twist-in"
                                                        v-on:click="downloadAppsCSV()"
                                                        :disabled="appsTableData.length == 0 || null"
                                                    >
                                                        <span class="animate">
                                                            <loader
                                                                v-if="appsTableData.loader"
                                                                style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
                                                            ></loader>
                                                        </span>
                                                        <span>
                                                            <i class="fal fa-download"></i> {{ T('Download CSV') }}
                                                        </span>
                                                    </button>

                                                    <button
                                                        id="f7f2fb76-1f97-47e8-8b49-5baec0bab7b7"
                                                        class="btn btn-primary btn-loader twist-in"
                                                        v-on:click="getAppsTableData(true,objectId)"
                                                        :class="{'btn-loading': deviceActionStates['APPLICATION_LIST'].status == 'PENDING' && !isTimedOut(deviceActionStates['APPLICATION_LIST'].created)}"
                                                        :disabled="deviceActionStates['APPLICATION_LIST'].status == 'PENDING' && !isTimedOut(deviceActionStates['APPLICATION_LIST'].created) || null"
                                                    >
                                                        <span class="animate">
                                                            <loader
                                                                v-if="deviceActionStates['APPLICATION_LIST'].status == 'PENDING' && !isTimedOut(deviceActionStates['APPLICATION_LIST'].created)"
                                                                style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
                                                            ></loader>
                                                        </span>
                                                        <span>
                                                            <i class="fal fa-sync"></i>
                                                        </span>
                                                    </button>
                                                    <hr class="margin-xs-y-2">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <h4>${T('Applications')}</h4>
                                                <table2
                                                    ref="appsTable"
                                                    class="appsTable"
                                                    :loading="appsTableData.loading"
                                                    :table="appsTableData.tableData"
                                                    
                                                ></table2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    :class="{'hidden':activeTab != 'profiles'}"
                                    id="device-profiles"
                                    ondragover="$('#device-profiles').addClass('dragging')"
                                    ondragleave="$('#device-profiles').removeClass('dragging')"
                                    ondrop="$('#device-profiles').removeClass('dragging')"
                                >
                                    <div class="box-content-2">
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <div class="text-right">
                                                    <button
                                                        id="3f14e19c-a638-4b48-b22e-cfab463cd0c8"
                                                        class="btn btn-primary btn-loader twist-in" v-on:click="sendProfileListMessage()"
                                                        :class="{'btn-loading': deviceActionStates['PROFILE_LIST'].status == 'PENDING' && !isTimedOut(deviceActionStates['PROFILE_LIST'].created)}"
                                                        :disabled="deviceActionStates['PROFILE_LIST'].status == 'PENDING' && !isTimedOut(deviceActionStates['PROFILE_LIST'].created) || null"
                                                    >
                                                        <span class="animate">
                                                            <loader
                                                                v-if="deviceActionStates['PROFILE_LIST'].status == 'PENDING' && !isTimedOut(deviceActionStates['PROFILE_LIST'].created)"
                                                                style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
                                                            ></loader>
                                                        </span>
                                                        <span>
                                                            <i class="fal fa-sync"></i>
                                                        </span>
                                                    </button>
                                                    <hr class="margin-xs-y-2">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <h4>${T('Profiles')}</h4>
                                                <template v-if="device.deviceType == 'IOS'">
                                                    <p>${T('Add custom Profiles (*.mobileconfig) via drag and drop or')} <a id="filebrowser">${T('choose a file')}</a></p>
                                                </template>
                                                <table2
                                                    ref="profilesTable"
                                                    class="profilesTable"
                                                    :loading="profilesTableData.loading"
                                                    :table="profilesTableData.tableData"
                                                    
                                                ></table2>
                                            </div>
                                        </div>
                                    </div>
                                    <template v-if="device.deviceType == 'IOS'">
                                        <div id="droparea">
                                            <div class="uploadcontent">
                                                <i class="fal fa-upload"></i> ${T('Upload mobileconfig')}
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <div
                                    v-if="device.info.IsMultiUser"
                                    :class="{'hidden':activeTab != 'users'}"
                                    id="device-perUserChannel"
                                >
                                    <div class="box-content-2">
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <div class="text-right">
                                                    <button
                                                        class="btn btn-primary btn-loader twist-in"
                                                        v-on:click="sendUserListMessage()"
                                                        :class="{'btn-loading': deviceActionStates['USER_LIST'].status == 'PENDING' && !isTimedOut(deviceActionStates['USER_LIST'].created)}"
                                                        :disabled="deviceActionStates['USER_LIST'].status == 'PENDING' && !isTimedOut(deviceActionStates['USER_LIST'].created) || null"
                                                    >
                                                         <span class="animate">
                                                            <loader
                                                                v-if="deviceActionStates['USER_LIST'].status == 'PENDING' && !isTimedOut(deviceActionStates['USER_LIST'].created)"
                                                                style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
                                                            ></loader>
                                                        </span>
                                                        <span>
                                                            <i class="fal fa-sync"></i>
                                                        </span>
                                                    </button>
                                                    <hr class="margin-xs-y-2">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <h4>${T('Users')}</h4>
                                                <table2
                                                    ref="perUserChannelUsersTable"
                                                    class="perUserChannelUsersTable"
                                                    :loading="perUserChannelUsersTableData.loading"
                                                    :table="perUserChannelUsersTableData.tableData"
                                                    
                                                ></table2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div :class="{'hidden':activeTab != 'restrictions'}" id="restrictions">
                                    <div class="box-content-2">
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <div class="text-right">
                                                    <button
                                                        id="6a1efd6e-84c7-4bdf-b496-c2112e93n595"
                                                        class="btn btn-primary"
                                                        v-on:click="getRestrictions()"
                                                    >
                                                        <i class="fal fa-sync"></i>
                                                    </button>
                                                    <hr class="margin-xs-y-2">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <h4>${T('Restrictions')}</h4>

                                                <template v-if="perUserChannelUsersTableData.length">
                                                    <div class="row toolbar">
                                                        <div class="col-xs-24 padding-xs-b">
                                                            <div class="box-border bg-white padding-xs-t padding-xs-l">
                                                                <div class="row flexrow flex-wrap margin-xs-0">
                                                                    <div class="col-xs-12 col-lg-4 padding-xs-0 padding-xs-b padding-xs-r">
                                                                        <label class="form-inputgroup margin-xs-b-0">
                                                                            <span class="form-icon-prefix">
                                                                                <i class="fal fa-user"></i>
                                                                            </span>
                                                                            <select v-model="restrictions.channel">
                                                                                <option value="global">{{ T('Global') }}</option>
                                                                                <template v-for="entry in perUserChannelUsersTableData">
                                                                                    <option :value="entry[0].title">{{ entry[0].title }}</option>
                                                                                </template>
                                                                            </select>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                                <table2
                                                    ref="restrictionsTable"
                                                    class="restrictionsTable"
                                                    :loading="restrictionsTableData.loading"
                                                    :table="restrictionsTableData.tableData"
                                                    
                                                ></table2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div :class="{'hidden':activeTab != 'operationsLog'}" id="device-messages">
                                    <div class="box-content-2">
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <div class="text-right">
                                                    <button
                                                        id="6a1efd6e-84c7-4bdf-b496-c2112eb4a145"
                                                        class="btn btn-primary"
                                                        v-on:click="getOperationsLogData()"
                                                    >
                                                        <i class="fal fa-sync"></i>
                                                    </button>
                                                    <hr class="margin-xs-y-2">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <h4>${T('Operations log')}</h4>
                                                <table2
                                                    ref="operationsLog"
                                                    class="operationsLog"
                                                    :loading="operationLogTableData.loading"
                                                    :table="operationLogTableData.tableData"
                                                    
                                                ></table2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="padding-xs-y-4"></div>
        </section>
    `,
    "computed": {
        "device": function(this : any) {
            this.refreshCount;
            return config.canUseNewObjectType('iosDevices') ? products.mobileSecurity.iosDevices.useStore?.().getObjectStoreObject(this.activeAccountId, useRouterStore().getObjectId || "") :  useStore().getters.getObject({
                "accountId": this.activeAccountId,
                "productType": "mobileSecurity",
                "objectType": "devices",
                "objectId": useRouterStore().getObjectId || ""
            })
        },
        "darkMode": function(this: any) {
            return useStore()?.state.browserSettings.darkmode
        },
        "thisDeviceDepProfile": function(this:any) {
            let device: any = this.device
            let thisProfile : any = this.depProfiles?.find((depProfile:any) => {
                return depProfile.profile_uuid == device?.depDeviceInfo?.profile_uuid
            })
            if (thisProfile) {
                let wasEnrolledAfterProfileAssignTime : any = undefined
                if(device.depDeviceInfo.profile_assign_time) {
                    wasEnrolledAfterProfileAssignTime = moment(device.depDeviceInfo.profile_assign_time).unix() < device.enrolledAt
                }
                device.depDeviceInfo["wasEnrolledAfterProfileAssignTime"] = wasEnrolledAfterProfileAssignTime
                device.depDeviceInfo["isEnrolledWithDepProfile"] = device.depDeviceInfo.profile_status == "pushed" && wasEnrolledAfterProfileAssignTime
                device.depDeviceInfo["extendedProfileStatus"] = device.depDeviceInfo.isEnrolledWithDepProfile ? "pushed+" : "pushed"
            }
            return thisProfile
        }
    },
    props: {

    },
    methods: {
        isObjectEmpty: jsonHelpers.isObjectEmpty,
        moment: moment,
        unescape: encodingHelpers.unescapeHTML,
        T: T,
        setActiveTab: function (this: any, tab: string) {
            this.activeTab = tab
        },
        getInfoBoxText(this:any) {
            let inventoryStreet: any = jsonHelpers.getObjectProperty(this.device, "inventory.inventoryLocation.street")
            let inventoryPostalCode: any = jsonHelpers.getObjectProperty(this.device, "inventory.inventoryLocation.postalCode")
            let inventoryCity: any = jsonHelpers.getObjectProperty(this.device, "inventory.inventoryLocation.city")
            let inventoryInventoryLocationDescription: any = jsonHelpers.getObjectProperty(this.device, "inventory.inventoryLocationDescription")
            
            let template : string = '<div>';
            if (inventoryStreet) {
                template += '<i class="fal fa-fw fa-road"></i>&nbsp;' + encodingHelpers.escapeHTML(inventoryStreet) + '<br>'
            }
            if (inventoryPostalCode) {
                template += '<i class="fal fa-fw fa-globe"></i>&nbsp;' + encodingHelpers.escapeHTML(inventoryPostalCode) + '<br>'
            }
            template += encodingHelpers.escapeHTML(inventoryCity) + '<br>';
            if (inventoryInventoryLocationDescription) {
                template += '<p>' + encodingHelpers.escapeHTML(inventoryInventoryLocationDescription) + '</p>'
            }
            template += '<div>';

            return template
        },
        getDate(timestamp : any, dateTime : boolean = false) {
            let date : any = ""
            if (dateTime) {
                date = moment.unix(timestamp).format("DD.MM.YYYY HH:mm")
            }
            else {
                date = moment.unix(timestamp).format("DD.MM.YYYY")
            }
            return date;
        },
        getInventoryLocationDialog: function(this:any) {
            dialogs.unifiedSecurity.setInventoryLocation(
                (this.device.inventory || {}).inventoryLocation || {},
                async (inventoryLocation : any, inventoryLocationDescription:any) => {
                    if(!jsonHelpers.isObjectEmpty(inventoryLocation)) {
                        if(config.canUseNewObjectType("iosDevices")) {
                            await products.mobileSecurity.iosDevices.queries.updateObjectPropertiesFromApi(this.activeAccountId, this.device.deviceId, { inventoryLocation: JSON.stringify(inventoryLocation) },"inventoryLocation",['inventory'],undefined,"/properties","POST")
                        }
                        else {
                            await useStore().dispatch(ActionTypes.updateObjectInventory, {
                                "accountId": this.activeAccountId,
                                "productType": 'mobileSecurity',
                                "objectType": 'devices',
                                "objectId": this.device.deviceId,
                                "object": { inventoryLocation: JSON.stringify(inventoryLocation) }
                            })
                        }
                        return true
                    }
                    else {
                        return false
                    }
                },
                (this.device.inventory || {}).inventoryLocationDescription
            )
        },
        initContractform: function() {
            let thisComponent : any = this
            if (!thisComponent.contractDataLoaded) {
                setTimeout(function() {
                    let datepicker : any = {}
                    let count : number = 1
                    $('[data-plugin="datepicker"]').each(function(this : any) {
                        datepicker['datepicker-'+count] = (<any>$(this)).datepicker(<any>{
                            dateFormat: 'dd.mm.yyyy',
                            onSelect: function(formattedDate : any, date : any, inst : any) {
                                thisComponent.propertiesForm[inst.$el[0].id] = formattedDate
                                inst.hide()
                            }
                        }).data('datepicker')
                        count++
                    })
                    $('main').on('scroll', function () {
                        for(let index in datepicker) {
                            if (datepicker[index].visible) {
                                var val = datepicker[index].el.value
                                datepicker[index].update();
                                datepicker[index].el.value = val
                            }
                        }
                    })
                }, 100)
                thisComponent.contractDataLoaded = true
            }
        },
        saveInventory: async function(this:any) {
            this.savingProperties = true
            let tenantDomain : any = tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || "")
            let deviceId : any = this.objectId
            let properties : any = this.propertiesForm
            if (properties.billDate) {
                properties.billDate = moment(properties.billDate, "DD.MM.YYYY").format('X')
            }
            if (properties.contractDate) {
                properties.contractDate = moment(properties.contractDate, "DD.MM.YYYY").format('X')
            }
            if (properties.handoverDate) {
                properties.handoverDate = moment(properties.handoverDate, "DD.MM.YYYY").format('X')
            }
            if (properties.warrantyStartDate) {
                properties.warrantyStartDate = moment(properties.warrantyStartDate, "DD.MM.YYYY").format('X')
            }
            if (properties.warrantyEndDate) {
                properties.warrantyEndDate = moment(properties.warrantyEndDate, "DD.MM.YYYY").format('X')
            }
            if (properties.inventoryExternalLink.indexOf('http://') == -1 && properties.inventoryExternalLink.indexOf('https://') == -1) {
                properties.inventoryExternalLink = 'http://' + properties.inventoryExternalLink
            }
            await queries.mobileSecurity.setDeviceProperties(tenantDomain, deviceId, properties)
            await this.getDeviceDetails()
            this.savingProperties = false
        },
        async getLastDeviceLocationMessage(tenantDomain: string, deviceId: string) {
            return await queries.mobileSecurity.getDeviceMessages(tenantDomain, deviceId, "?type=LOCATION&limit=1")
        },
        async getDeviceDetails(this:any,loadAllData:boolean=false) {
            let thisComponent : any = this
            let tenantDomain : any = tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || "")
            let deviceId: any = thisComponent.objectId || useRouterStore().getObjectId
            let results : any[] = []
            if(deviceId != undefined) {
                try {
                    results = await Promise.all([
                        config.canUseNewObjectType("iosDevices") ? products.mobileSecurity.iosDevices.queries.getObjectFromApi(this.activeAccountId,deviceId) : useStore().dispatch(ActionTypes.getObjectInfo, {
                            "accountId":this.activeAccountId,
                            "productType":"mobileSecurity",
                            "objectType":"devices",
                            "queryType":"devices",
                            "objectId": deviceId
                        }),
                        loadAllData ? queries.mobileSecurity.getLastDeviceBatteryInfo(tenantDomain, deviceId) : null,
                        loadAllData ? queries.mobileSecurity.getLastDeviceStorageInfo(tenantDomain, deviceId) : null,
                        loadAllData ? queries.mobileSecurity.getObjectInfos(thisComponent.activeAccountId,'profiles') : null,
                        loadAllData ? queries.mobileSecurity.getDeviceProperties(tenantDomain,deviceId) : null,
                        loadAllData ? this.getAppsTableData(deviceId) : null,
                        loadAllData ? this.getOperationsLogData(deviceId) : null,
                    ])

                }
                catch(e:any) {
                    console.error(e)

                    if(e.status == 404) {
                        router.navigate('404-'+deviceId)
                    }

                }


                if (config.canUseNewObjectType("iosApps")) {
                    await products.mobileSecurity.iosApps.queries.getObjectsFromApi(this.activeAccountId)
                }
                else {
                    await useStore().dispatch(ActionTypes.getObjectInfos, {
                        "accountId": this.activeAccountId,
                        "objectTypes": ["apps"]
                    })
                }
                

                if (this.canUseAppleDEP) {
                    if(config.canUseNewObjectType("iosDevices")) {
                        await products.mobileSecurity.iosDevices.queries.getObjectsFromApi(this.activeAccountId, undefined, [
                            { "property": "props[]", "value": "licenseUUID" },
                            { "property": "props[]", "value": "props" },
                            { "property": "props[]", "value": "profile" },
                            { "property": "props[]", "value": "deviceId" },
                            { "property": "props[]", "value": "alias" },
                            { "property": "props[]", "value": "deviceType" },
                            { "property": "props[]", "value": "serialNumber" },
                            { "property": "props[]", "value": "info" }
                        ]) 
                        await products.mobileSecurity.depProfiles.queries.getObjectsFromApi(this.activeAccountId) 
                    }
                    else {
                        await useStore().dispatch(ActionTypes.getObjectInfos,{
                            "accountId":this.activeAccountId,
                            "objectTypes":["depProfiles","depDevices"]
                        })
                    }
                }

                let device : any = config.canUseNewObjectType("iosDevices") ? products.mobileSecurity.iosDevices.useStore?.().getObjectStoreObject(this.activeAccountId,deviceId) : await useStore().getters.getObject({
                    "accountId": this.activeAccountId,
                    "productType":"mobileSecurity",
                    "objectType":"devices",
                    "objectId": deviceId
                })
                

                let batteryInfo = results[1]
                let storageInfo = results[2]
                let profiles = results[3]
                let deviceProperties = results[4]
                let depValue: any = { 'enabled' : false }

                if(device?.licenseUUID) {
                    device.licenseInformation = licenseHelpers.getLicenseByUUID(this.activeAccountId, device.licenseUUID)
                }

                if (thisComponent.$store.state.session.accounts[thisComponent.activeAccountId].mobileSecurity.settings.ios.dep != undefined) {
                    depValue = thisComponent.$store.state.session.accounts[thisComponent.activeAccountId].mobileSecurity.settings.ios.dep
                }
                else {
                    let tenant : any = await queries.unifiedSecurity.getTenantInfo(tenantDomain,["dep"])
                    depValue = tenant.dep
                }
                if (!jsonHelpers.getObjectProperty(depValue,'enabled')) {
                    /* dep disabled */
                }
                else {
                    let depDeviceInfos: any = await queries.mobileSecurity.dep.getDEPDeviceInfos(tenantDomain)
                    ;(depDeviceInfos && depDeviceInfos.devices || []).some((depDevice: any) => {
                        if (depDevice['serial_number'] == device.serialNumber) {
                            device.depDevice = true
                            device.depDeviceInfo = depDevice
                            return true
                        }
                        return false
                    })
                }

                thisComponent.propertiesForm = {
                    billNumber: deviceProperties?.billNumber || "",
                    billDate: deviceProperties?.billDate ? moment.unix(deviceProperties?.billDate).format("DD.MM.YYYY") : "",
                    hardwareDescription: deviceProperties?.hardwareDescription || "",
                    contractNumber: deviceProperties?.contractNumber || "",
                    providerCustomerNumber: deviceProperties?.providerCustomerNumber || "",
                    providerName: deviceProperties?.providerName || "",
                    providerTariff: deviceProperties?.providerTariff || "",
                    inventoryExternalLink: deviceProperties?.inventoryExternalLink || "",
                    contractDate: deviceProperties?.contractDate ? moment.unix(deviceProperties?.contractDate).format("DD.MM.YYYY") : "",
                    contractDuration: deviceProperties?.contractDuration || "",
                    handoverDate: deviceProperties?.handoverDate ? moment.unix(deviceProperties?.handoverDate).format("DD.MM.YYYY") : "",
                    warrantyStartDate: deviceProperties?.warrantyStartDate ? moment.unix(deviceProperties?.warrantyStartDate).format("DD.MM.YYYY") : "",
                    warrantyEndDate: deviceProperties?.warrantyEndDate ? moment.unix(deviceProperties?.warrantyEndDate).format("DD.MM.YYYY") : "",
                }
                
                const batteryInfoMessage = batteryInfo?.messages?.[0] || batteryInfo?.in?.messages?.[0]
                const storageInfoMessage = storageInfo?.messages?.[0] || storageInfo?.in?.messages?.[0]

                if (batteryInfo && batteryInfoMessage) {
                    $.extend(device, batteryInfoMessage.content.payload)
                }
                if (storageInfo && storageInfoMessage) {
                    $.extend(device, storageInfoMessage.content.payload)
                }
                
                let deviceLocationMessage = await thisComponent.getLastDeviceLocationMessage(tenantDomain, deviceId)
                let position : any = null
                let messages = deviceLocationMessage
                let message = messages?.messages?.[0] || messages.in?.messages[0]
                if (messages && (messages.count || messages.in?.count) && message != undefined  && message.content && message.content.payload) {
                    position = {
                        "position": {
                            "lat": message.content.payload.latitude,
                            "lng": message.content.payload.longitude,
                            "accuracy": message.content.payload.accuracy
                        },
                        "infoHtml": '<div class="content-2"><strong>' + T('Last contact') + '</strong>:' + moment(Math.min(parseInt(message.content.timestamp) * 1000, Date.now())).fromNow() + '</div>'
                    }
                }
                if (position != null) {
                    thisComponent.position = position
                }
                if (device) {
                    thisComponent.deviceProperties = deviceProperties
                    thisComponent.batteryPercentage = Number(Math.max(Math.min(device.batteryPercentage,100),0).toFixed(2)) || -1
                    thisComponent.storageMax = (device["internal"] || "").split('/')[1] || 0
                    thisComponent.storageValue = (device["internal"] || "").split('/')[0] || 0
                }

                thisComponent.initialized = true
                //this.getDeviceDetails = debounce(5000, this.getDeviceDetails)
            }
        },
        getBatteryColor: function(percentage : number) {
            let batteryPercentage : number = Number(Math.max(Math.min(percentage,100),0).toFixed(2)) || -1;
            let quarter : number = percentage > 0 ? Math.floor(percentage / 25) : 0;
            let batteryColor : string = "color-gray";
            switch(quarter) {
                case 0:
                    batteryColor = "color-red";
                    break
                case 1:
                    batteryColor = "color-red";
                    break
                case 2:
                    batteryColor = "color-yellow";
                    break
                case 3:
                    batteryColor = "color-green";
                    break
                case 4:
                    batteryColor = "color-green";
                    break
            }
            return batteryColor
        },
        getStoragePercentage: function(storageValue : number ,storageMax : number ) {
            let storagePercentage : number = 0;
            if (storageValue != undefined && storageMax != undefined) {
                try {
                    if (storageValue == storageMax && storageMax == 0) {
                        storagePercentage = 100;
                    }
                    else {
                        storagePercentage = 100 - Math.ceil(storageValue/storageMax*100);
                    }
                } catch(e:any) {}
            }
            return storagePercentage
        },
        bytesToSize: function(bytes : any) {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
            if (bytes === 0) return 'n/a'
            const i = Math.ceil(Math.floor(Math.log(bytes) / Math.log(1024)))
            if (i === 0) return `${bytes} ${sizes[i]})`
            return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
        },
        getHddColor: function(precentage : number) {
            let hddColor : string = "color-blue"
            if (precentage >= 89) {
                hddColor = "color-red"
            }
            else {
                hddColor = "color-blue"
            }
            return hddColor
        },
        deviceActionStatus: function(feature : string) {
            let thisComponent : any = this
            let features: any = {
                "DEVICE": {
                    "IOS": {
                        "major": 8,
                        "minor": 999
                    },
                    "BYOD":true,
                    "supervisedOnly":false
                },
                "LOCATION": {
                    "IOS": {
                        "major": thisComponent.device.lostMode ? 9 : -1,
                        "minor": 999
                    },
                    "BYOD":false,
                    "supervisedOnly":false,
                },
                "START_RINGTONE": {
                    "IOS": {
                        "major": thisComponent.device.lostMode ? 9 : -1,
                        "minor": 999
                    },
                    "BYOD":true,
                    "supervisedOnly":false,
                },
                "NOTIFICATION": {
                    "IOS": {
                        "major": -1,
                        "minor": 0
                    },
                    "BYOD":true,
                    "supervisedOnly":false,
                },
                "LOCK": {
                    "IOS": {
                        "major": thisComponent.device.lostMode || thisComponent.device.info.ModelName == 'AppleTV' ? -1 : 10,
                        "minor": 3
                    },
                    "BYOD":true,
                    "supervisedOnly":false,
                },
                "REBOOT": {
                    "IOS": {
                        "major": 10,
                        "minor": 3
                    },
                    "BYOD":true,
                    "supervisedOnly":true,
                },
                "SHUTDOWN": {
                    "IOS": {
                        "major": thisComponent.device.info.ModelName == 'AppleTV' ? -1 : 10,
                        "minor": 3
                    },
                    "BYOD":true,
                    "supervisedOnly":true,
                },
                "CLEAR_PASSCODE": {
                    "IOS": {
                        "major": (thisComponent.device?.iOSPerUserChannelProfiles?.length > 0) || thisComponent.device.info.ModelName == 'AppleTV' ? -1 : 10,
                        "minor": 0
                    },
                    "BYOD":false,
                    "supervisedOnly":false,
                },
                "ENTERPRISE_WIPE": {
                    "IOS": {
                        "major": -1,
                        "minor": 0
                    },
                    "BYOD":true,
                    "supervisedOnly":false,
                },
                "WIPE": {
                    "IOS": {
                        "major": 10,
                        "minor": 0
                    },
                    "BYOD":false,
                    "supervisedOnly":false,
                },
                "ENABLE_LOST_MODE": {
                    "IOS": {
                        "major": thisComponent.device.lostMode || thisComponent.device.info.ModelName == 'AppleTV' ? -1 : 10,
                        "minor": 0
                    },
                    "BYOD":true,
                    "supervisedOnly":true,
                },
                "DISABLE_LOST_MODE": {
                    "IOS": {
                        "major": thisComponent.device.lostMode ? 10 : -1,
                        "minor": 0
                    },
                    "BYOD":true,
                    "supervisedOnly":true,
                },
                "SCHEDULE_OS_UPDATE": {
                    "IOS": {
                        "major": thisComponent.device.availableUpdates?.length > 0 ? 10 : -1,
                        "minor": 0
                    },
                    "BYOD":true,
                    "supervisedOnly":true,
                }
            }

            let showElement : boolean = true
            let disabled : boolean = thisComponent.device.signedIn == false
            if ("ANDROID" == thisComponent.device.deviceType) {
                let sdk = thisComponent.device.info.sdk || 9
                let available = sdk >= features[feature]["ANDROID"].sdk
                if (features[feature]["ANDROID"].sdk == -1) {
                    showElement = false
                }
                else {
                    available ? showElement = true : showElement = false
                }
            }
            else if ("IOS" == thisComponent.device.deviceType) {
                let version = (thisComponent.device.info.version || thisComponent.device.info.OSVersion || "0.0").split(".")
                let major = version[0]
                let minor = version[1]

                if (features[feature]["IOS"].major == -1) {
                    showElement = false
                }
                else if (major > features[feature]["IOS"].major) {
                    showElement = true
                }
                else if (major == features[feature]["IOS"].major && minor >= features[feature]["IOS"].minor) {
                    showElement = true
                }
                else {
                    showElement = false
                }
            }
            if (thisComponent.device.ownership == "BYOD") {
                if (showElement == true) {
                    showElement = features[feature]['BYOD']
                }
            }
            if (!thisComponent.device.info.supervised && !thisComponent.device.info.IsSupervised) {
                if (disabled != true) {
                    disabled = features[feature]['supervisedOnly']
                }
            }
            return [showElement,disabled]
        },
        getAliasedShortDeviceId: deviceHelpers.getAliasedShortDeviceId,
        deviceAction: async function(deviceAction : string, deviceId : string) {
            let thisComponent : any = this
            let tenantDomain = tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || "")
            switch (deviceAction) {
                case "DEVICE":
                    await queries.mobileSecurity.sendDeviceMessage(tenantDomain, deviceId, { "type": "DEVICE", "payload": {} })
                    thisComponent.getDeviceDetails(true)
                break
                case "NOTIFICATION":
                    dialogs.misc.inputDialog(thisComponent.activeAccountId, "Message Text","",async (message:string) => {
                        queries.mobileSecurity.sendDeviceMessage(tenantDomain, deviceId, { "type": deviceAction, "payload": { "text": message } })
                    })                    
                break
                case "LOCK":
                    dialogs.misc.inputDialog(thisComponent.activeAccountId, "Message Text","",async (message:string) => {
                        queries.mobileSecurity.sendDeviceLockMessage(tenantDomain, deviceId, { "type": deviceAction, "payload": { "message": message } })
                    })                    
                break
                case "LOCATION":
                    dialogs.misc.inputDialog(thisComponent.activeAccountId, "Message Text",T("This message only appears in the audit log and is used for traceability of the localization."),async (message:string) => {
                        queries.mobileSecurity.sendDeviceMessage(tenantDomain, deviceId, { "type": deviceAction, "payload": { "message": message } })
                    }, "text", undefined, T("Message text"), true)                    
                break
                case "ENABLE_LOST_MODE":
                case "DISABLE_LOST_MODE":
                    let message: any = { "type": deviceAction }
                    if (deviceAction == "ENABLE_LOST_MODE") {
                        dialogs.misc.inputDialog(thisComponent.activeAccountId, "Message Text", T("This message is displayed on the device."), async (message:string) => {
                            queries.mobileSecurity.sendDeviceMessage(tenantDomain, deviceId, { "type": deviceAction, "payload": { "message": message } })
                        }, "text", undefined, T("Message text"), true)   
                    }
                    else {
                        await queries.mobileSecurity.sendDeviceMessage(tenantDomain, deviceId, message)
                    }
                break
                case "ENTERPRISE_WIPE":
                    dialogs.misc.confirmDialog(thisComponent.activeAccountId, "Confirm Delete", T("Do you really want to wipe this device? Your personal data wont be deleted."),async () => {
                        await queries.mobileSecurity.sendDeviceMessage(tenantDomain, deviceId, { "type": deviceAction, "payload": {} })
                    })
                break
                case "WIPE":
                    dialogs.misc.confirmDialog(thisComponent.activeAccountId, "Confirm Delete", T("Do you really want to wipe this device? All data that has not been backed up, will be lost."), async () => {
                        await queries.mobileSecurity.sendDeviceMessage(tenantDomain, deviceId, { "type": deviceAction, "payload": {} })
                    }, T('Delete'))
                break
                case "SCHEDULE_OS_UPDATE":

                    dialogs.mobileSecurity.updateAppleDevice(thisComponent.activeAccountId, async (updateType:any) => {
                        await queries.mobileSecurity.sendDeviceMessage(tenantDomain, deviceId, {
                            "type": "SCHEDULE_OS_UPDATE",
                            "payload": {
                                "Updates": [{
                                    "InstallAction": updateType,
                                    "ProductVersion": thisComponent.device?.availableUpdates?.[0]?.ProductVersion
                                }],
                                "RequestRequiresNetworkTether": false
                            }
                        })
                    })

                break
                default:
                    await queries.mobileSecurity.sendDeviceMessage(tenantDomain, deviceId, { "type": deviceAction, "payload": {} })
                break
            }
            thisComponent.getOperationsLogData(deviceId)
        },
        getOperationsLogData: debounce(2000,async function (this: any,deviceId:string) {
            let thisComponent : any = this
            if (this.mounted) {
                if (!deviceId) {
                    deviceId = thisComponent.objectId || useRouterStore().getObjectId
                }
                this.operationLogTableData.loading = true
                this.operationLogTableData.tableData.titles = [
                    {
                        "id":"date",
                        "text":"Date",
                        "sortable":true,
                        "sortBy":"dateSort"
                    },
                    {
                        "id":"direction",
                        "text":"Direction",
                        "sortable":false
                    },
                    {
                        "id":"type",
                        "text":"Type",
                        "sortable":false
                    },
                    {
                        "id":"status",
                        "text":"Status",
                        "sortable":false
                    },
                    {
                        "id":"actions",
                        "text":"Actions",
                        "sortable":false
                    }
                ]

                if(deviceId) {
                    let messages: any = await requestHandler.request('GET', apis.getTenantApiUrl(thisComponent.activeAccountId, "apple") + "/devices/" + deviceId + "/messages?queue=both&limit=100")
                    messages = apis.parseApiResponse(messages)
                    let operationsLogDataRaw : any = []

                    if(messages.messages) {
                        // New Shema
                        operationsLogDataRaw = (messages.messages).filter((message: any) => {
                            return message.content
                        })    
                    } else {
                        // Old Shema
                        let messagesOut : any = messages.out?.messages || []
                        let messagesIn : any = messages.in?.messages || []
                        operationsLogDataRaw = [].concat(messagesOut).concat(messagesIn).filter(function (message: any) {
                            return message.content
                        })
                    }
                    arrayHelpers.sortByObjProperty(operationsLogDataRaw, 'created', 'DESC')

                    
                    this.operationLogTableData.tableData.rows = operationsLogDataRaw.map(function (message: any) {
                        message.statusText = message.statusText ? message.statusText : message.content?.payload?.statusText || ""
                        message.statusText = thisComponent.managedAppStatusToInfo[message.statusText] ? thisComponent.managedAppStatusToInfo[message.statusText] : message.statusText
                        let thisActionButtons = []
                        if (message.queue == "out" && message.status == 'PENDING') {
                            thisActionButtons.push(
                                {
                                    "text": T("Revoke"),
                                    "title": T("Revoke"),
                                    "icon": "fal fas-fw fa-undo",
                                    "onClick": async function () {
                                        thisComponent.revokeDeviceMessage(tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || ""),deviceId, message.content.messageId)
                                    },
                                    "disabled": false,
                                    "loading": false
                                }
                            )
                        }                

                        let appName = ""
                        
                        if (message.type == 'APPLICATION_INSTALL_REQUEST' || message.type == 'APPLICATION_UNINSTALL_REQUEST') {
                            let appProperty = "Identifier"
                            if(message.content.payload.Identifier != undefined) {
                                appProperty = "Identifier"
                            } 
                            else if(message.content.payload.iTunesStoreID != undefined) {
                                appProperty = "iTunesStoreID"
                            } 
                            else if(message.content.payload.ManifestURL != undefined) {
                                appProperty = "ManifestURL"
                            }

                            let appInfo = config.canUseNewObjectType("iosApps") ? products.mobileSecurity.iosApps.useStore?.().getObjectStoreObject(thisComponent.activeAccountId, (message.content.payload.Identifier || message.content.payload.iTunesStoreID || message.content.payload.ManifestURL),appProperty) : useStore().getters.getObject({
                                "accountId":thisComponent.activeAccountId,
                                "objectId": (message.content.payload.Identifier || message.content.payload.iTunesStoreID || message.content.payload.ManifestURL),
                                "objectType":"apps",
                                "productType":"mobileSecurity",
                                "property": appProperty
                            })

                            if(appInfo != undefined) {
                                appName = appInfo.name
                            }

                        }
                        

                        return {
                            data:{
                                "date": moment(message.created * 1000).format("DD.MM.YYYY HH:mm:ss"),
                                "dateSort": message.created,
                                "direction": {
                                    labels: [
                                        message.queue == "in" ? {
                                            "title": T('Message from device to server'),
                                            "text": T('In'),
                                            "class": "",
                                            "icon": "fal fa-arrow-left",
                                        } :
                                            {
                                                "title": T('Message from server to device'),
                                                "text": T('Out'),
                                                "class": "",
                                                "icon": "fal fa-arrow-right",
                                            }
                                    ]
                                },
                                "type": {
                                    labels: [
                                        {
                                            "title": T(message.type),
                                            "text": T(message.type),
                                            "class":'',
                                            "icon":""
                                        },
                                        (message.type == 'APPLICATION_INSTALL_REQUEST' || message.type == 'APPLICATION_UNINSTALL_REQUEST' ? 
                                            {
                                                "title": appName + "(" + (message.content.payload.Identifier || message.content.payload.iTunesStoreID || message.content.payload.ManifestURL) + ")",
                                                "text": appName + "(" + (message.content.payload.Identifier || message.content.payload.iTunesStoreID || message.content.payload.ManifestURL) + ")",
                                                "class": '',
                                                "icon": "",
                                                "onClick":() => {
                                                    router.navigate('show-tenant-' + thisComponent.activeAccountId + '.sms-ios-apps-dashboard-' + (message.content.payload.Identifier || message.content.payload.iTunesStoreID || message.content.payload.ManifestURL))
                                                }
                                            }
                                            : null
                                        )
                                    ]
                                },
                                "status": {
                                    labels: [
                                        {
                                            "title": message.statusText ? message.statusText : T(stringHelpers.capitalizeFirstLetter(message.status)),
                                            "text": T(stringHelpers.capitalizeFirstLetter(message.status)),
                                            "class": message.status == 'ERROR' ? 'bg-red' : message.status == 'PENDING' ? 'bg-yellow' : '',
                                            "icon": message.status == 'ERROR' ? "fal fa-exclamation-triangle" : message.status == 'PENDING' ? "fal fa-bell" : "fal fa-info",
                                        }
                                    ]
                                },
                                "actions":{
                                    buttons:thisActionButtons
                                }
                            },
                        }
                    })


                    
                    let reversedOperationsLogData : any = operationsLogDataRaw.reverse()
                    for(let message of reversedOperationsLogData) {
                        // @ts-ignore
                        thisComponent.deviceActionStates[message.type] = { "status":message.status,"created":message.created}
                    }
                    this.operationLogTableData.loading = false
                }
            }
        }),
        sendProfileListMessage: async function(this:any) {
            if(this.objectId) {
                await queries.mobileSecurity.sendDeviceMessage(this.activeAccountId, this.objectId, { "type": "PROFILE_LIST", "payload": {} })
                let usersResult: any = await requestHandler.request('GET', apis.getTenantApiUrl(this.activeAccountId, "apple") + "/devices/" + this.objectId + "/messages?type=USER_LIST&queue=in&limit=1")
                usersResult = apis.parseApiResponse(usersResult)
                let messagesIn: any = usersResult?.in?.messages || usersResult?.messages
                for (let i: number = 0; (messagesIn[0]?.content?.payload?.users || []).length > i; i++) {
                    let thisUser: any = messagesIn[0].content.payload.users[i]
                    if (thisUser?.username != undefined) {
                        await queries.mobileSecurity.sendDeviceMessage(this.activeAccountId, this.objectId, {
                            "type": "PROFILE_LIST",
                            "iOSPerUserChannelUser": thisUser.username,
                            "payload": {}
                        })
                    }
                }
                this.getOperationsLogData()
            }
        },
        sendUserListMessage: async function(this:any) {
            await queries.mobileSecurity.sendDeviceMessage(this.activeAccountId, this.objectId, { "type": "USER_LIST", "payload": {} });
            this.getOperationsLogData()
        },
        getProfilesTableData: debounce(2000, async function (this:any, refresh : boolean = false) {
            let thisComponent : any = this


            this.perUserChannelUsersTableData.tableData.titles = [
                {
                    "id":"username",
                    "text":"Username",
                    "sortable":true,
                },
                {
                    "id":"loggedIn",
                    "text":"Logged in",
                    "sortable":true,
                },
                {
                    "id":"hasdataToSync",
                    "text":"Has data to sync",
                    "sortable":true,
                },
                {
                    "id":"actions",
                    "text":"actions",
                    "sortable":false,
                }
            ]
            
            this.profilesTableData.tableData.titles = [
                {
                    "id":"profile",
                    "text":"Profile",
                    "sortable":true
                },
                {
                    "id":"part",
                    "text":"Part",
                    "sortable":true
                },
                {
                    "id":"user",
                    "text":"User",
                    "sortable":true
                },
                {
                    "id":"actions",
                    "text":"Actions",
                    "sortable":false
                }
            ]


            if(thisComponent.objectId) {
                let profiles = await queries.mobileSecurity.getObjectInfos(thisComponent.activeAccountId, 'profiles')
                let profilesList : any[] = []
                let usersResult: any = await requestHandler.request('GET', apis.getTenantApiUrl(thisComponent.activeAccountId, "apple") +"/devices/"+ thisComponent.objectId +"/messages?type=USER_LIST&queue=in&limit=1")
                usersResult = apis.parseApiResponse(usersResult)
                        
                const getUserProfileInfo = async (thisUser: any) => {
                    let messageProfiles: any = await requestHandler.request('GET', apis.getTenantApiUrl(thisComponent.activeAccountId, "apple") + "/devices/" + thisComponent.objectId + "/messages?type=PROFILE_LIST&queue=in&iOSPerUserChannelUser=" + thisUser.username + "&limit=1");
                    messageProfiles = apis.parseApiResponse(messageProfiles);

                    ; (messageProfiles?.messages[0]?.content?.payload?.ProfileList || []).forEach((thisProfile: any) => {
                        thisProfile.iosUser = thisUser
                        thisProfile.profileName = profiles.profiles.filter((p: any) => p.profileId == thisProfile.PayloadDisplayName)[0]?.profileName || undefined
                        /* remove securepoint mdm enrollment profile, otherwise -> unenrolled */
                        if (thisProfile.PayloadIdentifier != "com.apple.mgmt.External.cd222e1d-38ca-4b23-96a8-3d3ef36cb192") {
                            profilesList.push(thisProfile)
                        }
                    })
                }

                const messagesIn = usersResult?.in?.messages || usersResult?.messages
                if ((usersResult.messages ? usersResult.count : messagesIn.length) != 0) {
                    this.perUserChannelUsersTableData.tableData.rows = (messagesIn[0]?.content?.payload?.users || []).map((thisUser:any) => {
                        let thisActionButtons = {
                            "buttons": [
                                new Button({
                                    "text": "Logout",
                                    "title": "Logout ",
                                    "icon": "fal fa-fw fa-sign-out-alt",
                                    "onClick": async function () {
                                        dialogs.misc.confirmDialog(thisComponent.activeAccountId, T('Logout') + ': ' + thisUser.username, T(`Do you really want to log out this user?`), async () => {
                                            await queries.mobileSecurity.sendDeviceMessage(
                                                thisComponent.activeAccountId,
                                                thisComponent.objectId,
                                                {
                                                    "type": "LOGOUT_USER"
                                                }
                                            )
                                            thisComponent.sendUserListMessage()
                                        })
                                    },
                                    "disabled": false,
                                    "loading": false
                                }),
                                new Button({
                                    "text": "Delete",
                                    "title": "Delete",
                                    "icon": "fal fa-fw fa-trash-alt",
                                    "onClick": async function () {
                                        dialogs.mobileSecurity.deleteSharedIpadUserDialog(thisComponent.activeAccountId, thisComponent.objectId, thisUser.username)
                                    },
                                    "disabled":false,
                                    "loading":false
                                })
                            ]
                        }
                        getUserProfileInfo(thisUser)
                        return {
                            data: {
                                "username": thisUser.username || "",
                                "loggedIn": thisUser.isLoggedIn ? T("Yes") : T("No"),
                                "hasdataToSync": thisUser.hasDataToSync ? T("Yes") : T("No"),
                                "actions": thisActionButtons, 
                            }
                        }
                    })
                }
                


                let deviceProfiles: any = await requestHandler.request('GET', apis.getTenantApiUrl(thisComponent.activeAccountId, "apple") +"/devices/"+ thisComponent.objectId +"/messages?type=PROFILE_LIST&limit=1");
                deviceProfiles = apis.parseApiResponse(deviceProfiles)

                const profileMessagesIn : any[] = deviceProfiles?.in?.messages || deviceProfiles?.messages

                ;(profileMessagesIn[0]?.content?.payload?.ProfileList || []).forEach(function(thisProfile:any) {
                    if(thisProfile) {
                        thisProfile.profileName = profiles.profiles.filter((p: any) => p.profileId == thisProfile.PayloadDisplayName)[0]?.profileName || undefined
                        /* remove securepoint mdm enrollment profile, otherwise -> unenrolled */
                        if(thisProfile.PayloadIdentifier != "com.apple.mgmt.External.cd222e1d-38ca-4b23-96a8-3d3ef36cb192") {
                            profilesList.push(thisProfile)
                        }
                    }
                })


                this.profilesTableData.tableData.rows = profilesList.map(function(profile:any) {
                    return {
                        data: {
                            "profile": profile?.profilename || profile?.PayloadDisplayName || profile?.PayloadUUID || "",
                            "part":profile?.PayloadIdentifier || "",
                            "user":profile?.iosUser?.username || "Device",
                            "actions":function(){
                                return profile.PayloadIdentifier ? (
                                {
                                    "buttons":[{
                                        "text": T("Send uninstall request"),
                                        "title": T("Send uninstall request"),
                                        "icon": "fal fa-fw fa-trash",
                                        "onClick": async function() {
                                            thisComponent.uninstallProfilePart(profile.PayloadIdentifier,profile.iosUser?.username || undefined)
                                        },
                                        "disabled": false,
                                        "loading": false
                                    }]
                                }) : ""
                            }()
                        }
                    }
                })

                if (refresh) {
                    thisComponent.getOperationsLogData()
                }

                this.profilesTableData.loader = false
                this.perUserChannelUsersTableData.loader = false
            }
        }),
        uninstallProfilePart: async function(partId : any,perUserChannel:any) {
            let thisComponent : any = this
            let deviceType : string = thisComponent.device.deviceType
            let part = partId
                .replace("de.securepoint.sms.ios.profile.", "")
                .replace("de.securepoint.sms.android.profile.", "")
            let isCustomProfile = part == partId

            dialogs.misc.confirmDialog(thisComponent.activeAccountId, "Confirm Delete", sprintf(T("Do you want to delete profile %s?"), partId), async () => {
                if (isCustomProfile || deviceType == "IOS") {
                    if (deviceType == "IOS" && perUserChannel != undefined) {
                        await queries.mobileSecurity.sendDeviceMessage(thisComponent.activeAccountId, thisComponent.objectId, {
                            "type": "REMOVE_PROFILE",
                            "iOSPerUserChannelUser": perUserChannel,
                            "payload": { "Identifier": partId }
                        })
                    }
                    else {
                        await queries.mobileSecurity.sendDeviceMessage(thisComponent.activeAccountId, thisComponent.objectId, { "type": "REMOVE_PROFILE", "payload": { "Identifier": partId } })
                    }
                }
                else {
                    await queries.mobileSecurity.sendDeviceMessage(thisComponent.activeAccountId, thisComponent.objectId, { "type": "REMOVE_PROFILE_" + part.toUpperCase() })
                }
                await queries.mobileSecurity.sendDeviceMessage(thisComponent.activeAccountId, thisComponent.objectId, { "type": "PROFILE_LIST", "payload": {} })
                thisComponent.getProfilesTableData()
            })

        },
        getAppsTableData: debounce(2000,async function (this:any, refresh : boolean = false,deviceId:string) {
            this.appsTableData.loader = true
            let thisComponent : any = this
            if(this.mounted) {
                if(!deviceId) {
                    deviceId = thisComponent.objectId || useRouterStore().getObjectId
                }
                if(deviceId) {
                    let mergedAppsList: any = {}
                    if (refresh) {
                        queries.mobileSecurity.sendDeviceMessage(tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || ""), deviceId, { "type": "APPLICATION_LIST", "payload": {} })
                        queries.mobileSecurity.sendDeviceMessage(tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || ""), deviceId, { "type": "MANAGED_APPLICATION_LIST", "payload": {} })
                        queries.mobileSecurity.sendDeviceMessage(tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || ""), deviceId, { "type": "MANAGED_APPLICATION_CONFIGURATION", "payload": {} })
                    }
                    let appsList: any = await requestHandler.request('GET', apis.getTenantApiUrl(thisComponent.activeAccountId, "appleDeviceApps") + "/devices/" + deviceId + "/apps")
                    this.appsTableData.tableData.titles = [
                        {
                            "id":"name",
                            "text":"Name",
                            "sortable":true
                        },
                        {
                            "id": "version",
                            "text": "Version",
                            "sortable": true
                        },
                        {
                            "id": "bundleId",
                            "text": "Bundle ID",
                            "sortable": true
                        },
                        {
                            "id": "status",
                            "text": "Status",
                            "sortable": false
                        },
                        {
                            "id": "actions",
                            "text": "Actions",
                            "sortable": false
                        }
                    ]

                    if (appsList) {
                        this.appsTableData.tableData.rows = Object.values(appsList).map(function (app: any) {
                            let button = new Button({
                                "onClick": () => { thisComponent.requestUninstallApp(app.id, app.name) },
                                "icon": "fal fa-trash",
                                "text": T("Send uninstall request"),
                                "title": T("Send uninstall request"),
                            })

                            let button2 = new Button({
                                "onClick": () => { thisComponent.showAppConfiguration(app) },
                                "icon": "fal fa-fw fa-info",
                                "text": T("Show configuration"),
                                "title": T("Show configuration"),
                            })

                            const btns = []

                            if (app.state === "managed") {
                                btns.push(button)
                            }

                            if (Object.keys(app?.configuration || {}).length > 0) {
                                btns.push(button2)
                            }

                            return {
                                data: {
                                    "name": app.name || "",
                                    "version": app.version || "",
                                    "bundleId": app.id,
                                    "status": T(app.state.replaceAll("-", " ")),
                                    "actions": { buttons: btns }
                                }
                            }
                        })
                    }


                    if (refresh) {
                        thisComponent.getOperationsLogData()
                    }
                    this.appsTableData.loader = false
                }
            }
        }),
        downloadAppsCSV: async function(this:any) {
            queries.misc.download("/sms-mgt-api/api/2.0/tenants/" + this.activeAccountId + ".sms/apple/devices/" + this.objectId + "/apps?csv=1", "apps.csv")
        },
        requestUninstallApp: async function(appPackage : any,appName : any) {
            let thisComponent : any = this
            let payload: any = {}
            payload[thisComponent.device.deviceType == "IOS" ? "Identifier" : "package"] = appPackage
            appName = appName.toString()

            dialogs.misc.confirmDialog(thisComponent.activeAccountId, "Confirm Delete", sprintf(T("Do you want to delete app %s?"), encodingHelpers.escapeHTML(appName)),async () => {
                await queries.mobileSecurity.sendDeviceMessage(thisComponent.activeAccountId, thisComponent.objectId, { "type": "APPLICATION_UNINSTALL_REQUEST", "payload": payload })
                thisComponent.getAppsTableData(true)
            })
        },
        showAppConfiguration: function(app: any) {
            let tableData: any = {
                "loading": false,
                "titles": [
                    {
                        "id": "key",
                        "text": "Key",
                        "sortable": true
                    },
                    {
                        "id": "data",
                        "text": "Data",
                        "sortable": true
                    },
                ],
                "rows": (Object.entries(app.configuration)).map(([key, value]: any) => {
                    return {
                        data: {
                            "key": encodingHelpers.escapeHTML(key),
                            "data": encodingHelpers.escapeHTML(value)
                        }
                    }
                })
            }

            let newModal: any = {
                "id": "keyedAppStates",
                "accountId": useStore().state.session.activeAccountId,
                "abortable": true,
                "content": {
                    "title": {
                        "text": T("App Configuration")
                    },
                    "body": {
                        "use": true,
                        "content": undefined,
                        "component": "key-app-states",
                        "properties": {
                            tableData: tableData
                        }
                    }
                },
                "buttons": [
                    {
                        "text": T("Close"),
                        "icon": "fal fa-times",
                        "onClick": async function () {
                            vueApp.$refs.modals.removeModal()
                        },
                        "align": "right",
                        "loading": false
                    }
                ]
            }

            vueApp.$refs.modals.addModal(newModal)
        },
        setThWidth: function(ref : string) {
            let thisComponent : any = this
            setTimeout(function() { thisComponent.$refs[ref]?.setThWidth() }, 100)
        },
        revokeDeviceMessage: async function(tenantDomain : string ,deviceId : string ,messageId : string ) {
            let thisComponent : any = this
            await queries.mobileSecurity.revokeDeviceMessage(tenantDomain, deviceId, messageId)
            thisComponent.getOperationsLogData()
        },
        initDropZone: function(dropZoneId : string) {
            let thisComponent : any = this
            let activeTenantDomain = tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || "")
            if (!thisComponent.dropZoneInitialized && thisComponent != undefined) {
                if (thisComponent.device.deviceType == "IOS") {
                    Dropzone.options.droparea = {
                        "clickable": '#filebrowser',
                        "uploadMultiple": false,
                        "paramName": "file",
                        "accept": function (file: any, done: Function) {
                            if (!/mobileconfig$/.test(file.name)) {
                                done(T("Wrong file format. Please upload .mobileconfig files."))
                            }
                            else {
                                done()
                            }
                        },
                        "previewTemplate": `
                        <div class="dz-preview dz-file-preview">
                            <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                            <div class="dz-success-mark"><span><i class="fal fa-check color-lightgreen"></i> ${T('PROFILE SUCCESSFULLY UPLOADED')}</span></div>
                            <div class="dz-error-mark"><span><i class="fal fa-times color-red"></i> ${T('ERROR UPLOADING FILE')}</span></div>
                            <br>
                            <div class="dz-details">
                                <div class="dz-filename"><strong>${T('File')}:</strong> <span data-dz-name></span></div>
                            </div>
                            <div class="dz-error-message"><strong>${T('Error')}:</strong> <span data-dz-errormessage></span></div>
                            <br>
                            <div class="dz-close empty"></div>
                        </div>`,
                    }
                    var myDropzone = new Dropzone(dropZoneId, { url: `/sms-mgt-api/api/1.1/tenants/${activeTenantDomain}/devices/${thisComponent.objectId}/profiles` })
                    let $buttonTemplate = $('<a class="btn btn-primary">'+T("back")+'</a>')
                    myDropzone.on("dragover",function() {
                        $('#droparea').removeClass('dz-started')
                        $('#droparea').find('.dz-preview').remove()
                    })
                    myDropzone.on("complete",function() {
                        $('.dz-preview').find('.dz-close.empty').each(function() {
                            $(this).append($buttonTemplate).find('.btn').on('click',async function() {
                                $('#droparea').removeClass('dz-started')
                                $(this)
                                .parent('.dz-close')
                                .parent('.dz-preview')
                                .remove()

                                
                                await queries.mobileSecurity.sendDeviceMessage(activeTenantDomain, thisComponent.objectId, { "type": "PROFILE_LIST", "payload": {} })
                            })
                            $(this).removeClass('empty')
                        })
                    })
                    thisComponent.dropZoneInitialized = true
                }
            }
        },
        isTimedOut: function(timestamp : number) {
            let result : boolean = false
            var now = Math.floor(Date.now() / 1000)
            if (now - timestamp > 600) {
                result = true
            }
            return result
        },
        getRestrictions: async function(this:any, refresh:boolean = false) {

            this.restrictionsTableData.tableData.titles = [
                {
                    "id":"restrictions",
                    "text":"Restrictions",
                    "sortable":false
                },
                {
                    "id":"value",
                    "text":"Value",
                    "sortable":false
                }
            ]

            if (this.objectId && this.device.ownership != 'BYOD') {
                this.restrictionsTableData.loading = true


                let messagePayload : any = { 
                    "type": "RESTRICTIONS", 
                    "payload": {} 
                }
                if(this.restrictions.channel != 'global') {
                    messagePayload.iOSPerUserChannelUser = this.restrictions.channel
                }
                let response = await queries.mobileSecurity.sendDeviceMessage(this.activeAccountId,this.objectId,messagePayload)
                let messageId = response.messageId
                

                useStore().commit(MutationTypes.addSubscriptionHook,{
                    "accountId":this.activeAccountId,
                    "hookKey":"deviceRestrictions",
                    "hookFunction":(message:any) => {
                        if (message.data?.type == "STATUS" && message.data?.status == "RECEIVED" && message.data?.payload.messageId == messageId) {
                            let restrictions = message.data?.payload?.data?.GlobalRestrictions
                            this.restrictionsTableData.tableData.rows = []



                            for(let property in restrictions.restrictedBool) {
                                this.restrictionsTableData.tableData.rows.push({
                                    data:{
                                        "restrictions":property || "",
                                        "value": restrictions.restrictedBool[property].value
                                    }
                                })
                            }
                            for(let property in restrictions.restrictedValue) {
                                this.restrictionsTableData.tableData.rows.push({
                                    data:{
                                        "restrictions": property || "",
                                        "value": restrictions.restrictedValue[property].value
                                    }
                                })
                            }
                            for(let property in restrictions.union) {
                                this.restrictionsTableData.tableData.rows.push({
                                    data:{
                                        "restrictions":property || "",
                                        "value": restrictions.union[property].values.join(', ')
                                    }
                                })
                            }

                            useStore().commit(MutationTypes.deleteSubscriptionHook,{
                                "accountId":this.activeAccountId,
                                "hookKey":"deviceRestrictions"
                            })
                        }
                    }
                })
                this.restrictionsTableData.loading = false
            }
        }
    },
    watch: {
        "darkmode": function(this:any) {
            this.getDeviceDetails(false)
        },
        "restrictions.channel": function(this:any) {
            this.getRestrictions()
        },
        initialized: {
            handler: function(initialized : any) {
                if(initialized) {
                    let thisComponent : any = this
                    let device : any = thisComponent.device;
                    if ($('#droparea').length && device != undefined) {
                        thisComponent.initDropZone('#droparea')
                    }

                    const getDetails = debounce(5000,() => {
                        thisComponent.getDeviceDetails(false)
                    })
                    const getOperationsLog = debounce(5000, () => {
                        thisComponent.getOperationsLogData()
                    })
                    const getAppsTableData = debounce(5000, () => {
                        thisComponent.getAppsTableData()
                    })
                    const getProfilesTableData = debounce(5000, () => {
                        thisComponent.getProfilesTableData()
                    })


                    thisComponent.$store.commit(MutationTypes.addSubscriptionHook,{
                        "accountId":thisComponent.$root.activeAccountId,
                        "hookKey":"deviceDetails",
                        "hookFunction":function(message:any) {
                            if (message.topic == "/device/ping" && device.deviceId == message.data?.deviceId) {
                                $('#pingbutton').addClass('btn-loading')
                                setTimeout(function() {
                                    $('#pingbutton').removeClass('btn-loading')
                                },5000)
                            }
                            if (message.topic == "/device/pong" && device.deviceId == message.data?.deviceId) {
                                getDetails()
                                $('#pingbutton').removeClass('btn-loading')
                            }
                            if (message.data?.type == "STATUS" && message.data?.status == "RECEIVED") {
                                getDetails()
                                getOperationsLog()
                            }
                            if (message.data?.type == "PROFILE_LIST" && message.data?.status == "RECEIVED") {
                                getProfilesTableData()
                                getOperationsLog()
                            }
                            if (message.data?.type == "USER_LIST" && message.data?.status == "RECEIVED") {
                                getProfilesTableData()
                                getOperationsLog()
                            }
                            if (message.topic == "DECLARATIVE_MANAGEMENT-STATUS-REPORT") {
                                getAppsTableData()
                                getOperationsLog()
                            }
                        }   
                    })
                }
            }
        },
        device: {
            handler: function(device : any,deviceOld : any) {
                let thisComponent : any = this
            },
            deep:true
        },
        "device.inventory": {
            handler: function(this:any) {
                this.deviceProperties
            },
            deep:true
        },
        deviceProperties: {
            handler: function(deviceProperties : any,devicePropertiesOld : any) {
                let thisComponent : any = this
                if (deviceProperties != undefined) {
                    thisComponent.initContractform()
                }
            },
            deep:true
        }
    },
    created: async function(this:any) {
        if(this.objectId) {
            this.managedAppStatusToInfo = ManagedApplicationStatusToInfo
            this.getDeviceDetails(true)
        }
    },
    mounted(this:any) {
        this.mounted = true
    },
    beforeUnmount(this:any) {
        this.mounted = false
        useStore().commit(MutationTypes.deleteSubscriptionHook,{
            "accountId":this.$root.activeAccountId,
            "hookKey":"deviceDetails"
        })
    },
    "components": {
        "loader" : loaderComponent,
        "device-inline-rename" : deviceInlineRenameComponent,
        "inline-itemlist-input" : inlineItemlistInputComponent,
        "regularly-updated-data": regularlyUpdatedDataComponent,
        "googleMap" : googleMapComponent,
        "itemlist-item": itemlistItemComponent,
        "table2" : table2Component
    }
}
export default deviceDetailsComponent
