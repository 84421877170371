import tenantHelpers from "@/helpers/helpers.tenants"
import type { MenuEntry, ObjectTypePayload } from "../../objectType"
import ObjectType from "../../objectType"
import { T } from "@/classes/i18n"
import router from "@/router/router"
import Button from "@/classes/buttons"

export interface HomescreenLayoutBase {
    "Dock": HomescreenLayoutPage,
    "Pages": HomescreenLayoutPage[]
}
// Interfaces for default homescreenlayout payload
export interface HomescreenLayout {
    "Name": string,
    "layoutId": string,
    "Dock": HomescreenLayoutPage,
    "Pages": HomescreenLayoutPage[]
}
export type HomescreenLayoutPage = HomescreenLayoutItem[] | HomescreenLayoutFormItem[]

export interface HomescreenLayoutItem {
    "BundleID"?: string, // Only if Type is "Application"
    "DisplayName"?: string,
    "Pages"?: HomescreenLayoutPage[], // Only if Type is "Folder"
    "Type": HomescreenLayoutItemType.Application | HomescreenLayoutItemType.Folder | HomescreenLayoutItemType.WebClip,
    "URL"?: string // Only if Type is "WebClip"
}
export interface HomescreenLayoutFormItem extends HomescreenLayoutItem {
    "assetInfos"?: any // Only used on frontend
    "draggingItemOver"?: boolean // Only used on frontend
    "randomId"?: string // Only used on frontend
    "webclipId"?: string // Only used on frontend
}

export enum HomescreenLayoutItemType {
    "Application" = "Application",
    "Folder" = "Folder",
    "WebClip" = "WebClip"
}

class HomescreenLayouts extends ObjectType<HomescreenLayout> {
    constructor(payload: ObjectTypePayload<HomescreenLayout>) {
        super(payload)

        // ITEMLIST
        this.itemlist.getToolbarButtons = (accountId: string, component: any) => {
            return [
                {
                    icon: 'fal fa-plus',
                    title: T('Add layout'),
                    link: "#add-tenant-" + tenantHelpers.getTenantDomain(accountId) + "-homescreenlayout",
                    id: 'addHomescreenLayout'
                }
            ]
        }
        // ITEMLIST-ITEM
        this.itemlistItem.hasCheckbox = true
        this.itemlistItem.getDisabledState = (accountId, item?) => {
            return false
        }
        this.itemlistItem.isClickable = (accountId, item?) => {
            return true
        }
        this.itemlistItem.onClick = (accountId, item?) => {
            let objectId = item?.layoutId
            if (objectId) {
                router.navigate('edit-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-homescreenlayout-' + objectId)
            }
        }
        this.itemlistItem.getStatus = (accountId, item?) => {
            return undefined
        }
        this.itemlistItem.getMenuEntries = (accountId, item) => {
            let menuEnties: MenuEntry[] = [
                new Button({
                    title: T('Edit'),
                    text: T('Edit'),
                    link: '#edit-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-homescreenlayout-' + item?.layoutId,
                    icon: 'fal fa-edit'
                }),
                new Button({
                    "icon": "fal fa-trash",
                    "text": T("Delete"),
                    "onClick": () => {
                        this.dialogs.getDeleteObjectDialog(accountId, item)
                    },
                    "loading": false,
                    "disabled": false,
                })
            ]
            return menuEnties
        }
        this.itemlistItem.getDetails = (accontId, item?) => {
            let result = [
                {
                    "iconClass": "fal fa-rocket",
                    "key": "Apps",
                    "title": "Apps",
                    "value": this.countItems(item, HomescreenLayoutItemType.Application).toString()
                },
                {
                    "iconClass": "fal fa-video",
                    "key": "Webclips",
                    "title": "Webclips",
                    "value": this.countItems(item, HomescreenLayoutItemType.WebClip).toString()
                },
                {
                    "iconClass": "fal fa-folder-closed",
                    "key": T("Folders"),
                    "title": T("Folders"),
                    "value": this.countItems(item, HomescreenLayoutItemType.Folder).toString()
                }
            ]
            return result
        }
        this.itemlistItem.getLabels = (accountId, item?) => {
            return []
        }
    }

    countItems(homescreenLayout?: HomescreenLayout, type?: HomescreenLayoutItemType.Application | HomescreenLayoutItemType.WebClip | HomescreenLayoutItemType.Folder) {
        let count = (homescreenLayout?.Dock || []).filter((item) => {
            return item.Type == type
        }).length;

        ; (homescreenLayout?.Pages || [])?.forEach((childPage) => {
            count = count + this.countAppsItems(childPage, type)
        })
        return count
    }

    countAppsItems(page?: HomescreenLayoutPage, type?: HomescreenLayoutItemType.Application | HomescreenLayoutItemType.WebClip | HomescreenLayoutItemType.Folder) {
        let count = 0;
        (page || []).forEach((item: HomescreenLayoutItem | HomescreenLayoutFormItem) => {
            if (item.Type == type) {
                count++
            }
            if (item.Type == HomescreenLayoutItemType.Folder && item.Pages != undefined) {
                item.Pages?.forEach((childPage) => {
                    const childCount = this.countAppsItems(childPage, type)
                    count = count + childCount
                })
            }
        })
        return count
    }

    stripExtrasFromHomescreenLayout(homescreenLayout: HomescreenLayout) {
        homescreenLayout.Pages.forEach(function (homescreenLayoutPage, pageIndex) {
            homescreenLayoutPage.forEach(function (item: HomescreenLayoutFormItem) {
                delete item.webclipId
                delete item.assetInfos
                delete item.draggingItemOver
                delete item.randomId
                if (item.Type != 'Folder') {
                    delete item.DisplayName
                }
                if (item.Type == 'Folder') {
                    delete item.BundleID
                    delete item.URL
                }
                if (item.Type == 'Application') {
                    delete item.DisplayName
                    delete item.URL
                }
                item.Pages?.forEach(function (folderPage) {
                    folderPage.forEach(function (folderItem: HomescreenLayoutFormItem) {
                        if (folderItem.Type != 'Folder') {
                            delete folderItem.DisplayName
                        }
                        if (folderItem.Type == 'Folder') {
                            delete folderItem.BundleID
                            delete folderItem.URL
                        }
                        delete folderItem.webclipId
                        delete folderItem.assetInfos
                        delete folderItem.draggingItemOver
                        delete folderItem.randomId
                    })
                })
            })
        })
        homescreenLayout.Dock.forEach(function (item: HomescreenLayoutFormItem) {
            delete item.webclipId
            delete item.assetInfos
            delete item.draggingItemOver
            delete item.randomId
            if (item.Type != 'Folder') {
                delete item.DisplayName
            }
            item.Pages?.forEach(function (folderPage) {
                folderPage.forEach(function (folderItem: HomescreenLayoutFormItem) {
                    if (folderItem.Type != 'Folder') {
                        delete folderItem.DisplayName
                    }
                    delete folderItem.webclipId
                    delete folderItem.assetInfos
                    delete folderItem.draggingItemOver
                    delete folderItem.randomId
                })
            })
        })
        return homescreenLayout
    }
}

const homescreenLayouts = new HomescreenLayouts({
    "slug": "homescreenlayouts",
    "objectType": "homescreenlayouts",
    "productType": "mobileSecurity",
    "hasStore": true,
    "apiInfo": {
        "url": "/sms-mgt-api/api/2.0",
        "getCountGETProperties": "?select=data.count",
        // GET
        "getObjectListMethod": "GET",
        "getObjectListPath": "/tenants/{tenantDomain}/ios/homeScreenLayout/layouts",
        "getObjectListResponseProperty":"homeScreenLayout",
        // ADD
        "addObjectMethod": "POST",
        "addObjectPath": "/tenants/{tenantDomain}/ios/homeScreenLayout/layouts/",
        //UPDATE
        "updateObjectMethod": "PUT",
        "updateObjectPath" : "/tenants/{tenantDomain}/ios/homeScreenLayout/layouts/{objectId}",
        // DELTE
        "deleteObjectMethod": "DELETE",
        "deleteObjectPath": "/tenants/{tenantDomain}/ios/homeScreenLayout/layouts/{objectId}"
    },
    "appearance": {
        "showInSidebar": true,
        "showOnDashboard": false,
        "color": "red",
        "iconClass": "fal fa-mobile",
        "text": {
            "plural": "Home Screen Layouts",
            "singular": "Home Screen Layout",
            "title": "Home Screen Layouts",
            "sidebarName": "Home Screen Layouts"
        }
    },
    "objectTypeInfo": {
        "nameProperty": {
            "primary": "Name",
            "pathToPrimaryProperty": undefined,
            "secondary": undefined,
            "pathToSecondaryProperty": undefined
        },
        "primaryKeyProperty": {
            "property": "layoutId",
            "pathToPrimaryProperty": undefined
        }
    }
})
export default homescreenLayouts