<script setup lang="ts">
    import getterHelpers from "@/helpers/helpers.getters";
    import loader from "../components/loader.vue";
    import { T } from "@/classes/i18n";
    import { computed, onMounted, ref, watch } from "vue";
    import inputVueSelect from "../inputtypes/input-vue-select.vue";
    import inputNumber from "../inputtypes/input-number.vue";
    import inputText from "../inputtypes/input-text.vue";
    import deviceHelpers from "@/helpers/helpers.devices";
    import objectStores from "@/classes/init";
    import ipaddr from "@/lib/ipaddr";
    import validationHelpers from "@/helpers/helpers.validation";
    import timeHelpers from "@/helpers/helpers.time";
    import products from "@/classes/objectTypes";
    import { ActionTypes } from "@/store/vuex.store";
    import { type UtmNode } from "@/classes/objectTypes/unifiedNetwork/nodes";

    const props = defineProps<{
        properties: {
            utmId:"",
            port:"",
            interface:"",
            hostname:"",
            transferNetwork:"",
            alias:"",
            satelliteCount:0
        }
    }>();

    const activeAccountId = computed(() => {
        return getterHelpers.useStore()?.getters.getActiveAccountId
    })

    const utmOptions = computed(() => {
        let result = <selectOption[]>[]
        const utms = objectStores.uscUtms.getObjectsFromStore(activeAccountId.value)
        
        result = utms.map((utm) => {
            const thisUtmState = getterHelpers.useStore()?.getters.getObject({
                "accountId": activeAccountId.value,
                "productType": "unifiedSecurityConsole",
                "objectType": "ccutmStates",        
                "objectId": utm.utmId,
            })

            let utmVersion = objectStores.uscUtms.getCurrentUtmVersion(utm)
            const hasVersion = validationHelpers.versionCompare(utmVersion,"12.8.0") === 0 || validationHelpers.versionCompare(utmVersion,"12.8.0") === 1

            return <selectOption>{
                "id":utm.utmId,
                "text": utm?.utmname + " (" + deviceHelpers.getShortDeviceId(utm.utmId) + ")" + (thisUtmState.online == false ? " | " + T("Offline") : (!hasVersion ? " | " + T("Version nicht kompatibel") : (utm.permissions?.sunEnabled !== true ? " | " + T("Keine Berechtigung") : (utm.pinState?.enabled !== true ? " | " + T("Keine PIN") : "")))),
                "disabled":thisUtmState.online === false || utm.permissions?.sunEnabled !== true || utm.pinState?.enabled !== true || !hasVersion,
                "groupId": (thisUtmState.online === false || utm.permissions?.sunEnabled !== true || utm.pinState?.enabled !== true || !hasVersion) ? '#unavailableUtmsHeader' : '#availableUtmsHeader'
            }
        })
        result.push({
            "type": 'groupName',
            id: '#availableUtmsHeader',
            text: T('Verfügbar'),
            disabled:true
        })
        result.push({
            "type": 'groupName',
            id: '#unavailableUtmsHeader',
            text: T('Nicht verfügbar'),
            disabled:true
        })

        
        return result
    })

    const initialized = ref(false)
    const selectedUtm = ref(null)
    const port = ref(51820)
    const selectedInterface = ref(<null|number>null)
	const selectedHostname = ref("")
    const transferNetwork = ref("")
    const alias = ref("")
    const hasIpChanged = ref(false)
    const transferNetworkErrors = computed(() => {
        let thisErrors : string[] = []
        if(hasIpChanged.value == true ) {
            if (transferNetwork.value.length >= 1) {
                let address : string | ipaddr = transferNetwork.value
                try {
                    address = new ipaddr(transferNetwork.value)
                }
                catch(e) {
                    
                }
                if (typeof address != "string" && (address.isIPv4() || address.isIPv6())) {
                    if(address.hasCidr()) {
                        if (address.isIPv4()) {
                            let cidr = Number(address.cidr) as NumericRange<CreateArrayWithLengthX<16>,30>

                            if (!validationHelpers.isPrivateIPv4(address.addr, false)) {
                                thisErrors.push("Enter a private IP address.")
                            }
                            if ((Number(address.cidr) <= 30 && Number(address.cidr) >= 16) && (props.properties.satelliteCount + 1) > products.unifiedNetwork.topologies.v4CidrToIpLimit[String(address.cidr) as keyof typeof products.unifiedNetwork.topologies.v4CidrToIpLimit]) {
                                thisErrors.push("There are too many UTMs in this network.")
                            }
                            if (Number(address.cidr) > 30 || Number(address.cidr) < 16) {
                                thisErrors.push("Enter a network mask between /16 and /30.")
                            }
                            else {
                                const baseCheckResult = validationHelpers.isCorrectIpV4Base(address.addr,cidr)
                                if(baseCheckResult !== true) {
                                    thisErrors.push(baseCheckResult.message)
                                }
                            }
                        }

                        if (address.isIPv6()) {
                            let cidr = Number(address.cidr) as NumericRange<CreateArrayWithLengthX<112>,126>
                            if (!validationHelpers.isPrivateIpV6(address.addr, false)) {
                                thisErrors.push("Enter a private IP address.")
                            }
                            if ((Number(address.cidr) <= 126 && Number(address.cidr) >= 112) && (props.properties.satelliteCount + 1) > products.unifiedNetwork.topologies.v6CidrToIpLimit[String(address.cidr) as keyof typeof products.unifiedNetwork.topologies.v6CidrToIpLimit]) {
                                thisErrors.push("There are too many UTMs in this network.")
                            }
                            if (Number(address.cidr) > 126 || Number(address.cidr) < 112) {
                                thisErrors.push("Enter a network mask between /112 and /126.")
                            }
                            else {
                                const baseCheckResult = validationHelpers.isCorrectIpV6Base(address.addr,cidr)
                                if(baseCheckResult !== true) {
                                    thisErrors.push(baseCheckResult.message)
                                }
                            }
                        }

                    }
                    else {
                        thisErrors.push("Missing network mask.")
                    }
                }
                else {
                    thisErrors.push("The specified address does not match an ipv4 or ipv6 address.")
                }
            }
            else {
                thisErrors.push("Enter a private IP address.")
            }
        }
        return thisErrors
    })
    
    const responseErrors = ref(<any[]>[])
    

    const formattedResponseErrors = ref(<{ 'property': string, 'error': string }[]>[])

    const selectedInterfaceInfo = computed(() => {
        return selectedInterface.value ? interfaceOptions.value?.find((option) => { return option.id == selectedInterface.value }) : undefined
    })

    const loadingNodeInfo = ref(false)
    const selectedNodeInfo = computed(() => {
        return products.unifiedNetwork.utmNodes.useStore?.().getObjectStoreObject(activeAccountId.value,selectedUtm.value || "")
    })
    const selectedUtmErrors = ref(<string[]>[])
    const canUseUtm = computed(() => {
        return selectedNodeInfo.value != undefined && interfaceOptions.value?.length > 0 && !selectedNodeInfo.value.errors && networkViewErrors.value.length == 0 && selectedUtmErrors.value.length == 0
    })

    const interfaceOptions = computed(() => {
        let result = <(selectOption & any)[]>[]
        
        result = (selectedNodeInfo.value?.nkView?.interfaces?.map((interfaceInfo) => {
            const addressList = interfaceInfo.addresses?.map((addressInfo) => {
                return addressInfo.address
            }).filter((ipAddr) => {
                const address = new ipaddr(ipAddr)
                if (address.isIPv4() && !validationHelpers.isPrivateIPv4(address.addr, false)) {
                    return true
                }
                else if (address.isIPv6() && !validationHelpers.isPrivateIpV6(address.addr, false)) {
                    return true
                }
                return false
            }).join(", ")
            const hostname = interfaceInfo.dynDns?.hostname
            return {
                "text": interfaceInfo.name + (hostname ? " | " + hostname : "") + (addressList?.length ? " (" + addressList +")" : ""),
                ...interfaceInfo
            }
        }) || []).filter((interfaceInfo:any) => {
            return interfaceInfo.isCoreCompatible === true
        })
        return result
    })

	const hostnameOptions = computed(() => {
        const sif = selectedNodeInfo.value?.nkView?.interfaces?.find((NkViewInterface) => {
			return NkViewInterface.id === selectedInterface.value
		})

		if (!sif){ 
            return <selectOption[]>[] 
        }
        
        const hostnameOptions = [...(sif.addresses?.map((address) => address.address) || [])].filter((address) => {
            const testAddress = new ipaddr(address)
            if (testAddress.isIPv4()) {
                return !validationHelpers.isPrivateIPv4(testAddress.addr, false)
            }
            else if(testAddress.isIPv6()) {
                return !validationHelpers.isPrivateIpV6(testAddress.addr, false)
            }
            else {
                return false
            }
            
        })
		const hostname = sif.dynDns?.hostname
		if(hostname){
			hostnameOptions.push(hostname)
		}
		const result: selectOption[] = hostnameOptions.map((option) => {
            return { 
                "id": option,
                "text": option
            }
		}, [])

		return result
    })

    // FUNCTIONS
    const checkReadyState = () => {

        if (selectedUtm.value && selectedUtm.value != "" && selectedInterface.value != null && selectedHostname.value != "" && transferNetwork.value.length > 0 && transferNetworkErrors.value.length == 0 && alias.value) {
            getterHelpers.useStore().getters.getActiveModal().buttons[1].loading = false
            getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = false
        }
        else {
            getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = true
        }
    }

    const addError = (e:any) => {
        responseErrors.value.push(e)
    }
    const clearErrors = () => {
        responseErrors.value = []
        formattedResponseErrors.value = []
    }

    const onSelectInterface = (e:any,a:any) => {
        console.log(e,a)
    }

    watch(responseErrors, (newErrors) => {
        responseErrors.value.forEach((error) => {
            if (Array.isArray(error?.data?.data?.error?.error) && error?.data?.data?.error.name.indexOf("TRANSFER_NETWORK") != -1) {
                error?.data?.data?.error?.error.forEach((errMsg:string) => {
                    formattedResponseErrors.value.push({
                        "property": "transferNetwork",
                        "error": errMsg
                    })
                })
            }

            if (Array.isArray(error?.data?.data?.errors)) {
                error.data.data.errors.forEach((err:any) => {
                    if (err.params?.missingProperty) {
                        formattedResponseErrors.value.push({
                            "property": err.params.missingProperty,
                            "error": err.message
                        })
                    }
                })
            }
        })

    }, {
        deep:true
    })

    // GET INTERFACES ON UTM CHANGE
    watch(selectedUtm,async () => {
        selectedUtmErrors.value = []
        if (props?.properties?.utmId) {

        }
        else if (initialized.value) {
            selectedInterface.value = null
            selectedHostname.value = ""
            transferNetwork.value = ""
        }
        if (selectedUtm.value) {
            loadingNodeInfo.value = true
            try {
                let result = await products.unifiedNetwork.utmNodes.queries.getObjectFromApi(activeAccountId.value,selectedUtm.value)
                if(Array.isArray((<Error|UtmNode[]>result))) {
                    // @ts-ignore
                    result = result[0]
                }
                if(!(result instanceof Error) && result.nkView != null) {
                    loadingNodeInfo.value = false
                }
                else if(!(result instanceof Error)) {
                    
                    products.unifiedNetwork.utmNodes.view.waitForNetworkViewViaWebsocket(activeAccountId.value,selectedUtm.value,() => {
                        loadingNodeInfo.value = false
                    })
                }
            }
            catch(e:any) {
                if(e?.data?.data && Array.isArray(e.data.data)) {
                    selectedUtmErrors.value = e.data.data
                }
                loadingNodeInfo.value = false
            }
        }
        checkReadyState()
    })
    const networkViewErrors = computed(() => {
        let thisErrors :string[] = []
        if(selectedNodeInfo.value) {
            if(selectedNodeInfo.value.nkView == null) {
                thisErrors.push("Für diese UTM existieren keine Netzwerkinformationen")
            }
            if((selectedNodeInfo.value.errors || []).length > 0) {
                (selectedNodeInfo.value.errors || []).forEach((error) => {
                    if(error == "UTM_RECENTLY_REMOVED") {
                        thisErrors.push("Die UTM wurde erst kürzlich aus der Konfiguration entfernt und kann erst wieder hinzugefügt werden, wenn sie sich nach Löschung der alten Konfigurationsdaten einmal zurückgemeldet hat. Bitte haben Sie einen Moment Geduld.")
                    }
                    else {
                        thisErrors.push(error)
                    }
                })
            }
        }
        return thisErrors
    })

    watch(selectedInterface,() => {
        if (initialized.value) {
            selectedHostname.value = ""
            transferNetwork.value = ""
        }
        checkReadyState()
    })
    watch(selectedHostname,() => {
        checkReadyState()
    })
    watch(port,() => {
        checkReadyState()
    })
    watch(alias,() => {
        checkReadyState()
    })
    watch(transferNetworkErrors,() => {
        checkReadyState()
    })
    watch(transferNetwork,(value,oldValue) => {
        checkReadyState()
        if(value.length >= 1) {
            hasIpChanged.value = true
        }
    })
    watch(interfaceOptions,() => {
        if (initialized.value) {
            if(interfaceOptions.value.length > 0) {
                selectedInterface.value = interfaceOptions.value[0].id as number
            }
        }
    })
    watch(hostnameOptions,() => {
        if (initialized.value) {
            const foundDyndns = hostnameOptions.value.some((host) => {
                const testAddress = new ipaddr(host.id as string)
                if (!testAddress.isIPv4() && !testAddress.isIPv6()) {
                    selectedHostname.value = host.id as string
                    return true
                }
            })
            if (!foundDyndns && hostnameOptions.value.length > 0) {
                selectedHostname.value = hostnameOptions.value[0].id as string
            }
        }
    })


    onMounted(async () => {
        await getterHelpers.useStore().dispatch(ActionTypes.getObjectInfos, {
            "accountId": activeAccountId.value,
            "objectTypes": ['ccutmStates']
        })
        // IF UTMID IS GIVEN, USE IT
        if (props?.properties?.alias) {
            alias.value = props.properties.alias
        }
        if (props?.properties?.utmId) {
            selectedUtm.value = props.properties.utmId
        }
        if (props?.properties?.port) {
            port.value = props.properties.port
        }
        if (props?.properties?.interface) {
            selectedInterface.value = Number(props.properties.interface)
        }
        if (props?.properties?.transferNetwork != undefined) {
            transferNetwork.value = props.properties.transferNetwork
        }
        if (props?.properties?.hostname) {
            selectedHostname.value = props.properties.hostname
        }
        checkReadyState()
        await timeHelpers.sleep(1000)
        initialized.value = true
    })

    // EXPOSE SELECTED VALUES
    defineExpose({ 
        selectedUtm, 
        port, 
        selectedInterface, 
        selectedHostname, 
        selectedInterfaceInfo,
        transferNetwork,
        alias,
        addError,
        clearErrors
    })
</script>
<template>
    <div class="content-2">
        <template v-if="initialized">

            <template v-if="formattedResponseErrors.some((err) => { return err.property !== 'transferNetwork' })">
                <p>
                    <template
                        v-for="err in formattedResponseErrors.filter((err) => { return err.property !== 'transferNetwork' }) ">
                        <span class="error-bubble label bg-red margin-xs-t"> {{ T(err.error) }}</span>
                    </template>
                </p>
            </template>

            <div class="row padding-xs-y form-group border-bottom">
                <div class="first col-xs-24 col-lg-6 col-xl-5">
                    <label class="control-label inputname" for="utmSelect">
                        {{ T('UTM') }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-8">
                    <input-vue-select 
                        id="utmSelect"
                        v-model="selectedUtm" 
                        :select-options="utmOptions"
                        :disabled="props.properties?.utmId"
                        :placeholder="T('Select an UTM')"
                    ></input-vue-select>
                </div>
                <div class="desc col-xs-24 col-lg-10">
                    <p class="input-description">
                        <template v-if="props.properties?.utmId">
                            {{ T('The UTM cannot be changed retrospectively.') }}
                        </template>
                        <template v-else>
                            {{ T('Currently only one UTM can be added.') }}
                        </template>
                    </p>
                </div>
            </div>
            <template v-if="selectedUtm && !loadingNodeInfo && canUseUtm">
                <div class="row padding-xs-y form-group border-bottom">
                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                        <label class="control-label inputname" for="alias">
                            {{ T('Alias') }}
                        </label>
                    </div>
                    <div class="input col-xs-24 col-lg-8">
                        <input-text id="alias" v-model="alias" :regex="/^[a-zA-Z0-9_-]{0,32}/gm" :placeholder="T('Alias')"></input-text>
                    </div>
                    <div class="desc col-xs-24 col-lg-10">
                        <p class="input-description">
                            <strong>{{ T('Pflichtfeld') }}.</strong> {{ T('Vergeben Sie einen Alias (wie z. B. "LG1"), um generierte Objekte auf der UTM leichter zuordnen zu können. (max 32 Zeichen (a-zA-Z0-9_-)).') }}
                        </p>
                    </div>
                </div>
                <div class="row padding-xs-y form-group border-bottom" v-if="props?.properties?.utmId">
                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                        <label class="control-label inputname" for="portSelect">
                            {{ T('Port') }}
                        </label>
                    </div>
                    <div class="input col-xs-24 col-lg-8">
                        <input-number id="portSelect" v-model="port" disabled :min="0" :max="65535" :placeholder="T('Enter port')"></input-number>
                    </div>
                    <div class="desc col-xs-24 col-lg-10">
                        <p class="input-description">
                            {{ T('The Port that is used by the VPN service.') }}
                        </p>
                    </div>
                </div>

                <div class="row padding-xs-y form-group border-bottom">
                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                        <label class="control-label inputname" for="interfaceSelect">
                            {{ T('Interface') }}
                        </label>
                    </div>
                    <div class="input col-xs-24 col-lg-8">

                        <input-vue-select 
                            id="interfaceSelect" 
                            v-model="selectedInterface" 
                            :select-options="interfaceOptions"
                            :disabled="!selectedUtm" 
                            :placeholder="T('Select an interface')"
                            :onSelecting="onSelectInterface"
                        ></input-vue-select>

                    </div>
                    <div class="desc col-xs-24 col-lg-10">
                        <p class="input-description">
                            {{ T('The interface that will be used to establish the connections.') }}
                        </p>
                    </div>
                </div>

                <div class="row padding-xs-y form-group border-bottom">
                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                        <label class="control-label inputname" for="hostnameSelect">
                            {{ T('Hostname/Public IP address') }}
                        </label>
                    </div>
                    <div class="input col-xs-24 col-lg-8">

                        <input-vue-select 
                            id="hostnameSelect" 
                            v-model="selectedHostname" 
                            :select-options="hostnameOptions"
                            :disabled="selectedInterface == null || Number.isNaN(selectedInterface) ? true : null" 
                            :placeholder="T('Please select')"
                        ></input-vue-select>

                    </div>
                    <div class="desc col-xs-24 col-lg-10">
                        <p class="input-description">
                            {{ T('The hostname that will be used to establish the connections.') }}
                        </p>
                    </div>
                </div>

                <div class="row padding-xs-y form-group border-bottom">
                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                        <label class="control-label inputname" for="utmSelect">
                            {{ T('Transfer network') }}
                        </label>
                    </div>
                    <div class="input col-xs-24 col-lg-8">

                        <input-text v-model="transferNetwork" :placeholder="T('Enter a private IP')" :disabled="selectedInterface == null || Number.isNaN(selectedInterface) ? true : null" ></input-text>
                        <template v-if="formattedResponseErrors.some((err) => { return err.property == 'transferNetwork'})">
                            <template
                                v-for="err in formattedResponseErrors.filter((err) => { return err.property == 'transferNetwork'})">
                                <span class="error-bubble label bg-red margin-xs-t"> {{ T(err.error) }}</span>
                            </template>
                        </template>
                        <template v-if="transferNetworkErrors.length">
                            <template v-for="err in transferNetworkErrors">
                                <span class="error-bubble label bg-red margin-xs-t"> {{ T(err) }}</span>
                            </template>
                        </template>
                    </div>
                    <div class="desc col-xs-24 col-lg-10">
                        <p class="input-description">
                            {{ T('IPv4 or IPv6 address to be used as the transfer network.') }}
                        </p>
                    </div>

                </div>

            </template>
            <template v-else-if="loadingNodeInfo">
                <div class="text-center padding-xs-y-8 col-xs">
                    <div class="text-size-3">
                        <loader class="text-size-2 color-red" />
                    </div>
                </div>
            </template>
            <template v-else-if="selectedUtm && !canUseUtm && networkViewErrors.length > 0">
                <p v-for="error in networkViewErrors">
                    <span class="notification bg-red color-white"> {{ T(error) }}</span>
                </p>
            </template>
            <template v-else-if="selectedUtm && !canUseUtm">
                <p>
                    <span class="error-bubble label bg-red margin-xs-t"> {{ T("Diese UTM kann nicht als Core-UTM verwendet werden.") }}</span>
                </p>
            </template>
        </template>
        <template v-else>
            <div class="text-center padding-xs-y-8 col-xs">
                <div class="text-size-3">
                    <loader class="text-size-2 color-red" />
                </div>
            </div>
        </template>
    </div>
</template>