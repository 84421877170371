import ObjectType, { type ObjectTypePayload } from "../../objectType"

export interface WindowsVpnState {
    "deviceId": string,
    "online": boolean,
    "lastContact": number,
    "mutipleConnects": boolean,
    "mutipleConnectsCount": number,
    "machineIds": MachineId[]
};

export interface MachineId {
    "machineId": string,
    "data": {
        "tenantDomain": string,
        "deviceId": string,
        "lastContact": number,
        "connectedSince": number,
        "ip": string,
        "server": string
    }
};

class WindowsVpnStates extends ObjectType<WindowsVpnState> {
    constructor(payload: ObjectTypePayload<WindowsVpnState>) {
        super(payload)
    }
}

const windowsVpnStates = new WindowsVpnStates({
    "productType": "mobileSecurity",
    "slug": "windowsVpnStates",
    "objectType": "windowsVpnStates",
    "hasStore": true,
    "objectTypeInfo": {
        "primaryKeyProperty": {
            "property": "deviceId",
            "pathToPrimaryProperty": undefined
        },
        "nameProperty": {
            "primary": "deviceId",
            "pathToPrimaryProperty": undefined,
            "secondary": undefined,
            "pathToSecondaryProperty": undefined
        }
    },
    "apiInfo": {
        "url": "/sms-mgt-api/api/2.0",
        "getCountGETProperties": "?props[]=null&select=count",
        // GET
        "getObjectListResponseProperty": "vpns",
        "getObjectListMethod": "GET",
        "getObjectListPath": "/tenants/{tenantDomain}/windows/devices/state",
    },
})

export default windowsVpnStates