import licenseHelpers from "@/helpers/helpers.license"
import ObjectType, { type ObjectTypePayload } from "../../objectType"
import dialogs from "@/dialogs/dialogs"
import { T } from "@/classes/i18n"
import Button from "@/classes/buttons"
import tenantHelpers from "@/helpers/helpers.tenants"
import numberHelpers from "@/helpers/helpers.numbers"
import utf8 from "utf8"

export interface Certificate {
  "tenantDomain": string,
  "certificateId": string,
  "name": string,
  "cn": string,
  "dn": string,
  "dnComponents": string[],
  "ca": boolean,
  "valid": boolean,
  "notBefore": number,
  "notAfter": number,
  "type": string,
  "hasPrivateKey": boolean
};


class Certificates extends ObjectType<Certificate> {
  constructor(payload: ObjectTypePayload<Certificate>) {
    super(payload)
    const thisObjectType = this
    this.itemlist.getSortingOptions = () => {
      return [
        {
          "id": "name",
          "text": T("Name")
        }
      ]
    }
    this.itemlist.getToolbarButtons = (accountId) => {
      let thisToolbarEntries = []
      if (licenseHelpers.hasOneOfLicenses(accountId, ['Mobile Security', 'MDM'], 'valid')) {
        thisToolbarEntries.push(
          {
            icon: 'fal fa-plus',
            title: T('Add certificate'),
            onclick: () => {
              dialogs.mobileSecurity.renderAddCertificateDialog(accountId)
            },
            id: 'certificatesButtonAdd',
            vIf: false
          }
        )
      }
      return thisToolbarEntries
    }

    this.itemlistItem.getMenuEntries = (accountId,item) => {
      let menuLinks = []
      if (licenseHelpers.hasOneOfLicenses(accountId, ['Mobile Security', 'MDM'], 'valid')) {
        menuLinks.push(
          new Button({
            title: T('Details'),
            text: T('Details'),
            onClick() { dialogs.mobileSecurity.renderEditCertificateDialog(tenantHelpers.getTenantDomain(accountId), item?.certificateId) },
            icon: 'fal fa-info-circle',
          })
        )
      }
      menuLinks.push(
        new Button({
          title: T('Delete'),
          text: T('Delete'),
          onClick() { 
            thisObjectType.dialogs.getDeleteObjectDialog(accountId,item)
          },
          icon: 'fal fa-trash',
        })
      )
      return menuLinks
    }
    this.itemlistItem.getDetails = (accountId,item) => {
      return [
        {
          iconClass: 'fal fa-fw fa-file',
          title: T('Type'),
          key: T('Type'),
          value: item ? item.type : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
        },
        {
          iconClass: 'fal fa-fw fa-info',
          title: T('CN'),
          key: T('CN'),
          value: item ? item.cn : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
        },
        {
          iconClass: 'fal fa-fw fa-info',
          title: T('DN'),
          key: T('DN'),
          value: item ? utf8.decode(item.dn) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
        }
      ]
    }
    this.itemlistItem.onClick = (accountId,item) => {
      if (licenseHelpers.hasOneOfLicenses(accountId, ['Mobile Security', 'MDM'], 'valid') && item?.certificateId) {
        dialogs.mobileSecurity.renderEditCertificateDialog(tenantHelpers.getTenantDomain(accountId), item?.certificateId)
      }
    }

  }

  


}

const certificates = new Certificates({
  "productType":"mobileSecurity",
  "slug":"certificates",
  "objectType":"certificates",
  "hasStore": true,
  "appearance": {
    "iconClass": "fal fa-certificate",
    "text": {
      "plural": "Certificates",
      "title": "Certificates",
      "sidebarName": "Certificates",
      "singular": "Certificate"
    },
    "color": "red",
    "showInSidebar": true,
    "showOnDashboard": true,
  },
  "objectTypeInfo": {
    "primaryKeyProperty": {
      "property": "certificateId",
      "pathToPrimaryProperty": undefined
    },
    "nameProperty": {
      "primary": "name",
      "pathToPrimaryProperty": undefined,
      "secondary": undefined,
      "pathToSecondaryProperty": undefined
    }
  },
  "apiInfo": {
    "url": "/sms-mgt-api/api/1.1",
    "getCountGETProperties": "?props[]=null&select=count",
    // GET
    "getObjectListResponseProperty": "certificates",
    "getObjectListMethod": "GET",
    "getObjectListPath": "/tenants/{tenantDomain}/certificates",
    // Update
    "updateObjectMethod": "PUT",
    "updateObjectPath": "/tenants/{tenantDomain}/certificates/{objectId}",
  },
})


export default certificates