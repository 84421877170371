<script setup lang="ts">
import { type Connection } from '@/classes/objectTypes/mobileSecurity/windows/windowsVpns';
import { computed, onUnmounted, ref } from 'vue';
import loader from '../components/loader.vue';
import { T } from "@/classes/i18n"
import { onMounted } from 'vue';
import requestHandler from '@/queries/requests';
import config from '@/classes/config';
import getterHelpers from '@/helpers/helpers.getters';
import tenantHelpers from '@/helpers/helpers.tenants';
import { MutationTypes, useStore } from '@/store/vuex.store';
import TableNext, { type TableEntryInfo } from '../components/tableNext.vue';

const props = defineProps<{
    properties: {
        connection: Connection,
        vpnId: string
    }
}>();


// Ref Values
const loading = ref(false)
//TODO Write Type for LogEntry
type LogEntry = {
    "isodate": unknown,
    "severity": unknown,
    "data": unknown
} | number
const logEntrys = ref(<LogEntry[]>[1, 2, 3])


// Computed Values
const activeAccountId = computed(() => { return getterHelpers.useStore().getters.getActiveAccountId })

const connectionLogOptions: TableEntryInfo[] = [
    {
        "text": T("Datum"),
        "property": "isodate",
        "displayType": "text",
        "getValue": (entry: LogEntry) => {
            return "Hello World"
        },
        "width": 130
    },
    {
        "property": "severity",
        "width": 90,
        "text": T("Schweregrad"),
        "displayType": "status",
        "getSortValue": (entry: LogEntry) => {
            return Math.random() * 10
        },
        "getValue": (entry: LogEntry) => {
            let color: "red" | "green" | "yellow" = "green"
            
            if(entry == 1) color = "red";
            if(entry == 2) color = "yellow";
            if(entry == 3) color = "green";

            let tooltipText = "SomeToolTipText"
            return {
                "color": color,
                "tooltip": tooltipText,
            }
        }
    },
    {
        "text": T("Nachricht"),
        "property": "data",
        "displayType": "text",
        "getValue": (entry: LogEntry) => {
            return "Hello World"
        }
    }
]


onMounted(async () => {
    loading.value = true
    console.log(props.properties.connection)
    try {
        const response = await requestHandler.request("POST", config.mgtApiUriNext + "/tenants/" + tenantHelpers.getTenantDomain(activeAccountId.value) + "/windows/devices/" + props.properties.vpnId + "/jobs/vpn", {
            "name": "connection-logs",
            "data": {
                "connectionId": props.properties.connection.id
            }
        })

        useStore().commit(MutationTypes.addSubscriptionHook, {
            "accountId": activeAccountId.value,
            "hookKey": "vpnConnectionLog",
            "hookFunction": (message: any) => {
                const messageTopic = message?.topic || message?.data?.topic || ""
                const clientContext = message?.data?.clientContext || ""
                if(typeof messageTopic == "string" && messageTopic.includes("/windows/vpn/") && clientContext) {
                    console.log(messageTopic)
                    console.log(clientContext)
                    // loading.value = false
                }
            }
        })
    }
    catch (e: unknown) {
        console.error(e)
    }
    setTimeout(() => {
            loading.value = false
        }, 2000)

})

onUnmounted(() => {
    useStore().commit(MutationTypes.deleteSubscriptionHook, {
        "accountId": activeAccountId.value,
        "hookKey": "vpnConnectionLog",
    })
})

</script>

<template>
    <template v-if="loading == true">
        <div class="text-size-3 text-center padding-xs-t-4">
            <div class="text-size-2">
                <loader class="color-red"></loader>
            </div>
        </div>
        <div class="text-center padding-xs-t-2" style="opacity:0.8">
            <span>
                {{ T("Hole Logeinträge...") }}
            </span>
        </div>
    </template>
    <template v-else>
        {{ T("Das Verbindungs-Log des VPN-Clients kann nach den Schweregraden gefiltert oder sortiert werden. Die Schweregrade sind:") }}
        <ul>
            <li> {{ T("Information(Info)") }} </li>
            <li> {{ T("Warnungen(Warning)") }} </li>
            <li> {{ T("Fehler(Error)") }} </li>
        </ul>
        <TableNext :selectableColumns="connectionLogOptions" :objectList="logEntrys">

        </TableNext>
    </template>
</template>