<script setup lang="ts">
    import getterHelpers from "@/helpers/helpers.getters";
    import loader from "../components/loader.vue";
    import { T } from "@/classes/i18n";
    import { computed, onMounted, ref, watch } from "vue";
    import inputVueSelect from "../inputtypes/input-vue-select.vue";
    import objectStores from "@/classes/init";
    import deviceHelpers from "@/helpers/helpers.devices";
    import inputText from "../inputtypes/input-text.vue";
    import validationHelpers from "@/helpers/helpers.validation";
    import ipaddr from "@/lib/ipaddr";
    import licenseHelpers from "@/helpers/helpers.license";
    import products from "@/classes/objectTypes";
    import { ActionTypes } from "@/store/vuex.store";
    import { type UtmNode } from "@/classes/objectTypes/unifiedNetwork/nodes";
import tenantHelpers from "@/helpers/helpers.tenants";

    const props = defineProps<{
        properties: {
            coreUtmId:string,
            utmId:string,
            existingSatellites:string[],
            type:undefined|"satellite"|"addressPool"
            addressPoolName:string
            transferNetwork:undefined|string
            alias:string,
            profiles:{"id":string,"type":string}[]
        }
    }>();

    const errors = ref(<string[]>[])

    const activeAccountId = computed(() => {
        return getterHelpers.useStore()?.getters.getActiveAccountId
    })

    const transferNetworkErrors = computed(() => {
        let thisErrors : string[] = []
        if(hasIpChanged.value == true ) {
            if (transferNetwork.value.length >= 1) {
                let address : string | ipaddr = transferNetwork.value
                try {
                    address = new ipaddr(transferNetwork.value)
                }
                catch(e) {
                    
                }
                if (typeof address != "string" && (address.isIPv4() || address.isIPv6())) {
                    if(address.hasCidr()) {
                        if (address.isIPv4()) {
                            let cidr = Number(address.cidr) as NumericRange<CreateArrayWithLengthX<16>,30>

                            if (!validationHelpers.isPrivateIPv4(address.addr, false)) {
                                thisErrors.push("Enter a private IP address.")
                            }
                            if (Number(address.cidr) > 30 || Number(address.cidr) < 16) {
                                thisErrors.push("Enter a network mask between /16 and /30.")
                            }
                            else {
                                const baseCheckResult = validationHelpers.isCorrectIpV4Base(address.addr,cidr)
                                if(baseCheckResult !== true) {
                                    thisErrors.push(baseCheckResult.message)
                                }
                            }
                        }

                        if (address.isIPv6()) {
                            let cidr = Number(address.cidr) as NumericRange<CreateArrayWithLengthX<112>,126>
                            if (!validationHelpers.isPrivateIpV6(address.addr, false)) {
                                thisErrors.push("Enter a private IP address.")
                            }
                            if (Number(address.cidr) > 126 || Number(address.cidr) < 112) {
                                thisErrors.push("Enter a network mask between /112 and /126.")
                            }
                            else {
                                const baseCheckResult = validationHelpers.isCorrectIpV6Base(address.addr,cidr)
                                if(baseCheckResult !== true) {
                                    thisErrors.push(baseCheckResult.message)
                                }
                            }
                        }

                    }
                    else {
                        thisErrors.push("Missing network mask.")
                    }
                }
                else {
                    thisErrors.push("The specified address does not match an ipv4 or ipv6 address.")
                }
            }
            else {
                thisErrors.push("Enter a private IP address.")
            }
        }
        return thisErrors
    })


    const networkViewErrors = computed(() => {
        let thisErrors :string[] = []
        if(selectedNodeInfo.value) {
            if(selectedNodeInfo.value.nkView == null) {
                thisErrors.push("Für diese UTM existieren keine Netzwerkinformationen")
            }
            if((selectedNodeInfo.value.errors || []).length > 0) {
                (selectedNodeInfo.value.errors || []).forEach((error) => {
                    if(error == "UTM_RECENTLY_REMOVED") {
                        thisErrors.push("Die UTM wurde erst kürzlich aus der Konfiguration entfernt und kann erst wieder hinzugefügt werden, wenn sie sich nach Löschung der alten Konfigurationsdaten einmal zurückgemeldet hat. Bitte haben Sie einen Moment Geduld.")
                    }
                    else {
                        thisErrors.push(error)
                    }
                })
            }
        }
        return thisErrors
    })

    const profileOptions = computed(() => {
        const androidProfiles = products.mobileSecurity.androidProfiles.useStore?.().getObjectStoreObjects(activeAccountId.value)
        const iosProfiles = products.mobileSecurity.iosProfiles.useStore?.().getObjectStoreObjects(activeAccountId.value)
        const options : selectOption[] = []

        androidProfiles?.forEach((androidProfile) => {
            options.push({
                "id":androidProfile.name,
                "text":androidProfile.profileId,
                "icon":"fab fa-android",
                "groupId": '#android'
            })
        })
        iosProfiles?.forEach((iosProfile) => {
            options.push({
                "id":iosProfile.profileId || "",
                "text":iosProfile.profilename || "",
                "icon":"fab fa-apple",
                "groupId": '#apple'
            })
        })

        options.push({
            "type": 'groupName',
            id: '#android',
            text: T('Android Profile'),
            disabled: true
        })
        options.push({
            "type": 'groupName',
            id: '#apple',
            text: T('Apple Profile'),
            disabled: true
        })

        return options
    })

    const utmOptions = computed(() => {
        let result = <selectOption[]>[]
        const utms = objectStores.uscUtms.getObjectsFromStore(activeAccountId.value)
        
        result = utms.map((utm) => {
            const thisUtmState = getterHelpers.useStore()?.getters.getObject({
                "accountId": activeAccountId.value,
                "productType": "unifiedSecurityConsole",
                "objectType": "ccutmStates",        
                "objectId": utm.utmId,
            })

            let utmVersion = objectStores.uscUtms.getCurrentUtmVersion(utm)
            const hasVersion = validationHelpers.versionCompare(utmVersion,"12.8.0") === 0 || validationHelpers.versionCompare(utmVersion,"12.8.0") === 1

            return <selectOption>{
                "id":utm.utmId,
                "text": utm?.utmname + " (" + deviceHelpers.getShortDeviceId(utm.utmId) + ")" + (thisUtmState.online == false ? " | " + T("Offline") : (!hasVersion ? " | " + T("Version nicht kompatibel") : (utm.permissions?.sunEnabled !== true ? " | " + T("Keine Berechtigung") : ""))),
                "disabled":thisUtmState.online === false || utm.permissions?.sunEnabled !== true || !hasVersion,
                "groupId": (thisUtmState.online === false || utm.permissions?.sunEnabled !== true || !hasVersion) ? '#unavailableUtmsHeader' : '#availableUtmsHeader'
            }
        }).filter((option) => {
            return option.id !== props.properties.coreUtmId && (props.properties.existingSatellites || []).indexOf(String(option.id)) == -1
        })
        result.push({
            "type": 'groupName',
            id: '#availableUtmsHeader',
            text: T('Verfügbar'),
            disabled:true
        })
        result.push({
            "type": 'groupName',
            id: '#unavailableUtmsHeader',
            text: T('Nicht verfügbar'),
            disabled:true
        })

        
        return result
    })

    const typeOptions : selectOption[] = [
        {
            "id":"satellite",
            "text":"UTM"
        }
    ]

    const initialized = ref(false)
    const selectedUtm = ref("")
    const selectedType = ref("satellite")
    const addressPoolName = ref("")
    const transferNetwork = ref("")
    const alias = ref("")
    const selectedProfiles = ref(<string[]>[])

    const hasIpChanged = ref(false)

    const loadingNodeInfo = ref(false)
    const selectedNodeInfo = computed(() => {
        return products.unifiedNetwork.utmNodes.useStore?.().getObjectStoreObject(activeAccountId.value,selectedUtm.value || "")
    })
    const canUseUtm = computed(() => {
        return selectedNodeInfo.value != undefined && selectedNodeInfo.value.nkView && networkViewErrors.value.length == 0
    })

    // FUNCTIONS
    const checkReadyState = () => {
        if (initialized.value == true && ((selectedType.value == 'satellite' && selectedUtm.value && canUseUtm.value && alias.value) || (selectedType.value == 'addressPool' && addressPoolName.value && transferNetwork.value && transferNetworkErrors.value.length == 0))) {
            getterHelpers.useStore().getters.getActiveModal().buttons[1].loading = false
            getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = false
        }
        else {
            getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = true
        }
    }

    // WATCHERS
    watch(selectedType,() => {
        checkReadyState()
    })

    watch(utmOptions,() => {
        checkReadyState()
    })
    watch(canUseUtm,() => {
        checkReadyState()
    })

    watch(selectedUtm,async () => {
        checkReadyState()
        clearErrors()
        if(selectedUtm.value) {
            loadingNodeInfo.value = true
            try {
                let result = await products.unifiedNetwork.utmNodes.queries.getObjectsFromApi(activeAccountId.value,undefined,[{ "property":"ids[]","value":selectedUtm.value}])
                
                if(Array.isArray(result)) {
                    // @ts-ignore
                    result = result[0] as UtmNode
                }
                //@ts-ignore
                if(!(result instanceof Error) && (result).nkView != null) {
                    loadingNodeInfo.value = false
                }
                else if(!(result instanceof Error)){
                    products.unifiedNetwork.utmNodes.view.waitForNetworkViewViaWebsocket(activeAccountId.value,selectedUtm.value,() => {
                        loadingNodeInfo.value = false
                    })
                }
            }
            catch(e) {
             
                loadingNodeInfo.value = false
            }
        }

    })
   
    watch(transferNetwork,(value,oldValue) => {
        checkReadyState()
        if(value.length >= 1) {
            hasIpChanged.value = true
        }
    })
    watch(networkViewErrors,(value,oldValue) => {
        checkReadyState()
    })
    watch(alias,(value,oldValue) => {
        checkReadyState()
    })
    watch(loadingNodeInfo,() => {
        getterHelpers.useStore().getters.getActiveModal().buttons[1].loading = loadingNodeInfo.value
    })

    // SETTERS
    const setErrors = (newErrors:string[]) => {
        errors.value = newErrors
    }

    const clearErrors = () => {
        errors.value = []
    }

    

    // ON MOUNTED
    onMounted(async () => {
        await getterHelpers.useStore().dispatch(ActionTypes.getObjectInfos, {
            "accountId": activeAccountId.value,
            "objectTypes": ['ccutmStates']
        })
        if(licenseHelpers.hasOneOfLicenses(activeAccountId.value,['MDM','Mobile Security'])) {
            typeOptions.push({
                "id":"addressPool",
                "text":"Adresspool",
            })
            
            let androidProfiles = products.mobileSecurity.androidProfiles.useStore?.().getObjectStoreObjects(activeAccountId.value)
            let iosProfiles = products.mobileSecurity.iosProfiles.useStore?.().getObjectStoreObjects(activeAccountId.value)

            if(androidProfiles?.length == 0) {
                if (tenantHelpers.hasFunctionality(activeAccountId.value,"enterprise")) {
                    await products.mobileSecurity.androidProfiles.queries.getObjectsFromApi(activeAccountId.value)
                }
            }
            if(iosProfiles?.length == 0) {
                await products.mobileSecurity.iosProfiles.queries.getObjectsFromApi(activeAccountId.value)
            }
        }

        if(props.properties.alias) {
            alias.value = props.properties.alias
        }

        // ADDRESSPOOL 
        if(props.properties.type == "addressPool") {
            selectedType.value = "addressPool"
            if (props.properties.addressPoolName) {
                addressPoolName.value = props.properties.addressPoolName
            }
            if (props.properties.transferNetwork) {
                transferNetwork.value = props.properties.transferNetwork
            }
            if (props.properties.profiles) {
                selectedProfiles.value = props.properties.profiles.map((profile) => {
                    return profile.id
                })
            }
            initialized.value = true
        }
        else {
            // IF UTMID IS NOT GIVEN, USE FIRST IN LIST
            if (!props?.properties?.utmId && utmOptions.value?.length && selectedUtm.value == "") {      
                initialized.value = true
            }
            // IF UTMID IS GIVEN, USE IT
            else if (props?.properties?.utmId) {
                selectedUtm.value = props.properties.utmId
                initialized.value = true
            }
        }
        checkReadyState()
    })

    // EXPOSE SELECTED VALUES
    defineExpose({ 
        selectedUtm, 
        selectedType,
        addressPoolName,
        transferNetwork,
        selectedProfiles,
        alias,
        setErrors, 
        clearErrors 
    })
</script>
<template>
    <div class="content-2">
        <template v-if="initialized">

            <div class="row padding-xs-y form-group border-bottom" v-if="typeOptions.length > 1">
                <div class="first col-xs-24 col-lg-6 col-xl-5">
                    <label class="control-label inputname" for="typeSelect">
                        {{ T('Type') }}
                    </label>
                </div>  
                <div class="input col-xs-24 col-lg-10">
                    <input-vue-select 
                        id="typeSelect" 
                        v-model="selectedType" 
                        :select-options="typeOptions"
                        :disabled="props.properties?.type" 
                        :placeholder="T('Select a type')"
                    ></input-vue-select>
                </div>
                <div class="input-description col-lg-8 col-xl-9">
                    
                        {{ T('Wählen Sie aus ob Sie eine UTM oder einen Adresspool hinzufügen möchten') }}
                    
                </div>
            </div>

            <template v-if="selectedType == 'satellite'">
                <template v-if="utmOptions.some((option) => { return option.disabled !== true })">
                    
                    <div class="row padding-xs-t form-group">
                        <div class="first col-xs-24 col-lg-6 col-xl-5">
                            <label class="control-label inputname" for="utmSelect">
                                {{ T('UTM') }}
                            </label>
                        </div>  
                        <div class="input col-xs-24 col-lg-10">
                            <input-vue-select 
                                id="utmSelect" 
                                v-model="selectedUtm" 
                                :select-options="utmOptions"
                                :disabled="props.properties?.utmId" 
                                :placeholder="T('Select an UTM')"
                            ></input-vue-select>
                        </div>
                        <div class="input-description col-xs-24 col-lg-8 col-xl-9">
                                {{ T('Select an UTM that will connect to the Core-UTM via VPN.') }}<br>
                                {{ T('The changes will be assigned to the UTMs as soon as the settings are published.') }}
                        </div>
                    </div>

                    <div class="row padding-xs-y form-group border-bottom">
                        <div class="first col-xs-24 col-lg-6 col-xl-5">
                            <label class="control-label inputname" for="alias">
                                {{ T('Alias') }}
                            </label>
                        </div>
                        <div class="input col-xs-24 col-lg-10">
                            <input-text id="alias" v-model="alias" :regex="/^[a-zA-Z0-9_-]{0,32}/gm" :placeholder="T('Alias')"></input-text>
                        </div>
                        <div class="desc col-xs-24 col-lg-8">
                            <p class="input-description">
                                <strong>{{ T('Pflichtfeld') }}.</strong> {{ T('Vergeben Sie einen Alias (wie z. B. "LG1"), um generierte Objekte auf der UTM leichter zuordnen zu können. (max 32 Zeichen (a-zA-Z0-9_-)).') }}
                            </p>
                        </div>
                    </div>

                </template>
                <template v-else>
                    <p class="notification bg-red color-white">
                        <i class="color-white fal fa-exclamation-triangle"></i>&nbsp;&nbsp;{{ T('Missing an UTM that can used as a satellite. Please make sure that your UTMs are up-to-date and connected to the Unified Security Console in order to configure them.') }}
                    </p>
                </template>
            </template>
            <template v-else-if="selectedType == 'addressPool'">
                

                    <div class="row padding-xs-y form-group border-bottom">
                        <div class="first col-xs-24 col-lg-6 col-xl-5">
                            <label class="control-label inputname" for="utmSelect">
                                {{ T('Name') }}
                            </label>
                        </div>  
                        <div class="input col-xs-24 col-lg-10">
                            <input-text v-model="addressPoolName" :placeholder="T('Geben Sie einen Namen ein')"></input-text>
                        </div>
                        <div class="input-description col-lg-8 col-xl-9">
                            
                                {{ T('Der Name der für diesen Adresspool verwendet wird') }}
                            
                        </div>
                    </div>

                    <div class="row padding-xs-y form-group border-bottom">
                        <div class="first col-xs-24 col-lg-6 col-xl-5">
                            <label class="control-label inputname" for="transfernetwork">
                                {{ T('Transfer network') }}
                            </label>
                        </div>
                        <div class="input col-xs-24 col-lg-10">
                            <input-text 
                                id="transferNetwork"
                                v-model="transferNetwork" 
                                :placeholder="T('Geben Sie eine private IP-Adresse ein')"
                            ></input-text>
                            <template v-if="transferNetworkErrors.length">
                                <template v-for="err in transferNetworkErrors">
                                    <span class="error-bubble label bg-red margin-xs-t"> {{ T(err) }}</span>
                                </template>
                            </template>
                        </div>
                        <div class="input-description col-lg-8 col-xl-9">
                            
                                {{ T('IPv4 or IPv6 address to be used as the transfer network.') }}
                            
                        </div>
                    </div>

                    <div class="row padding-xs-y form-group border-bottom">
                        <div class="first col-xs-24 col-lg-6 col-xl-5">
                            <label class="control-label inputname" for="profileSelect">
                                {{ T('Profile') }}
                            </label>
                        </div>  
                        <div class="input col-xs-24 col-lg-10">
                            <input-vue-select 
                                id="profileSelect" 
                                v-model="selectedProfiles" 
                                :select-options="profileOptions"
                                :placeholder="T('Profile auswählen')"
                                :multiple="true"
                            ></input-vue-select>
                        </div>
                        <div class="input-description col-xs-24 col-lg-8 col-xl-9">
                            {{ T('Geräte aus diesen Profilen können sich nach Veröffentlichung der Konfiguration mit diesem Netzwerk verbinden.') }}
                        </div>
                    </div>
            </template>

            <template v-if="selectedUtm && !canUseUtm && !loadingNodeInfo && networkViewErrors.length > 0">
                <p v-for="error in networkViewErrors" class="notification bg-red color-white">
                     {{ T(error) }}
                </p>
            </template>

            <template v-for="error in errors">
                <p class="notification bg-red color-white">
                    {{ T(error) }}
                </p>
            </template>

        </template>
        <template v-else>
            <div class="text-center padding-xs-y-8 col-xs">
                <div class="text-size-3">
                    <loader class="text-size-2 color-red" />
                </div>
            </div>
        </template>
    </div>
</template>