import { useStore } from '@/store/vuex.store'
import devLog from "../classes/log"
import jsonHelpers from "./helpers.json"
import tenantHelpers from "./helpers.tenants"
import validationHelpers from "./helpers.validation"
import moment from "moment"
import { useVue } from "@/app"
import type { UscProfile } from "@/classes/unifiedSecurityConsole/uscProfiles"
import config from '@/classes/config'
import licenseHelpers from './helpers.license'
import products from '@/classes/objectTypes'

const jsonFormHelpers = {
	getFieldById: function(form : JsonFormShemaObject, id: string) {
		return jsonHelpers.jsonSearchPair(form, "id", id,["options"]) as JsonFormShemaObject
	},
	getFieldByProperty: function(form: JsonFormShemaObject, property: string) {
		return jsonHelpers.jsonSearchPair(form, "property", property, ["options"])
	},
	areConditionsFulfilled: function (component: any, conditions: JsonFormCondition[], field?: any, isErrorConditions: boolean = false) {
		let areConditionsFulfilled : boolean = true
		if (conditions && conditions.length > 0) {
			for (let conditionIndex in conditions) {
				let condition = conditions[conditionIndex]
				let inputObj : any = condition.refId ? (jsonFormHelpers.getFieldById(component.form||component.fullJson,condition.refId) || undefined) : field || undefined
				if(condition.hasManagedConfiguration === undefined && condition.root === undefined && inputObj) {
					let hasMdmDevices : boolean = true
					switch(condition.operator) {
						case 'equals':
							if (inputObj.value !=  condition.value) {
								areConditionsFulfilled = false
							}
						break;
						case 'equals_not':
							if (inputObj.value == condition.value) {
								areConditionsFulfilled = false
							}
						break;
						case 'is_one_of':
							if (condition.value.indexOf(inputObj.value) == -1) {
								areConditionsFulfilled = false
							}
						break;
						case 'has_one_of':
							let hasOneOf = condition.value.some((conditionValue:string|number) => {
								if ((String(inputObj.value) || []).indexOf(conditionValue.toString() as never) != -1) {
									return true
								}	
							})
							if(!hasOneOf) {
								areConditionsFulfilled = false
							}
						break;
						case 'no_fields':
							if ((inputObj.fields || []).length > 0) {
								areConditionsFulfilled = false
							}
						break;
						case 'minLength':
							if (!validationHelpers.checkLength(inputObj.value,condition.value)) {
								areConditionsFulfilled = false
							}
						break;
						case 'maxLength':
							if (!validationHelpers.checkLength(inputObj.value,undefined,condition.value)) {
								areConditionsFulfilled = false
							}
						break;
						case 'date_is_after':
							if (inputObj.value && field.value) {
								let firstDate = moment(inputObj.value,"DD.MM.YYYY")
								let thisDate = moment(field.value, "DD.MM.YYYY")
								if(firstDate.isBefore(thisDate)) {

								}
								else {
									areConditionsFulfilled = false
								}
							}

						break;
						case 'has_mdm_device':
							hasMdmDevices = (inputObj.value.some((deviceId: string) => {
								let device: any = config.canUseNewObjectType("iosDevices") ? products.mobileSecurity.iosDevices.useStore?.().getObjectStoreObject(component.activeAccountId,deviceId) : useStore()?.getters.getObject({
									"accountId": component.activeAccountId,
									"productType": "mobileSecurity",
									"objectType": "devices",
									"objectId": deviceId,
									"property": "deviceId"
								})
								return device?.licenseInformation?.type == "MDM"
							}) || false)
							
							if(!hasMdmDevices) {
								areConditionsFulfilled = false
							}
						break;
						case '!has_mdm_device':
							hasMdmDevices = (inputObj.value.some((deviceId: string) => {
								let device: any = config.canUseNewObjectType("iosDevices") ? products.mobileSecurity.iosDevices.useStore?.().getObjectStoreObject(component.activeAccountId,deviceId) : useStore()?.getters.getObject({
									"accountId": component.activeAccountId,
									"productType": "mobileSecurity",
									"objectType": "devices",
									"objectId":deviceId,
									"property":"deviceId"
								})
								return device?.licenseInformation?.type == "MDM"
							}) || false)

							if (hasMdmDevices) {
								areConditionsFulfilled = false
							}
						break;
						case 'isGeneratedUscProfile':
							if (useVue().$refs?.editpage?.rootObject != undefined) {
								let object: UscProfile = useVue().$refs?.editpage?.rootObject
								if (object?.template?.tenantDomains == undefined && object?.template?.uuid != undefined && object?.template?.childProfiles == undefined) {
									areConditionsFulfilled = false
								}
							}
						break;
						case 'uscProfileTagsErrorCheck':
							let accountID = useStore().state.session.activeAccountId
							if (accountID) {
								if ([null, undefined].indexOf(useStore().state.session.accounts[accountID].unifiedSecurity.account.parent_accountid) != -1) {
									let isTemplateField = this.getFieldById(useVue().$refs?.editpage?.form, "_use_template")
									if (isTemplateField.value == true) {
										let tagsField = this.getFieldById(useVue().$refs?.editpage?.form, "tags")

										if (tagsField.value.length == 0) {
											areConditionsFulfilled = false
											if (isErrorConditions && useVue().$refs?.editpage) {
												useVue().$refs.editpage.refreshCounter++
											}

										}
									}
								}
							}
						break;
						case 'uniqueApiKeyName':
							if (useVue().context != 'edit') {
								const accountId = useStore().state.session.activeAccountId
								const apiKeys = (config.canUseNewObjectType("apiKeys") ? products.unifiedSecurity.apiKeys.useStore?.().getObjectStoreObjects(accountId || "") : useStore().state.session.accounts[accountId || ""].unifiedSecurity.apiKeys.items) || []
								const isNameTaken = apiKeys.some((apiKey) => {
									return apiKey.name == field.value
								})
								if(isNameTaken) {
									areConditionsFulfilled = false
								}
							}
						break;
						case 'is_Ipv4Cidr_Ipv6_Fqdn':
							const fieldValue: Array<string> | string | undefined = field.value
							if (Array.isArray(fieldValue) && fieldValue.length) {
								areConditionsFulfilled = !fieldValue.some((value: string) => {
									return validationHelpers.isFQDN(value) == false && validationHelpers.isIpv4Cidr(value) == false && validationHelpers.isIpV6(value) == false
								})
							}
							else if (typeof fieldValue == "string") {
								areConditionsFulfilled = validationHelpers.isFQDN(fieldValue) || validationHelpers.isIpv4Cidr(fieldValue) || validationHelpers.isIpV6(fieldValue)
							}
						break;
						case 'matches_regex':
							areConditionsFulfilled = inputObj.value.match(new RegExp(condition.value))
						break
					}
				}

				if (condition.hasManagedConfiguration === true) {
					areConditionsFulfilled = false
					inputObj = jsonFormHelpers.getFieldById(component.form || component.fullJson,"emm_applications")
					if (inputObj == undefined) {
						devLog.log('[formpage]','Input was not found', condition)
						break;
					}
					if(inputObj.fields.length) {
						for(let i in inputObj.fields) {
							let thisField : any = inputObj.fields[i]
							if(jsonHelpers.getObjectProperty(thisField.fields[6],"value") != undefined) {
								areConditionsFulfilled = true
								break;
							}
						}
					}
				}

				if(condition.root) {
					switch (condition.root) {
						case 'context':
							if (condition.operator == 'equals' && component.$root.context != condition.value) {
								areConditionsFulfilled = false
							}
							else if (condition.operator == 'equals_not' && component.$root.context == condition.value) {
								areConditionsFulfilled = false
							}
						break;
						case 'hasLicense':
							if (!licenseHelpers.hasLicense(component.$root.activeAccountId, condition.value)) {
								areConditionsFulfilled = false
							}      
						break;
						case '!hasLicense':
							if (licenseHelpers.hasLicense(component.$root.activeAccountId, condition.value)) {
								areConditionsFulfilled = false
							}      
						break;
						case 'hasValidLicense':
							if (!licenseHelpers.hasLicense(component.$root.activeAccountId, condition.value,'valid')) {
								areConditionsFulfilled = false
							}      
						break;
						case '!hasValidLicense':
							if (licenseHelpers.hasLicense(component.$root.activeAccountId, condition.value,'valid')) {
								areConditionsFulfilled = false
							}      
						break;
						case 'isDev':
							if (config.devMode != condition.value) {
								areConditionsFulfilled = false
							}      
						break;
						case 'canUseEnterprise':
							if (!tenantHelpers.hasFunctionality(useStore()?.state.session.activeAccountId || "",'enterprise')) {
								areConditionsFulfilled = false
							}      
						break;
						case 'isReseller':
							if (useStore()?.state.session.activeAccountId) {
								if([null,undefined].indexOf(useStore()?.state.session.accounts[useStore()?.state.session.activeAccountId || ""].unifiedSecurity.account.parent_accountid) == -1) {
									areConditionsFulfilled = false
								}
							}
						break;
						case 'isTenantOfOneOfResellers':
							if (condition.value.length) {
								if (useStore()?.state.session.activeAccountId) {
									if(condition.value.indexOf(String(useStore()?.state.session.accounts[useStore()?.state.session.activeAccountId || ""].unifiedSecurity.account.parent_accountid)) == -1) {
										areConditionsFulfilled = false
									}
								}
							}
						break;
					}
				}

				if (condition.type == "hasFunctionality") {
					let activeAccountId = useStore()?.state.session.activeAccountId
					if (!activeAccountId) {
						throw "Missing accountId"
					}
					areConditionsFulfilled = areConditionsFulfilled && condition.functionality ? tenantHelpers.hasFunctionality(activeAccountId,condition.functionality) : false
				}
			}
		}
		return areConditionsFulfilled
	}
}

export default jsonFormHelpers