<script setup lang="ts">
    import { T } from '@/classes/i18n';
    import getterHelpers from '@/helpers/helpers.getters';
    import { computed } from 'vue';
    const activeAccountId = computed(() => {
        return getterHelpers.useStore()?.getters.getActiveAccountId
    })
    const props = defineProps<{
        sunInfo?: any[]|undefined,
    }>();
</script>
<template>
    <p>{{ T('Dieses Profil ist mit folgenden Core-UTMs verknüpft:') }}</p>
    <hr>
    <template v-if="(props.sunInfo || []).length > 0">
        <template v-for="info in sunInfo">
            
            <h5>{{ info.coreUtmName }}</h5>
            <div class="row margin-xs-b">
                <div class="col-xs-24 col-lg-3">
                    <strong>{{ T('Adresspools') }}:</strong>
                </div>
                <div class="col-xs-24 col-lg-21">
                    <template v-for="pool in info.pools">
                        <a :href="'#show-tenant-'+activeAccountId+'.sms-unified-network-highlight-'+pool.id">{{ pool.name }}</a> | TN: {{ pool.transferNetwork }} | {{ T("IPs in Benutzung") }}: {{ (pool.numberOfDevices || 0) + "/" + pool.transferNetworkCapacity }} <br>
                    </template>
                </div>
            </div>
            <hr>
        </template>
    </template>
</template>