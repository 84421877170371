import Icon, { type IconOptions } from "./icon"

export interface LabelOptions {
    id?:string
    title?:string,
    htmlTooltip?:boolean,
    text?:string,
    class?:string,
    onClick?:(event:MouseEvent) => any,
    stopPropagation?:boolean
    icon?:Icon|IconOptions|string,
    icons?:Array<Icon>
    content?:({ type:"icon", value:Icon|IconOptions|string } | { type:"text", value:string|number })[]
    float?:"left" | "right"
    displayType?:"label",
    contentSeparator?: string
}
export default class Label implements LabelOptions {
    id : LabelOptions["id"] = undefined
    title : LabelOptions["title"] = undefined
    htmlTooltip : LabelOptions["htmlTooltip"] = false
    text : LabelOptions["text"] = undefined
    class : LabelOptions["class"] = undefined
    onClick : LabelOptions["onClick"] = undefined
    stopPropagation : LabelOptions["stopPropagation"] = undefined
    icon : LabelOptions["icon"] = undefined
    icons : LabelOptions["icons"] = []
    float : LabelOptions["float"] = undefined
    displayType : LabelOptions["displayType"] = undefined
    content : LabelOptions["content"] = undefined
    contentSeparator: LabelOptions["contentSeparator"] = undefined
    constructor(options?:LabelOptions) {
        if(options) {
            Object.assign(this,options)
            if(options.onClick != undefined && options.stopPropagation == undefined) {
                this.stopPropagation = true
            }
        }
    }
}