<script setup lang="ts">
  import Button, { type ButtonOptions } from '@/classes/buttons';
  import { computed, onMounted, ref } from 'vue';
  import loaderComponent from "./loader.vue"
  import Tooltip from './tooltip.vue';
  //@ts-ignore
  import DropdownMenu from 'v-dropdown-menu'
  import iconComponent from "@/templates/components/icon.vue"
  

  export interface ButtonComponentButtonOptions {
    "buttonOptions":ButtonOptions|Button
  }
  defineOptions({
    inheritAttrs: false
  })
  const props = withDefaults(defineProps<ButtonComponentButtonOptions>(), {
    "buttonOptions":() => { return {
      "disabled": false,
      "icon": "",
      "link": undefined,
      "loading": false,
      "onClick": undefined,
      "target": "_blank",
      "text": "Button",
      "title": "",
      "htmlTooltip": undefined,
      "topRightCircle": undefined,
      "type":"default",
      "size":undefined,
      "dropdownEntries":undefined,
      "color":undefined
    }},
    "updater":0
  });

  const isDisabled = computed(() => {
    props.updater;
    return (typeof props.buttonOptions.disabled == 'function' ? props.buttonOptions.disabled() : props.buttonOptions.disabled) || null
  })

  const buttonSpacing = computed(() => {
    return props.buttonOptions.text ? "margin-right: 6px;" : ''
  })

  const showDropDownMenu = ref(false)
  const dropDownButton = ref(<null|HTMLElement>null)
  const dropDownButtonPosition = ref(<undefined|DOMRect>undefined)
  const dispatchEvent = (e:any) => {
    showDropDownMenu.value = !showDropDownMenu.value
  }

  const mouseEnteredDropDown = ref(false)

  const mouseLeaveDropdownButton = (event:any) => {
    setTimeout(() => {
    if(mouseEnteredDropDown.value == false) {
      showDropDownMenu.value = false
    }},100)
  }
  const mouseLeaveDropdown = (event:any) => {
    showDropDownMenu.value = false
    setTimeout(() => {
      mouseEnteredDropDown.value = false
    },100)
  }
  

  onMounted(() => {
    dropDownButtonPosition.value = dropDownButton.value?.getBoundingClientRect()
  })
</script>

<template>

    <template v-if="!props.buttonOptions.dropdownEntries">
      <Tooltip :tooltip="props.buttonOptions.title" :is-tag="'span'" :removeOnBeforeUnmount="true" :htmlTooltip="props.buttonOptions.htmlTooltip">
        <a class="btn btn2 btn-loader twist-in" :class="[
      { 'btn-loading': typeof props.buttonOptions.loading == 'function' ? props.buttonOptions.loading() : props.buttonOptions.loading },
      { 'disabled': (typeof props.buttonOptions.disabled == 'function' ? props.buttonOptions.disabled() : props.buttonOptions.disabled) || null },
      { 'style-icon': props.buttonOptions.type == 'icon' },
      { 'style-blank': props.buttonOptions.type == 'blank' },
      { ['btn-' + props.buttonOptions.size]: props.buttonOptions.size != undefined },
      { ['btn-' + props.buttonOptions.color]:props.buttonOptions.color }
    ]" :disabled="isDisabled || null"
          :href="props.buttonOptions.link" :target="props.buttonOptions.target"
          v-on:click="props.buttonOptions.onClick != undefined ? props.buttonOptions.onClick() : null" v-bind="$attrs">
          <span class="btn-wrapper">
            <span>
              <template v-if="props.buttonOptions.icon">
                <iconComponent class="fa-fw" :icon="typeof props.buttonOptions.icon == 'string' ? { 'class':props.buttonOptions.icon } : props.buttonOptions.icon" :style="buttonSpacing"></iconComponent>
              </template>
              <template v-if="props.buttonOptions.text">
                {{ props.buttonOptions.text }}
              </template>
            </span>
            <span class="animate">
              <loaderComponent v-if="props.buttonOptions.loading"></loaderComponent>
            </span>
          </span>
          <span class="topRightCircle" v-if="props.buttonOptions.topRightCircle">
            <template v-if="props.buttonOptions.topRightCircle?.counter">
              {{ props.buttonOptions.topRightCircle.counter }}
            </template>
            <template v-if="props.buttonOptions.topRightCircle.icon">
              <i :class="props.buttonOptions.topRightCircle.icon"></i>
            </template>
          </span>
        </a>
      </Tooltip>
    </template>
    <template v-else>
      <DropdownMenu @opened="dispatchEvent" @closed="dispatchEvent">
        <template #trigger>
          <Tooltip :tooltip="props.buttonOptions.title" :is-tag="'span'" :removeOnBeforeUnmount="true" :htmlTooltip="props.buttonOptions.htmlTooltip">
            <a 
              ref="dropDownButton"
              class="btn btn2 btn-loader twist-in" 
              :class="[
                { 'btn-loading': typeof props.buttonOptions.loading == 'function' ? props.buttonOptions.loading() : props.buttonOptions.loading },
                { 'disabled': (typeof props.buttonOptions.disabled == 'function' ? props.buttonOptions.disabled() : props.buttonOptions.disabled) || null },
                { 'style-icon': props.buttonOptions.type == 'icon' },
                { 'style-blank': props.buttonOptions.type == 'blank' },
                { ['btn-' + props.buttonOptions.size]: props.buttonOptions.size != undefined },
                { ['btn-' + props.buttonOptions.color]:props.buttonOptions.color }
              ]" 
              :disabled="isDisabled || null"
              :href="props.buttonOptions.link" :target="props.buttonOptions.target"
              @click="(props.buttonOptions.onClick != undefined ? props.buttonOptions.onClick() : null)" v-bind="$attrs"
            >
              <span class="btn-wrapper">
                <span>
                  <template v-if="props.buttonOptions.icon">
                    <iconComponent class="fa-fw" :icon="typeof props.buttonOptions.icon == 'string' ? { 'class':props.buttonOptions.icon } : props.buttonOptions.icon" :style="buttonSpacing"></iconComponent>
                  </template>
                  <template v-if="props.buttonOptions.text">
                    {{ props.buttonOptions.text }}
                  </template>
                </span>
                <span class="animate">
                  <loaderComponent v-if="props.buttonOptions.loading"></loaderComponent>
                </span>
              </span>
              <span class="topRightCircle" v-if="props.buttonOptions.topRightCircle">
                <template v-if="props.buttonOptions.topRightCircle?.counter">
                  {{ props.buttonOptions.topRightCircle.counter }}
                </template>
                <template v-if="props.buttonOptions.topRightCircle.icon">
                  <i :class="props.buttonOptions.topRightCircle.icon"></i>
                </template>
              </span>
            </a>
          </Tooltip>
        </template>


        <template #body>
          <Teleport to="body" v-if="showDropDownMenu">
            <nav 
              class="dropdownMenu" 
              :style="dropDownButtonPosition ? 'position:fixed; left:'+dropDownButtonPosition.x+'px; top:'+dropDownButtonPosition.y+'px; transform:translate(calc(-100% + 10px),24px)' : ''" 
            >
              <ul>
                <li v-for="btn in props.buttonOptions.dropdownEntries">
                  <template v-if="btn !== 'divider'">
                    <Tooltip :tooltip="btn.title" :is-tag="'span'" :removeOnBeforeUnmount="true" :htmlTooltip="btn.htmlTooltip">
                      <a 
                        class="btn btn2 btn-loader twist-in" 
                        :class="[
                          { 'btn-loading': typeof btn.loading == 'function' ? btn.loading() : btn.loading },
                          { 'disabled': (typeof btn.disabled == 'function' ? btn.disabled() : btn.disabled) || null },
                          { 'style-icon': btn.type == 'icon' },
                          { ['btn-' + btn.size]: btn.size != undefined }
                        ]" 
                        :disabled="isDisabled || null"
                        :href="btn.link" :target="btn.target"
                        v-on:click="btn.onClick != undefined ? btn.onClick() : null"
                      >
                        <span class="btn-wrapper">
                          <span>
                            <template v-if="btn.icon">
                              <iconComponent class="fa-fw" :icon="typeof btn.icon == 'string' ? { 'class':btn.icon } : btn.icon" :style="buttonSpacing"></iconComponent>
                            </template>
                            <template v-if="btn.text">
                              {{ btn.text }}
                            </template>
                          </span>
                          <span class="animate">
                            <loaderComponent v-if="btn.loading"></loaderComponent>
                          </span>
                        </span>
                        <span class="topRightCircle" v-if="btn.topRightCircle">
                          <template v-if="btn.topRightCircle?.counter">
                            {{ btn.topRightCircle.counter }}
                          </template>
                          <template v-if="btn.topRightCircle.icon">
                            <i :class="btn.topRightCircle.icon"></i>
                          </template>
                        </span>
                      </a>
                    </Tooltip>
                  </template>
                  <template v-else>
                    <hr>
                  </template>
                </li>
              </ul>
            </nav>
          </Teleport>
        </template>

      </DropdownMenu>

      

    </template>
  

</template>
<style lang="scss">
  @import "../../styles/sass/settings";
  .v-dropdown-menu {
    display:inline-block;
  }
  
  .btn2 {
    overflow: visible !important;
    text-shadow: none !important;
    &.btn-white {
      color:rgba(0,0,0,0.4)
    }
  }

  .btn.btn2.btn-loader > span.btn-wrapper {
    overflow: hidden;
    transition: 0s;
    padding:0;
    display: block;
    position: relative;
    transform:scale(1,1) !important;
    font-size:1em;
    > span {
      padding: 0.5em 0.75em;
      line-height: 1.5em;
      font-size:1em;
      display:block;
      transition:0.3s;
    }
  }

  .btn.btn2.btn-loader.btn2.btn-loading  > span.btn-wrapper {
    > span {
      transition:0.3s; 
      transform:scale(0,0);
      &.animate {
        transform:scale(1,1);
      }
    }
    
  }

  .btn span.topRightCircle {
    position: absolute !important;
    z-index:10;
    top:-7px;
    left:calc(100% - 16px);
    line-height:8px;
    padding:6px;
    font-size:10px;
    background-color:#E74C3C;
    color:#fff;
    display:block;
    border-radius:50%;
    border:2px solid;
    width:auto;
    min-width:18px;
    height:22px;
    i {
      line-height:1px;
      width:5px;
      &::before {
        left:-1.5px;
        position: relative;
      }
    }
  }

  .btn[disabled] span.topRightCircle {
    display:none;
  }

  .dropdownMenu {
    width: max-content;
    ul {
      background:#fff;
      list-style:none;
      margin:0;
      padding:0;
      min-width: 200px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
      border: 1px solid rgba(0, 0, 0, 0.1);
      line-height: 32px;
      text-align: left;
      > li {
        a.btn.btn2 {
          margin:0;
          border:none;
          background:none;
          text-shadow: none;
          color:rgba($fontColor,0.6);
          width:100%;
          text-align:left;
          border-radius: 0;
          :hover {
            background-color: rgba(0, 0, 0, 0.04);
          }
          > span.btn-wrapper > span {
            padding:8px;
            i {
              margin-right: 8px;
            }
          }
        }
        
        &:last-child a.btn.btn2 {
          border-bottom:none;
        }
      }
    }
  }

  .btn.btn2.style-icon {
    background:none;
    border:none;
    padding:0;
    margin:0;
    display:inline-block;
    position:relative;
    color:rgba(0,0,0,0.5);
    text-shadow:none;
    span.topRightCircle {
      border:none;
      min-width:17px;
      top:-5px;
      left:calc(100% - 15px);
      padding:4px;
      background:rgba(0,0,0,0.08);
      color:rgba(0,0,0,0.5);
      box-shadow:1px 1px 5px -2px rgba(0,0,0,0.1);
      height:17px;
    }
    &:hover {
      color:#E74C3C;
    }
  }

  .darkmode .btn span.topRightCircle {
    border-color:#25262A;
  }

  .darkmode .btn.btn2.style-icon {
    color: rgba(255, 255, 255, 0.5);
    span.topRightCircle {
      background:rgba(0,0,0,0.2);
      color:rgba(255,255,255,0.6);
      box-shadow:1px 1px 5px -2px rgba(0,0,0,0.1);
    }
    &:hover {
      color:#E74C3C;
    }
  }
</style>