import router from "@/router/router"
import { useStore } from '@/store/vuex.store'
import { throttle } from "throttle-debounce"
import devLog from "./log"
import queries from "@/queries/queries"
import timeHelpers from "@/helpers/helpers.time"
import $ from "jquery"
import unifiedSecurityQueries from "@/queries/unified.security"
import getterHelpers from "@/helpers/helpers.getters"
import useRouterStore from "@/router/routerStore"
class IdleTimer {
  private lastActionTimestamp : number = 0
  private maxTimeUntilTimeout: number = 600000
  private idleTimer : any | undefined = undefined

  constructor() {
    
  }

  initIdleTimer = () => {
    this.setMaxTimeUntilTimeout()
    this.updateLastActionTimestamp()

    $(document).on('mousemove', throttle(60 * 1000, async () => {
      await this.checkIdleTimer()
      this.updateLastActionTimestamp()
    }))
  }

  setMaxTimeUntilTimeout = () => {
    this.maxTimeUntilTimeout = useStore()?.state.session.userInfo.timeout || 600000
  }
  updateLastActionTimestamp = () => {
    this.lastActionTimestamp = timeHelpers.getUnixTimeStamp() * 1000
  }

  setIdleTimer = () => {
    this.idleTimer = setTimeout(() => {
      devLog.log('idleTimer', 'Logging out due inactivity', undefined, 'info', 1)
      this.killIdleTimer()
      this.timeoutLogout()
    }, this.maxTimeUntilTimeout)
  }

  checkIdleTimer = async () => {
    if (this.idleTimer) {
      clearTimeout(this.idleTimer)
    }
    const thisMouseMove = timeHelpers.getUnixTimeStamp() * 1000
    if ((thisMouseMove - this.lastActionTimestamp) > this.maxTimeUntilTimeout) {
      devLog.log('idleTimer', 'Logging out due inactivity', undefined, 'info', 1)
      this.killIdleTimer()
      this.timeoutLogout()
    }
    else {
      this.setIdleTimer()
      devLog.log('idleTimer', 'Checking authentification...', undefined, 'default', 3)

      let userInfo = await queries.unifiedSecurity.checkAuth()
      if (!userInfo) {
        devLog.log('idleTimer', 'User not authenticated. Missing userinfo. Logging out...', undefined, 'warning', 1)
        this.timeoutLogout()
      }
      else {
        devLog.log('idleTimer', 'User is authenticated', userInfo, 'success', 3)
        this.resetIdleTimer()
      }
    }
  }

  resetIdleTimer = () => {
    if (this.idleTimer) {
      clearTimeout(this.idleTimer)
    }
    this.setIdleTimer()
    this.updateLastActionTimestamp()
  }

  killIdleTimer = () => {
    $(document).off('mousemove')
    if (this.idleTimer) {
      clearTimeout(this.idleTimer)
    }
    devLog.log('idleTimer', 'Idletimer was killed', undefined, 'default', 3)
    this.idleTimer = undefined
  }

  timeoutLogout = () => {
    const activePage = useRouterStore().getActivePage
    const activePageInfo = getterHelpers.useStore().state.resources.pages[activePage || ""]
    let requiresSession = true
    if (activePageInfo) {
      requiresSession = Object.hasOwn(activePageInfo.options,"requiresSession") ? activePageInfo.options.requiresSession : true
    }
    unifiedSecurityQueries.logout(requiresSession)
  }
}
const idleTimer = new IdleTimer()
export default idleTimer