import type { ObjectStoreCountProperties } from "../../../types/vuex"
import { MutationTypes } from "@/store/vuex.store"
import devLog from "./log"
import apis from "./apis"
import requestHandler from "@/queries/requests"
import type { ProductType } from "@/resources/registeredProducts"
import { useStore } from '@/store/vuex.store'
import type Button from "./buttons"
import { T } from "./i18n"
import dialogs from "@/dialogs/dialogs"
import tenantHelpers from "@/helpers/helpers.tenants"


export interface ItemlistInfo {
  "isCheckboxChecked"?: boolean
  "isCheckboxHovering"?: boolean
  "details"?:ItemlistDetail[]
  "labels"?:Label[]
  "menuEntries"?:MenuEntry[]
  "title"?: {
    "title": string | undefined,
    "small"?: string | undefined,
    "link"?: {
      "innerHtml": string
      "onclick": Function
      "showIf": Function
    } | undefined
  }
}
export interface AddItem<T> {
  "$itemlist"?: ItemlistInfo
  "toJSON"?:() => T
}
export interface GenericObject<T> {
  "$itemlist"?: ItemlistInfo
  "toJSON"?: () => T
}

export type shemaErrors = {
  errors: any[],
  missing: any[],
  valid: false
}
export interface Label {
  id?:string
  text: string
  title: string
  htmlTooltip?:boolean
  icon?: string
  class?: string
  onClick?: () => void
  displayType?:"label",
  lineBreakAfter?: boolean,
  lineBreakBefore?: boolean,
  float?: "left" | "right",
}

export interface ItemlistDetail {
  key: string
  value?: string|number|Array<any>
  title: string
  iconClass: string
  labels?: Array<Label>
  buttons?: Array<Button>
  iconValue?:string
  editableContent?: {
    type: string,
    options: string,
    ref: string,
    value: any,
    editingBoolProperty: string,
    editButton: Button,
    select2Settings?:any,
    submitFunction: Function | Promise<any>
    abortFunction: Function | Promise<any>,
    multiple?: boolean
  } | false,
  regularlyUpdatedData?: {
    "inputValue":any,
    "method":Function,
    "refreshAfterMs":number,
    "frequency"?: number
  }
  
}

export interface ItemListStatus {
  "color": "red" | "orange" | "yellow" | "green"
  "tooltip"?: {
    "title": string
    "text": string
  }
}
export interface MenuEntry extends Button {
  "id"?: string
}

export type GetPropertiesObjectList = Array<{ 'property': string, 'value': string | number }>


export class GenericObjectStore<T extends GenericObject<T>> {
  public settings = {
    primaryKeyProperty: <keyof T>"objectId",
    nameProperty: {
      "primary": <keyof T|undefined>"",
      "pathToPrimaryProperty":<string>"",
      "secondary": <keyof T|undefined>"",
      "pathToSecondaryProperty": <string>""
    },
    productType: <ProductType>"unifiedSecurity",
    objectType: "objectType",
    appearance: {
      iconClass: "fal fa-question-square",
      color: "red",
      text: {
        "title": "ObjectTypes",
        "plural": "Objecttypes",
        "singular": "Objecttype"
      }
    },
    apiInfo: {
      "url": "/sms-mgt-api/api/1.1",
      "urlNew": "/sms-mgt-api/api/2.0",
      "listPath":"",
      "objectPath":"",
      "objectListPropertyInResponse":<string|Function>""
    }
  }
  public additionalInfo: any = {}

  /**
  * creates a new store for the given class/objectType
  */
  constructor() {}


  public itemlist = {

    getToolbar:(accountId:string,itemlistComponent:any) => {
      let result = <any>[]
      return result
    },
    getInfoBoxContent:(accountId:string,itemlistComponent:any) => {
      let result = ""
      return result
    },
    getTitle: (item: T, component:any) => {
      let primaryName = this.settings.nameProperty.primary ? String(item[this.settings.nameProperty.primary]) : undefined
      let secondaryName = this.settings.nameProperty.secondary ? String(item[this.settings.nameProperty.secondary]) : undefined
      let result : {
        title: string | undefined,
        small?: string | undefined,
        link?: {
          "innerHtml": string
          "onclick": Function
          "showIf": Function
        } | undefined
      } = {
        "title":  primaryName,
        "small": <string | undefined> secondaryName,
        "link": undefined
      }
      
      return result
    },
    getMenuEntries: (accountId:string,item: T): MenuEntry[] => {
      let menuEnties: MenuEntry[] = []
      return menuEnties
    },
    getLabels: (accountId:string,item: T): Label[] => {
      let result: Label[] = []
      result.push({
        "class": "bg-red",
        "icon": "fal fa-exclamation-triangle",
        "text": "Labels not defined for " + this.settings.appearance.text.title,
        "title": "Error"
      })
      return result
    },
    getDetails: (accountId: string,item: T,component:any) => {
      let result: ItemlistDetail[] = []
      const id = item[this.settings.primaryKeyProperty]

      result.push({
        "key": "ID",
        "title": "ID",
        "value": String(id),
        "iconClass": "fa fa-hashtag",
      })
      return result
    },
    getStatus: (accountId: string,item: T): ItemListStatus | undefined => {
      return undefined
    },
    onClick: (accountId: string,item: T) => {

    },
    isDisabled: (accountId:string,item: T): boolean => {
      return false
    },
    isClickable: (accountId:string,item: T): boolean => {
      return true
    },
    getIconBackgroundImage: <string | undefined | ((accountId: string,item: T) => string | undefined)>undefined,
    hasCheckBox: <boolean | ((item: T) => boolean)>false,
    isChecked: (item: T) => {
      return item?.$itemlist?.isCheckboxChecked || false
    },
    sortingOptions: <{ "id":string, "text":string }[]>[]
  }

  public dialogs : any = {
    getDeleteObjectDialog: (accountId:string,object: T,confirm:boolean=false) => {
      let singularOfObjectType = this.settings.appearance.text.singular.toLocaleLowerCase()
      let objectIdProperty = this.settings.primaryKeyProperty
      dialogs.misc.confirmDialog(accountId, T("Confirm delete"), T('Do you really want to delete this ' + singularOfObjectType + '?'), async () => {
        await this.deleteObjectFromApi(accountId,object[objectIdProperty])
      },confirm ? T("Delete"):undefined)
    }
  }
  getProductType() {
    return this.settings.productType
  }
  getPrimaryKeyProperty() {
    return this.settings.primaryKeyProperty
  }
  getNameProperty() {
    return this.settings.nameProperty
  }
  addGenericObjectInfos(accountId:string,item: AddItem<T>) {
    if (item?.$itemlist == undefined) (<GenericObject<T>>item).$itemlist = {
      "isCheckboxChecked": false,
      "isCheckboxHovering": false,
      "title":this.itemlist.getTitle(item as T,undefined),
      "details":this.itemlist.getDetails(accountId,item as T,undefined),
      "menuEntries":this.itemlist.getMenuEntries(accountId,item as T),
      "labels":this.itemlist.getLabels(accountId,item as T)
    }
    if ((<any>item).tags != undefined && Array.isArray((<any>item).tags)) {
      (<any>item).tags = (<any>item).tags.sort?.((tagA: string, tagB: string) => { return tagA.toLowerCase() > tagB.toLowerCase() ? 1 : -1 })
    }
    if(!item.toJSON) {
      item.toJSON = function() {
        let item : any = {}
        let addItem = this
        for (const key in addItem) {
          if (Object.prototype.hasOwnProperty.call(addItem, key) && ["$itemlist", "toJSON"].indexOf(key) == -1) {
            item[key] = this[key as keyof typeof addItem];
          }
        }
        return item as T
      }
    }
    return item as T
  }

  /**
  * Gets count of objects in this class, undefined means it hasn't been loaded yet
  */
  getCount(accountId: string, countProperty: ObjectStoreCountProperties="count") {
    return <number|undefined> useStore()?.getters.getCount({
      "accountId":accountId,
      "productType":this.settings.productType,
      "objectType":this.settings.objectType,
      "countProperty":countProperty
    })
  }

  /**
  * Updates count of objects in this class, undefined means it hasn't been loaded yet
  * @param count
  */
  setCount(accountId:string,count: number | undefined,countProperty:ObjectStoreCountProperties = "count") {
    useStore()?.commit(MutationTypes.setCount,{
      "accountId": accountId,
      "productType": this.settings.productType,
      "objectType": this.settings.objectType,
      "count":count,
      "countProperty":countProperty
    })
  }

  /**
  * Gets a list of all objects of this class
  */
  getObjectsFromStore(accountId: string) {
    return <Array<T>> useStore()?.getters.getObjects({
      "accountId": accountId,
      "productType": this.settings.productType,
      "objectType": this.settings.objectType,
    })
  }

  /**
  * Searches object from this class.
  * @param id
  */
  getObjectFromStore(accountId:string,id: T[keyof T]) {
    return <T>useStore()?.getters.getObject({
      "accountId": accountId,
      "productType": this.settings.productType,
      "objectType": this.settings.objectType,
      "objectId": String(id),
    })
  }

  /**
  * Adds object to this class' object list
  * @param object
  */
 addObjectToStore(accountId:string,object: T) {
    object = this.addGenericObjectInfos(accountId,object)
    useStore()?.commit(MutationTypes.addOrUpdateObjects, {
      "accountId": accountId,
      "productType": this.settings.productType,
      "objectType": this.settings.objectType,
      "items": [object]
    })
  }

  /**
  * Updates object in this class' object list if it exists
  * @param updateObject
  */
  updateObjectInStore(accountId:string,object: T) {
    useStore()?.commit(MutationTypes.addOrUpdateObjects, {
      "accountId": accountId,
      "productType": this.settings.productType,
      "objectType": this.settings.objectType,
      "items": [object]
    })
  }

  /**
  * Adds or Updates object in this class' object list depending on its existance
  * @param object
  */
  addOrUpdateObjectInStore(accountId:string,object: T) {
    useStore()?.commit(MutationTypes.addOrUpdateObjects, {
      "accountId": accountId,
      "productType": this.settings.productType,
      "objectType": this.settings.objectType,
      "items": [object]
    })
  }

  /**
  * Adds or Updates object(s) in this class' object list depending on its existance
  * @param objects
  */
  addOrUpdateObjectsInStore(accountId:string,objects: T | Array<T>) {
    let items = []
    if(Array.isArray(objects)) {
      items = objects
    }
    else {
      items = [objects]
    }

    items.forEach((item) => {
      if ((<any>item).tags != undefined && Array.isArray((<any>item).tags)) {
        (<any>item).tags = (<any>item).tags.sort?.((tagA: string, tagB: string) => { return tagA.toLowerCase() > tagB.toLowerCase() ? 1 : -1 })
      }
      item = this.addGenericObjectInfos(accountId,item)
    })

    let result = useStore()?.commit(MutationTypes.addOrUpdateObjects,{
      "accountId": accountId,
      "productType": this.settings.productType,
      "objectType": this.settings.objectType,
      "items": items
    })
    return result
  }

  /**
  * Checks for the existance of an object with the given id
  * @param id
  */
  hasObjectInStore(accountId:string,id: T[keyof T]): boolean {
    return <boolean>useStore()?.getters.hasObject({
      "accountId": accountId,
      "productType": this.settings.productType,
      "objectType": this.settings.objectType,
      "objectId": String(id)
    })
  }

  /**
  * Removes object from this class' object list, if it exists
  * @param id
  */
  removeObjectFromStore(accountId:string,id: T[keyof T]) {
    useStore()?.commit(MutationTypes.deleteObject, {
      "accountId": accountId,
      "productType": this.settings.productType,
      "objectType": this.settings.objectType,
      "objectId": String(id)
    })
  }

  /**
  * Generates a stringified GET payload  
  * @param props
  * @param withTimeStamp
  */
  getPropertiesString(props: GetPropertiesObjectList, withTimeStamp: boolean = false) {
    let timeStamp: number = Date.now()
    let propertiesString: string = ""
    if (withTimeStamp === true) {
      propertiesString += "?_=" + timeStamp
    }
    (props || []).forEach((prop) => {
      if (propertiesString.length == 0) {
        propertiesString += "?" + prop.property + "=" + prop.value
      }
      else {
        propertiesString += "&" + prop.property + "=" + prop.value
      }
    })
    return propertiesString
  }

  /**
  * Gets total count from sms-mgt-api
  * @param setAfterGet (optional) Wether to set count after getting count or not, defaults to true
  */
  async getCountFromApi(accountId: string,updateLocalStore: boolean = true): Promise<number | Error> {
    devLog.log("GenericStoreClass", "Function getCountFromApi is not defined", this, "error")
    return new Error("Function getCountFromApi is not defined")
  }

  /**
  * Gets object-list from sms-mgt-api
  * @param props (optional) GET request Payoad as array
  * @param updateLocalStore (optional) GET request Payoad as updateLocalStore (optional) wether the changes will affect the local store or not. defaults to true
  */
  async getObjectsFromApi(accountId: string,props?: GetPropertiesObjectList, updateLocalStore: boolean = true, newApi?: boolean): Promise<T[] | Error> {
    const tenantDomain = tenantHelpers.getTenantDomain(accountId)
    const propertiesString: string = props ? this.getPropertiesString(props) : ""
    let result : T[] | Error
    try {
      let response = await requestHandler.request("GET",  (newApi ? this.settings.apiInfo.urlNew : this.settings.apiInfo.url) + (this.settings.apiInfo.listPath || this.settings.apiInfo.objectPath).replace("{tenantDomain}", tenantDomain) + propertiesString)
      response = apis.parseApiResponse(response)

      if (typeof this.settings.apiInfo.objectListPropertyInResponse == "string" && this.settings.apiInfo.objectListPropertyInResponse.length > 0 && response[this.settings.apiInfo.objectListPropertyInResponse]) {
        result = response[this.settings.apiInfo.objectListPropertyInResponse as string] as T[]
      }
      else if (typeof this.settings.apiInfo.objectListPropertyInResponse == "function") {
        result = response[this.settings.apiInfo.objectListPropertyInResponse()] as T[]
      }
      else if (response.length) {
        result = response as T[]
      }
      else {
        throw "Error getting objects"
      }
      if (updateLocalStore) {
        this.addOrUpdateObjectsInStore(accountId, result) // Add Users to store
      }
      return result
      
    }
    catch(e : any) {
      devLog.log("GenericStoreClass", e.message, e, "error")
      throw e as Error
    }
  }

  /**
  * Gets single object from sms-mgt-api
  * @param objectId 
  * @param updateLocalStore (optional) wether the changes will affect the local store or not. defaults to true
  */
  async getObjectFromApi(accountId: string, objectId: T[keyof T], props?: GetPropertiesObjectList, updateLocalStore: boolean = true): Promise<T | Error> {
    devLog.log("GenericStoreClass", "Function getObjectFromApi is not defined", this, "error")
    return new Error("Function getObjectFromApi is not defined")
  }

  /**
  * Adds object to sms-mgt-api
  * @param object 
  * @param updateLocalStore (optional) wether the changes will affect the local store or not. defaults to true
  */
  async addObjectToApi(accountId: string,object: any, updateLocalStore: boolean = true): Promise<T | Error | shemaErrors> {
    devLog.log("GenericStoreClass", "Function addObjectToApi is not defined", this, "error")
    return new Error("Function addObjectToApi is not defined")
  }

  /**
  * Adds object to sms-mgt-api
  * @param object 
  * @param updateLocalStore (optional) wether the changes will affect the local store or not. defaults to true
  */
  async updateObjectFromApi(accountId: string, objectId: T[keyof T], object: T, updateLocalStore: boolean = true, updateProperties?: boolean,properties?:string[]): Promise<T | Error | shemaErrors> {
    devLog.log("GenericStoreClass", "Function updateObjectFromApi is not defined", this, "error")
    return new Error("Function updateObjectFromApi is not defined")
  }

  /**
  * Adds or updates object to sms-mgt-api
  * @param object 
  * @param updateLocalStore (optional) wether the changes will affect the local store or not. defaults to true
  */
  async addOrUpdateObjectFromApi(accountId: string,objectId:T[keyof T]|undefined,object: T, updateLocalStore: boolean = true): Promise<T | Error | shemaErrors> {
    if(objectId) {
      return await this.updateObjectFromApi(accountId,objectId, object, updateLocalStore)
    }
    else {
      return await this.addObjectToApi(accountId,object, updateLocalStore)
    }
  }
  
  /**
  * Deletes object from sms-mgt-api
  * @param objectId 
  * @param updateLocalStore (optional) wether the changes will affect the local store or not. defaults to true
  */
  async deleteObjectFromApi(accountId: string,objectId: T[keyof T], updateLocalStore: boolean = true): Promise<true | Error> {
    devLog.log("GenericStoreClass", "Function deleteObjectFromApi is not defined", this, "error")
    return new Error("Function deleteObjectFromApi is not defined")
  }


  async updateObjectInventory(accountId: string, objectId: T[keyof T], object: any): Promise<boolean | Error | shemaErrors> {
    devLog.log("GenericStoreClass", "Function updateObjectInventory is not defined", this, "error")
    return new Error("Function updateObjectInventory is not defined")
  }
}
