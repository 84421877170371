const en = {
    "XPlease enter": "Please enter",
    "Xto confirm":"to confirm",
    "XLink":"Link",
    "Xo'clock":"o'clock",
    "The apple servers have reported following error:":"Die Apple Server haben folgenden Fehler zurückgeliefert:",
    "Automatic VPP license assignment":"Automatische VPP Lizenzzuweisung",
    "Choose wether the VPP app license should be automatically assigned to the device or not.":"Wählen Sie aus, ob die VPP-App-Lizenz automatisch dem Gerät zugewiesen werden soll oder nicht.",
    "A profile with this name already exists. Saving this profile would overwrite the existing one.":"Ein Profil mit diesem Namen existiert bereits. Speichern dieses Profils würde das bestehende überschreiben.",
    "Productselection":"Produktauswahl",
    "Inventory list":"Inventarliste",
    "xFrom":"From",
    "xState": "State",
    "must match a schema in anyOf":"must match one of the points mentioned",
    "Resuming job at label dryrun":"Resuming Job at \"Dryrun\".",
    "Resuming job at label reboot": "Resuming Job at \"Reboot\".",
    "Resuming job at label finalize": "Resuming Job at \"Finalize\".",
    "Job is parked with label dryrun. Waiting for resume response.": "Job is parked at \"Dryrun\". Waiting for resume response.",
    "Job is parked with label reboot. Waiting for resume response.": "Job is parked at \"Reboot\". Waiting for resume response.",
    "Job is parked with label finalize. Waiting for resume response.": "Job is parked at \"Finalize\". Waiting for resume response.",
    "Job is done [Update is done]": "Job is done (Update)",
    "xDashboard": "Dashboard",
    "Benutzerdaten sind gespeichert": "Saved Credentials",
    "Favorit": "Favorite",
    "Zum Starten wird ein PIN benötigt": "A PIN is needed to start the connection",
    "Letzte Änderung": "Last Change",
    "Log von": "Log of",
    "Hole Logeinträge...": "Getting log entrys...",
    "Das Verbindungs-Log des VPN-Clients kann nach den Schweregraden gefiltert oder sortiert werden. Die Schweregrade sind:": "The connection log of the VPN client can be filtered or sorted according to the severity levels. The severity levels are:",
    "Information(Info)": "Information(Info)",
    "Warnungen(Warning)": "Warnings(Warning)",
    "Fehler(Error)": "Error(Error)",
    "Datum": "Date",
    "Schweregrad": "Severity",
    "Nachricht": "Message",
    "WSC-Risiko": "WSC risk",
    "WSC-Gefährdung": "WSC danger",
    "Keine Aktion erforderlich": "No action required",
    "Status wird nicht von der WSC überwacht": "Status is not monitored by the WSC",
    "Computer ist möglicherweise gefährdet": "Computer may be at risk",
    "WSC schützt den Computer nicht aktiv": "WSC does not actively protect the computer",
    "Firewall": "Firewall",
    "Automatische Updates": "Automatic updates",
    "Antivirus": "Antivirus",
    "Internet-Einstellungen": "Internet settings",
    "WSC-Service-Status": "WSC service status",
    "UAC-Einstellungen": "UAC settings",
    "Fehlende Berechtigung":"Missing permission",
    "PIN deaktiviert":"PIN disabled",
    "PIN gesperrt":"PIN locked",
    "Die Websession PIN ist aufgrund mehrerer Fehleingaben gesperrt. Bitte schalten Sie die Websession PIN auf der Core-UTM wieder frei.":"The websession PIN is locked due to several incorrect entries. Please unlock the websession PIN on the Core-UTM again.",
    "Die Websession PIN ist auf der UTM deaktiviert. Bitte aktivieren Sie die PIN um die Konfiguration veröffentlichen zu können.":"The websession PIN is deactivated on the UTM. Please activate the PIN to be able to publish the configuration.",
    "Die Core-UTM ist offline. Bitte stellen Sie sicher dass die Core-UTM erreichbar ist.":"The Core-UTM is offline. Please make sure that the Core-UTM is accessible.",
    "Die Core-UTM ist nicht für die Unified Network Console freigeschaltet. Bitte aktivieren Sie die Unified Network Console für die Core-UTM.":"The Core-UTM is not enabled for the Unified Network Console. Please activate the Unified Network Console for the Core-UTM.",
    "Nicht alle UTMs in der Konfiguration sind online. Unter Umständen müssen Sie die Veröffentlichung der Konfiguration erneut durchführen, wenn die betroffenen UTMs wieder online sind.":"Not all UTMs in the configuration are online. You may have to publish the configuration again when the affected UTMs are online again.",
    "Nicht alle UTMs in der Konfiguration sind für die Unified Network Console freigeschaltet. Bitte aktivieren Sie die Unified Network Console für alle UTMs aus dieser Konfiguration, um die Funktionalität auf allen UTMs nutzen zu können.":"Not all UTMs in the configuration are enabled for the Unified Network Console. Please activate the Unified Network Console for all UTMs in this configuration in order to be able to use the functionality on all UTMs.",
    "Es fehlen Satelliten oder Adresspools.":"Missing satellites oder addresspools.",
    "Es gibt nichts zu veröffentlichen.":"There is nothing to publish.",
    "Diese UTM wird von der Unified Network Console unterstützt und befindet sich in einer Konfiguration":"This UTM is supported by the Unified Network Console and is in a configuration",
    "Verfügbar":"Available",
    "Nicht verfügbar":"Unavailable",
    "CPU Auslastung:": "CPU utilization:",
    "Arbeitsspeicherinformationen": "Working memory information",
    "Speicher": "Memory",
    "Diagnose der Verbindung": "Connection diagnosis",
    "Remote-Host aus der Konfiguration": "Remote host of the configuration",
    "Remote-Port aus der Konfiguration": "Remote Port of the configuration",
    "Aufgelöste IP des Remote-Hostes": "Resolved ip of the remote host",
    "Remote-Host und Port sind vom Client aus erreichbar": "Remote host and port are reachable from the client",
    "Das Abrufen der Verbindungsinformationen dauert ungewöhnlich lange. Bitte überprüfen Sie den Verbindungsstatus Ihres VPNs.": "Fetching the connection information takes unusually long. Please check the connection status of your VPN.",
    "Öffentliche IP des Clients": "Public ip of the client",
    "Öffentliche IP": "Public IP",
    "Erreichbar": "Reachable",
    "Hole Verbindungsinformationen...": "Fetching connection information...",
    'Vergeben Sie einen Alias (wie z. B. "LG1"), um generierte Objekte auf der UTM leichter zuordnen zu können. (max 32 Zeichen (a-zA-Z0-9_-)).':'Assign an alias (such as "LG1") to make it easier to identify generated objects on the UTM. (max 32 characters (a-zA-Z0-9_-))',
    "Die UTM wurde erst kürzlich aus der Konfiguration entfernt und kann erst wieder hinzugefügt werden, wenn sie sich nach Löschung der alten Konfigurationsdaten einmal zurückgemeldet hat. Bitte haben Sie einen Moment Geduld.":"The UTM was only recently removed from the configuration and can only be added again once it has reported back after the old configuration data has been deleted. Please be patient for a moment.",
    "Aktualisiere...":"Refreshing...",
    "Die Daten der Windows-Sicherheit kommen von dem Windows-Security-Center-Dienst (wscsvc). Das WSC sammelt die aktuellen Informationen zur Sicherheit des Windows-Clients. Neben dem Schutz durch Antivirenprodukte von Drittanbietern, Windows Firewall, dem Status des Dienstes, wird die Konfiguration der Internet-Einstellungen, des Automatischen-Updates und der Benutzerkontensteuerung (UAC) ausgewertet.": "The Windows security data of the Windows Security Center service (wscsvc). The WSC collects current information on the security of the Windows client. In addition to the protection provided by third-party antivirus products, Windows Firewall, the status of the service, the configuration of the Internet settings, Automatic Update and User Account Control (UAC) are analysed.",
    'Jeder dieser Security-Provider hat einen Status von "Gut", "Nicht überwacht", "Schlecht" und "Temporär deaktiviert".': 'Each of these security providers has a status of ‘Good’, ‘Not monitored’, ‘Poor’ and ‘Snooze’.',
    "Erklärungen:": "Explanation:",
    "Windows-Sicherheit": "Windows-Security",
    "Gut: Der Status der Sicherheitsanbieterkategorie ist gut und erfordert keine Aufmerksamkeit des Benutzers.": "Good: The status of the security provider category is good and requires no attention from the user.",
    "Nicht überwacht: Der Status der Sicherheitsanbieterkategorie wird von WSC nicht überwacht.": "Not monitored: The status of the security provider category is not monitored by WSC.",
    "Gut": "Good",
    "Nicht überwacht": "Not monitored",
    "Schlecht": "Poor",
    "Temporär deaktiviert": "Snooze",
    "Automatisches-Update": "Automatic Updates",
    "Benutzerkontensteuerung": "User account control",
    "Dienst-Status": "Service status",
    "Schlecht: Der Sicherheitsanbieterkategorie ist schlecht, und der Computer ist möglicherweise gefährdet.": "Poor: The security provider category is bad and the computer may be at risk.",
    'Temporär deaktiviert: Die Sicherheitsanbieterkategorie befindet sich im Zustand "Temporär deaktiviert", der Computer ist möglicherweise gefährdet.': "Snooze: The security provider category is in the Temporarily Disabled state, and the computer may be at risk.",
    "Pflichtfeld":"Required",
    "Wählen Sie ein bestehendes Netzwerkobjekt aus oder legen Sie ein neues an.":"Select an existing network object or create a new one.",
    "Wählen Sie einen bestehenden Dienst aus oder legen Sie einen neuen an.":"Select an existing service or create a new one.",
    "Wählen Sie aus ob Sie eine UTM oder einen Adresspool hinzufügen möchten":"Select whether you want to add a UTM or an address pool",
    "Der Name der für diesen Adresspool verwendet wird":"The name used for this address pool",
    "Sie besitzen aktuell keine Lizenzen, die Sie zur Nutzung des Securepoint Unified Security Portals berechtigen. Bitte nutzen Sie das Reseller Portal für eine Neulizenzierung oder Verlängerung.": "You currently do not have any licenses that entitle you to use the Securepoint Unified Security Portal. Please use the Reseller Portal for a relicensing or renewal.",
    "Dieses Gerät ist einem Profil zugewiesen, das Teil eines Transfernetzes in der UNC-Konfiguration ist. Dieses Transfernetz hat keine ausreichende Kapazität für dieses Gerät mehr.":"This device is assigned to a profile that is part of a transfer network in the UNC configuration. This transfer network does not have sufficient capacity for this device.",
    "Dieses Gerät ist einem Profil zugewiesen, das Teil eines Transfernetzes in der UNC-Konfiguration ist. Dieses Transfernetz hat keine ausreichende Kapazität mehr.":"This device is assigned to a profile that is part of a transfer network in the UNC configuration. This transfer network does not have sufficient capacity.",
    "Dieses Gerät ist einem Profil zugewiesen, das Teil eines Transfernetzes in der UNC-Konfiguration ist.":"This device is assigned to a profile that is part of a transfer network in the UNC configuration.",
    "Das Gerät muss online sein, um die Daten anzufordern": "The device must be online to request the data",
    "Websession starten":"Start websession",
    "Nicht verbunden": "Not connected",
    "Um diese Aktion durchführen zu können, muss das Gerät Online sein": "To perform this action, the device must be online"

}
export default en