import licenseHelpers from "@/helpers/helpers.license"
import ObjectType, { type Label, type ObjectTypePayload } from "../../objectType"
import dialogs from "@/dialogs/dialogs"
import i18n, { T } from "@/classes/i18n"
import Button from "@/classes/buttons"
import tenantHelpers from "@/helpers/helpers.tenants"
import numberHelpers from "@/helpers/helpers.numbers"
import type { ItemlistDetail } from "@/classes/genericObjectStore"
import router from "@/router/router"
import getterHelpers from "@/helpers/helpers.getters"
import jsonHelpers from "@/helpers/helpers.json"
import { ActionTypes, MutationTypes } from "@/store/vuex.store"
import download from "downloadjs"
import products from "../.."
import deviceHelpers from "@/helpers/helpers.devices"
import requestHandler from "@/queries/requests"
import apis from "@/classes/apis"
import devLog from "@/classes/log"
import config from "@/classes/config"
import arrayHelpers from "@/helpers/helpers.arrays"

export interface IosAppsKnownInstallation {
    "deviceId": string,
    "timestamp": string,
    "version": string
}

export interface IosApp {
    "appId":string,
    "name"?:string,
    "Label"?: string,
    "platform"?:"WEBCLIP"|"IOS",

    "devices"?: string[],
    "affectedDeviceIds"?: string[],
    "users"?: string[],
    "roles"?: string[],
    "tags"?: string[],
    "comment"?: string,
    "iTunesStoreID"?: number,
    "Identifier"?: string,
    "ManifestURL"?:string,
    "uninstallPackageName"?: string,
    "ChangeManagementState"?: "Managed",
    "ManagementFlags"?:0|1,
    "tenantDomain"?: string,
    "lastInstallDate"?: string,
    "lastUninstallDate"?: string,
    "knownInstallations"?: IosAppsKnownInstallation[],
    "Precomposed"?:boolean,
    "TargetApplicationBundleIdentifier"?:string
    "IsRemovable"?: boolean,
    "Options"?: {
        "PurchaseMethod": "none" | 0 | 1
    },
    "Configuration"?:any,
    "IgnoreManifestScope"?: boolean,
    
    "Icon"?: string,
    "hasIcon"?:boolean,
    'Icon:data'?:string,

    "URL"?: string,
    "FullScreen"?: boolean,
    "disableAutomaticVPPLicenseAssignment"?: "0"|"1"
    "managedConfiguration"?: string

    "assetInfo"?: AssetInfo
}

export interface AssetInfo {
    screenshotUrls: string[];
    ipadScreenshotUrls: string[];
    appletvScreenshotUrls: string[];
    artworkUrl60: string;
    artworkUrl512: string;
    artworkUrl100: string;
    artistViewUrl: string;
    isGameCenterEnabled: boolean;
    features: string[];
    supportedDevices: string[];
    advisories: string[];
    kind: string;
    trackCensoredName: string;
    languageCodesISO2A: string[];
    fileSizeBytes: string;
    sellerUrl: string;
    formattedPrice: string;
    minimumOsVersion: string;
    contentAdvisoryRating: string;
    averageUserRatingForCurrentVersion: number;
    userRatingCountForCurrentVersion: number;
    averageUserRating: number;
    trackViewUrl: string;
    trackContentRating: string;
    releaseNotes: string;
    artistId: number;
    artistName: string;
    genres: string[];
    price: number;
    description: string;
    isVppDeviceBasedLicensingEnabled: boolean;
    trackId: number;
    trackName: string;
    primaryGenreName: string;
    primaryGenreId: number;
    bundleId: string;
    currentVersionReleaseDate: string;
    releaseDate: string;
    genreIds: string[];
    sellerName: string;
    currency: string;
    version: string;
    wrapperType: string;
    userRatingCount: number;
    isInStore: boolean;
};


class IosApps extends ObjectType<IosApp> {
    constructor(payload: ObjectTypePayload<IosApp>) {
        super(payload)
        const thisObjectType = this
        this.itemlist.getToolbarButtons = (accountId,itemlistComponent) => {
            let thisToolbarEntries = []
            if (licenseHelpers.hasOneOfLicenses(accountId, ['Mobile Security', 'MDM'], 'valid')) {
                thisToolbarEntries.push(
                    {
                        icon: 'fal fa-plus',
                        title: T('Add app'),
                        link: '#add-tenant-' + accountId + '.sms-ios-app',
                        id: 'appsButtonAdd',
                        vIf: false
                    }
                )
            }
            return thisToolbarEntries
        }
        this.itemlist.getSortingOptions = () => {
            return [
                {
                    "id": "name",
                    "text": T("Name")
                }
            ]
        }

        this.itemlistItem.hasCheckbox = (item) => {
            return true
        }

        this.itemlistItem.onClick = (accountId, item) => {
            if (licenseHelpers.hasOneOfLicenses(accountId, ['Mobile Security', 'MDM'], 'valid') && item?.appId) {
                router.navigate('#edit-tenant-' + accountId + '.sms-ios-app-' + item?.appId)
            }
        }

        this.itemlistItem.getIconBackgroundImage = (accountId, item) => {
            let result: any = null
            if (item?.hasIcon) {
                result = 'background-image:url(/sms-mgt-api/api/' + config.mgtApiVersion + '/tenants/' + accountId + '.sms/apps/' + item?.appId + '/icon)'
            }
            else if (item && item['Icon:data']) {
                result = 'background-image:url(data:image/png;base64,' + item['Icon:data'] + ')'
            }
            else if (item?.assetInfo?.artworkUrl60) {
                result = 'background-image:url(' + item?.assetInfo.artworkUrl60 + ')'
            }
            return result
        }
        
        this.itemlistItem.getTitle = (item,component) => {
            return {
                "title":item?.name?.replace(" (VPP)","") || ""
            }
        }
        this.itemlistItem.getLabels = (accountId,item) => {
            let thisLabels : Label[] = []
            if(item?.name?.indexOf("(VPP)") != -1) {
                thisLabels.push({
                    "title":"VPP",
                    "text":"VPP",
                    icon: "fab fa-apple"
                })
            }
            return thisLabels
        }
        this.itemlistItem.getMenuEntries = (accountId,item,component) => {
            let menuLinks = []
            if (licenseHelpers.hasOneOfLicenses(accountId, ['Mobile Security', 'MDM'], 'valid')) {
                menuLinks.push(
                    new Button({
                        title: T('Edit'),
                        text: T('Edit'),
                        link: '#edit-tenant-' + accountId + '.sms-ios-app-' + item?.appId,
                        icon: 'fal fa-edit',
                    }))
                if(item?.name?.indexOf("(VPP)") == -1) {
                    menuLinks.push(
                        new Button({
                            title: T('Copy'),
                            text: T('Copy'),
                            onClick: async () => {
                                let app = await this.queries.getObjectFromApi(accountId, item.appId)
                                if (!(app instanceof Error)) {
                                    this.saveToClipBoard(accountId, app)
                                }
                            },
                            icon: 'fal fa-clone'
                        }))
                }
                // #35768
                if(item?.name?.indexOf("(VPP)") == -1) {
                menuLinks.push(
                    new Button({
                        title: T('Export'),
                        text: T('Export'),
                        onClick: () => {
                            this.exportObject(accountId, item?.appId)
                        },
                        icon: 'fal fa-download',
                    })
                )
                }
                menuLinks.push(new Button({
                    title: T('Install'),
                    text: T('Install'),
                    onClick: () => {
                        dialogs.mobileSecurity.renderInstallOrUninstallAppDialog(accountId, item?.appId, "install")
                    },
                    icon: 'fal fa-cog'
                }))
            }
            if (item?.uninstallPackageName || item.platform == "WEBCLIP") {
                menuLinks.push(new Button({
                    title: T('Uninstall'),
                    text: T('Uninstall'),
                    onClick: () => {
                        dialogs.mobileSecurity.renderInstallOrUninstallAppDialog(accountId, item?.appId, "uninstall")
                    },
                    icon: 'fal fa-times'
                }))
            }
                menuLinks.push(new Button({
                    title: T('Delete'),
                    text: T('Delete'),
                    onClick: () => {
                        this.dialogs.getDeleteObjectDialog(accountId, item)
                    },
                    icon: 'fal fa-trash',
                })) 
            return menuLinks
        }


        this.itemlistItem.getDetails = (accountId,item,component) => {

            let iosDevicesStore = products.mobileSecurity.iosDevices.useStore?.()

            let thisDevicesArray : Label[] = []
            if (item?.devices && item.devices.length >= 5) {
                let thisDevice: any = {}
                thisDevicesArray = [{
                    "text": item?.devices?.length + " " + T('Devices'),
                    "title": item?.devices?.map((deviceId: any) => {
                        thisDevice = iosDevicesStore?.getObjectStoreObject(accountId,deviceId)
                        return deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId || thisDevice?.id, thisDevice?.alias || thisDevice?.info?.deviceName || undefined, false)
                    }).join(', '),
                    "onClick": undefined,
                    "displayType": "label"
                }]
            }
            else if (item?.devices) {
                thisDevicesArray = item?.devices?.map(function (deviceId: string) {

                    let thisDevice = iosDevicesStore?.getObjectStoreObject(accountId, deviceId)

                    return {
                        "text": deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId || deviceId, thisDevice?.alias || thisDevice?.info?.deviceName || undefined, false),
                        "title": deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId || deviceId, thisDevice?.alias || thisDevice?.info?.deviceName || undefined, false),
                        "onClick": function () {
                            router.navigate('#show-tenant-' + accountId + '.sms-ios-devices-dashboard-' + deviceId)
                            
                        },
                        "displayType": "label"
                    }
                })
            }
            return [
                {
                    iconClass: 'fal fa-fw fa-microchip',
                    title: T('Type'),
                    key: T('Type'),
                    value: item ? item?.platform == 'IOS' ? T('App') : item?.platform == 'WEBCLIP' ? T('Webclip') : T("Unknown") : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                },
                {
                    iconClass: 'fal fa-fw fa-users',
                    title: T('Roles'),
                    key: T('Roles'),
                    [item ? "labels" : "value"]: item ? item?.roles?.map(function (role: string) {
                        return {
                            "id": role,
                            "text": role,
                            "title": role,
                            "onClick": function () {
                                router.navigate('edit-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-role-' + role)
                            },
                            "displayType": "label"
                        }
                    }) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                },
                {
                    iconClass: 'fal fa-fw fa-user',
                    title: T('Users'),
                    key: T('Users'),
                    [item ? "labels" : "value"]: item ? item?.users?.map(function (user: string) {
                        return {
                            "id": user,
                            "text": user,
                            "title": user,
                            "onClick": function () {
                                router.navigate('#show-tenant-' + accountId + '.sms-users-dashboard-' + user)
                            },
                            "displayType": "label"
                        }
                    }) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                },
                {
                    iconClass: 'fal fa-fw fa-mobile-alt',
                    title: T('Devices'),
                    key: T('Devices'),
                    [item ? "labels" : "value"]: item ? thisDevicesArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                },
                {
                    iconClass: 'fal fa-fw fa-tags',
                    title: T('Tags'),
                    key: T('Tags'),
                    [item ? "labels" : "value"]: item ? item?.tags?.map(function (tag: string) {
                        return {
                            "id": tag,
                            "text": tag,
                            "title": tag,
                            "onClick": undefined,
                            "displayType": "label"
                        }
                    }) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                }
            ]
        }


        this.queries.getObjectsFromApi = async (accountId, customerId?, props?, updateStore = true) => {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            let result: IosApp[] | Error
            try {
                let response = await requestHandler.request(this.options.apiInfo.getObjectListMethod, this.getListUrl(accountId) + propertiesString)
                response = apis.parseApiResponse(response)
                let propertyInresponse = this.options.apiInfo.getObjectListResponseProperty
                if (typeof propertyInresponse == "string" && propertyInresponse.length > 0 && response[propertyInresponse]) {
                    result = response[propertyInresponse as string] as IosApp[]
                }
                else if (typeof propertyInresponse == "function" && response[propertyInresponse()]) {
                    result = response[propertyInresponse()] as IosApp[]
                }
                else if (Array.isArray(response)) {
                    result = response as IosApp[]
                }
                else {
                    throw new Error("Error getting Objects")
                }

                try {
                    result = await this.completeAssetInfos(result, "iTunesStoreID")
                }
                catch (e) {
                    console.error(e)
                }
                try {
                    result = await this.completeAssetInfos(result, "Identifier")
                }
                catch (e) {
                    console.error(e)
                }

                if (updateStore) {
                    this.useStore?.().setObjectTypeObjects(accountId, result)
                }
                return result
            }
            catch (e: any) {
                devLog.log("ObjectType", e.message, e, "error")
                throw e as Error
            }
        }
        this.queries.getObjectFromApi = async (accountId, objectId, customerId?, props?, updateStore = true) => {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            let result: IosApp | Error
            try {
                if (!objectId) throw "Missing objectId"
                let response = await requestHandler.request(this.options.apiInfo.getObjectMethod, this.getSingleObjectUrl(accountId, objectId, customerId) + propertiesString)
                response = apis.parseApiResponse(response)
                if (!jsonHelpers.isObjectEmpty(response)) {
                    result = response as IosApp
                    if(!(result instanceof Error)) {
                        let thisAppArray = [result]
                        try {
                            thisAppArray = await this.completeAssetInfos(thisAppArray,"iTunesStoreID")
                        }
                        catch (e) {
                            console.error(e)
                        }
                        try {
                            thisAppArray = await this.completeAssetInfos(thisAppArray,"Identifier")
                        }
                        catch (e) {
                            console.error(e)
                        }

                        result = thisAppArray[0]
                    }
                }
                else {
                    throw "Error getting objects"
                }
                if (updateStore) {
                    this.useStore?.().setObjectTypeObject(accountId, String(objectId), result)
                }
                return result
            }
            catch (e: any) {
                devLog.log("ObjectType", e.message, e, "error")
                throw e as Error
            }
        }

        this.dialogs.getDeleteObjectDialog = (accountId, object, customerId, confirm = false) => {
            let singularOfObjectType = this.options.appearance.text.singular.toLocaleLowerCase()
            let objectIdProperty = this.options.objectTypeInfo.primaryKeyProperty.property
            let objectId = this.getObjectId(object)
            let objectName = this.itemlistItem.getTitle(object, undefined).title

            let modal = {
                "accountId": accountId,
                "id": "deleteAppDialog",
                "abortable": true,
                "content": {
                    "title": {
                        "text": T('Confirm delete'),
                        "icon": "fal fa-exclamation-triangle"
                    },
                    "body": {
                        "use": true,
                        "content": "<p>" + T('Do you really want to delete this ' + singularOfObjectType + '?') + (objectName ? " (" + objectName + ")" : "") + "</p>",
                        "component": undefined
                    }
                },
                "buttons": [
                    {
                        "text": T("Cancel"),
                        "icon": "fal fa-times",
                        "onClick": async () => {
                            getterHelpers.useStore().commit(MutationTypes.removeModal, {
                                "accountId": accountId
                            })
                        },
                        "align": "left",
                        "loading": false
                    },
                    {
                        "text": T("Uninstall and delete"),
                        "icon": "fal fa-trash",
                        "onClick": async () => {
                            getterHelpers.useStore().getters.getActiveModal(accountId).buttons[0].loading = true
                            getterHelpers.useStore().getters.getActiveModal(accountId).buttons[1].loading = true
                            getterHelpers.useStore().getters.getActiveModal(accountId).buttons[2].loading = true
                            await this.uninstallApp(accountId, String(objectId))
                            await this.queries.deleteObjectFromApi(accountId, objectId, undefined)
                            getterHelpers.useStore().commit(MutationTypes.removeModal, {
                                "accountId": accountId
                            })
                            getterHelpers.useStore().getters.getActiveModal(accountId).buttons[0].loading = false
                            getterHelpers.useStore().getters.getActiveModal(accountId).buttons[1].loading = false
                            getterHelpers.useStore().getters.getActiveModal(accountId).buttons[2].loading = false
                        },
                        "align": "center",
                        "loading": false
                    },
                    {
                        "text": T("Delete without uninstall"),
                        "icon": "fal fa-times",
                        "onClick": async () => {
                            getterHelpers.useStore().getters.getActiveModal(accountId).buttons[0].loading = true
                            getterHelpers.useStore().getters.getActiveModal(accountId).buttons[1].loading = true
                            getterHelpers.useStore().getters.getActiveModal(accountId).buttons[2].loading = true
                            await this.queries.deleteObjectFromApi(accountId, objectId, undefined)
                            getterHelpers.useStore().commit(MutationTypes.removeModal, {
                                "accountId": accountId
                            })
                            getterHelpers.useStore().getters.getActiveModal(accountId).buttons[0].loading = false
                            getterHelpers.useStore().getters.getActiveModal(accountId).buttons[1].loading = false
                            getterHelpers.useStore().getters.getActiveModal(accountId).buttons[2].loading = false
                        },
                        "align": "right",
                        "loading": false
                    }
                ]
            }
            getterHelpers.useStore().dispatch(ActionTypes.addModal, modal)
        }

    }


    installApp = async (accountid: string, objectId: string) => {
        let result: any = null
        try {
            if (this.useStore?.().hasAccount(accountid) == false) {
                throw { "status": 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request("POST", apis.getTenantApiUrl(tenantDomain, "apple") + "/apps/" + objectId + "/install")
        }
        catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    }

    uninstallApp = async (accountid: string, objectId: string) => {
        let result: any = null
        try {
            if (this.useStore?.().hasAccount(accountid) == false) {
                throw { "status": 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request("POST", apis.getTenantApiUrl(tenantDomain, "apple") + "/apps/" + objectId + "/uninstall")
        }
        catch (e: any) {
            console.error(e)
            result = false
        }
        return result
    }


    saveToClipBoard = (accountId: string, object: IosApp, addNotification: boolean = true) => {
        if (object) {
            if (typeof object == "object") {
                object = jsonHelpers.copyObject(object)
            }
            (<any>object).oldItemName = object.name || "";
            (<any>object).name = object.name + T("_Copy")
            delete (<any>object).appId
            delete (<any>object).tenantDomain
            delete (<any>object).assetInfo

            getterHelpers.useStore().commit(MutationTypes.setClipboard, {
                "objectType": "iosApps",
                "objects": [object]
            })
            if(addNotification) {
                getterHelpers.useStore().dispatch(ActionTypes.addNotification, {
                    "accountId": accountId,
                    "content": {
                        "title": {
                            "icon": "fal fa-exclamation-triangle",
                            "text": "Clipboard"
                        },
                        "body": {
                            "content": "Added item to clipboard"
                        }
                    }
                })
            }
        }
    }

    exportObject = async (accountId: string,objectId:string) => {
        try {
            let object = await this.queries.getObjectFromApi(accountId,objectId)
            if (!(object instanceof Error)) {
                let filename: string = object.name || ""
                delete (<any>object).appId
                delete object.tenantDomain
                delete object.hasIcon
                delete object.affectedDeviceIds

                if (object.devices?.length) {
                    object.devices = []
                }
                if (object.roles?.length) {
                    object.roles = []
                }
                if (object.users?.length) {
                    object.users = []
                }

                object.name = filename

                download(new Blob([JSON.stringify(object)]), `${filename}.iosApp`, "application/json")
            }
        }
        catch (e: any) {
            console.error(e)
        }
    }

    /**
     * completeAssetInfos
     * @param {[any]} assets
     *
     **/
    completeAssetInfos = async (assets: IosApp[], idProperty: string = 'adamIdStr') => {
        let result: any = {}
        let resultsByAdamIdStr: any = {}

        const ids: string[] = assets.map(function (asset: any) {
            if (asset[idProperty]) {
                return asset[idProperty].toString()
            }
        }).filter((value,index,array) => { 
            return value != undefined && arrayHelpers.onlyUniqueFilter(value,index,array)
        })
        const countryCode: string | undefined = getterHelpers.useStore()?.state.session.accounts[getterHelpers.useStore().state.session.activeAccountId]?.mobileSecurity?.settings?.ios?.vpp?.countryCode || "DE"

        const baseUrl: string = "https://itunes.apple.com/lookup?" + (idProperty == 'Identifier' ? 'bundleId' : 'id') + "=$idList$&media=software&entity=software,iPadSoftware&country=" + countryCode
        const baseUrlLength = baseUrl.length - 8
        const maxUrlLength = 1024

        let chunks: string[] = [];

        let thisChunk = ""
        for (let i: number = 0; i < ids.length; i++) {
            let id = ids[i]
            let thisChunkLength = thisChunk.length
            let thisIdLength = id.length
            if ((baseUrlLength + thisChunkLength + thisIdLength) < maxUrlLength) {
                // URL is shorter than max length
                if (thisChunk.length == 0) {
                    thisChunk += id
                }
                else {
                    thisChunk += "," + id
                }
                // push thisChunk to chunks array, if it is the last one
                if (i == (ids.length - 1)) {
                    chunks.push(thisChunk)
                }
            }
            else {
                // URL has reached max length
                // push thisChunk to chunks array and clear it
                chunks.push(thisChunk)
                thisChunk = id
            }
        }

        try {
            // build promise.all array
            let promises: any = []

            chunks.forEach((chunk: string) => {
                promises.push(requestHandler.request("GET", baseUrl.replace("$idList$", escape(chunk))))
            })
            let results = await Promise.all(promises)

            result.results = []
            results.forEach((thisResult: any) => {
                if (typeof thisResult == "string") {
                    thisResult = JSON.parse(thisResult)
                }
                if (thisResult?.results?.length) {
                    result.results = result.results.concat(thisResult.results)
                }
            })

        }
        catch (e: any) {
            //dialogs.mobileSecurity.renderVPPErrorDialog(e)
            console.error(e)
        }

        if (result?.results?.length) {
            result.results.forEach(function (entry: any) {
                if (idProperty == 'Identifier') {
                    resultsByAdamIdStr[entry.bundleId] = entry
                }
                else {
                    resultsByAdamIdStr[entry.trackId] = entry
                }
            })
        }

        assets.forEach(function (asset: IosApp) {
            //@ts-ignore
            if (asset[idProperty] && resultsByAdamIdStr[asset[idProperty]]) {
                //@ts-ignore
                asset.assetInfo = resultsByAdamIdStr[asset[idProperty]] || undefined
                if (asset.assetInfo) {
                    asset.assetInfo.isInStore = true
                }
            }
            else if (result?.results?.length && asset.assetInfo == undefined) {
                //@ts-ignore
                asset.assetInfo = {
                    "isInStore": false
                }
            }
        })

        return assets

    }

}

const iosApps = new IosApps({
    "productType": "mobileSecurity",
    "slug": "iosapps",
    "objectType": "iosApps",
    "hasStore": true,
    "appearance": {
        "iconClass": "fal fa-grid-2-plus",
        "text": {
            "plural": "Apps",
            "title": "Apps",
            "sidebarName": "Apps",
            "singular": "App"
        },
        "color": "red",
        "showInSidebar": true,
        "showOnDashboard": true,
    },
    "objectTypeInfo": {
        "primaryKeyProperty": {
            "property": "appId",
            "pathToPrimaryProperty": undefined
        },
        "nameProperty": {
            "primary": "name",
            "pathToPrimaryProperty": undefined,
            "secondary": undefined,
            "pathToSecondaryProperty": undefined
        }
    },
    "apiInfo": {
        "url": "/sms-mgt-api/api/2.0",
        "getCountGETProperties": "?props[]=null&select=data.count",
        // GET
        "getObjectListResponseProperty": "apps",
        "getObjectListMethod": "GET",
        "getObjectListPath": "/tenants/{tenantDomain}/apple/apps",
        // Add
        "addObjectMethod": "POST",
        "addObjectPath": "/tenants/{tenantDomain}/apple/apps",
        // Update
        "updateObjectMethod": "PUT",
        "updateObjectPath": "/tenants/{tenantDomain}/apple/apps/{objectId}",
        // Delete
        "deleteObjectPath": "/tenants/{tenantDomain}/apple/apps/{objectId}",
    }
})
export default iosApps