<template>
    <div class="row border-bottom">
        <div class="col-xs-24 padding-xs-t">
            <h5>{{ T("Endpoints") }}</h5>
            <p class="description">{{ T("Configure Securepoint Secure DNS by using the following endpoints:") }}</p>

            <div class="row padding-xs-y">
                <div class="col-xs-24 col-md-12">
                    <p>{{ T("DNS-over-HTTPS (DoH)") }}</p>
                    <div style="display: flex; align-items: center;">
                        <input style="flex-grow: 1; margin-right: 5px;" type="text" :value="dohClientName" readonly>
                        <button v-on:click="copy(dohClientName)" class="btn btn-primary" style="flex-shrink: 0;"
                            :title="T('Copy to clipboard')">
                            <i class="fal fa-copy"></i>
                        </button>
                    </div>
                </div>

                <div class="col-xs-24 col-md-12">
                    <p>{{ T("DNS-over-TLS (DoT)") }}</p>
                    <div style="display: flex; align-items: center;">
                        <input style="flex-grow: 1; margin-right: 5px;" type="text" :value="dotClientName" readonly>
                        <button v-on:click="copy(dotClientName)" class="btn btn-primary" style="flex-shrink: 0;"
                            :title="T('Copy to clipboard')">
                            <i class="fal fa-copy"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row border-bottom">
        <div class="col-xs-24 padding-xs-t">
            <h5>{{ T("Mobileconfig") }}</h5>
            <p class="description">{{ T("Configure Securepoint Secure DNS by downloading a signed mobileconfig to your Apple devices:") }}</p>

            <div class="row padding-xs-y">
                <div class="col-xs-24 col-md-12">
                    <button v-on:click="download" class="btn btn-primary" style="flex-shrink: 0;" :title="T('Download mobileconfig')">
                        <i class="fal fa-download"></i>
                        {{ T("Download") }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="row border-bottom">
        <div class="col-xs-24 padding-xs-t">
            <h5>{{ T("Identify your devices") }}</h5>
            <p class="description">{{ T("Optionally, you can assign a name to identify your devices in the statistics and logs.") }}</p>
            <p class="description">{{ T("The above endpoints are automatically updated while you type.") }}</p>

            <div class="row padding-xs-y">
                <div class="col-xs-24 col-md-12">
                    <input v-model="clientNameInput" type="text" maxlength="25" :placeholder="T('Example: Desktop PC 1')">
                    <span v-if="clientNameError" class="error-bubble label bg-red margin-xs-t">
                        <i class="fa-solid fa-triangle-exclamation" />
                        {{ T(clientNameError) }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { T } from "@/classes/i18n";
import ClipboardHandler from '@/classes/clipboard';
import frontendNotifications from '@/classes/notifications';
import { useStore } from '@/store/vuex.store';
import miscQueries from '@/queries/misc';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
})

const accountId = computed(() => {
  return useStore().state.session.activeAccountId || ''
})


const clientNameInput = ref("")
const clientNameError = ref("")

const dotClientName = computed(() => {
    let name = clientNameInput.value

    name = name.replaceAll(" ", "--")
    name = name.replace(/[^a-zA-Z0-9-]/g, '')

    if (name.length > 0) {
        name = name + '-'
    }

    return name + props.modelValue.dot
})

const dohClientName = computed(() => {
    const mainUrl = props.modelValue.doh
    const clientName = clientNameInput.value

    if (clientName.length === 0) {
        return mainUrl
    }

    return `${props.modelValue.doh}/${encodeURIComponent(clientNameInput.value)}`
})

function copy(str: string) {
    const clipboard = new ClipboardHandler()
    clipboard.copy(str)

    frontendNotifications.addNotification(accountId.value, {
        "accountId": accountId.value,
        "content": {
            "body": {
                "content": str,
            },
            "title": {
                "text": T("Endpoint successfully copied"),
                "icon": "fal fa-check"
            }
        },
        "highlightNew": true,
    })
}

function download() {
    miscQueries.download(`${props.modelValue.mobileConfig}?url=${dohClientName.value}`, 'securepoint-securedns.mobileconfig')
}

watch(clientNameInput, () => {
    if (clientNameInput.value.match(/- | - |--|^ +$|^ | $/)) {
        clientNameError.value = `Your input contains combinations of characters that may result in a different output in the statistics.`
    } else {
        clientNameError.value = ""
    }
})
</script>

<style scoped>
.description {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.9em;
    margin: 0;
}

.darkmode .description {
    color: rgba(255, 255, 255, 0.5);
}
</style>
