export interface IconOptions {
    title?:string,
    htmlTooltip?:boolean,
    onClick?:() => any,
    class:string|string[],
    
}
export default class Icon implements IconOptions  {
    title : IconOptions["title"] = ""
    htmlTooltip : IconOptions["htmlTooltip"] = false
    onClick : IconOptions["onClick"] = undefined
    class : IconOptions["class"] = "fal fa-check"
    constructor(options?:IconOptions) {
        if(options) {
            Object.assign(this,options)
        }
    }
}