<script setup lang="ts">
import { T } from "@/classes/i18n"
import buttonComponent from "../components/button.vue";
import { computed, onMounted, ref, watch } from "vue";
import Button from "@/classes/buttons";
import inputPin from "../inputtypes/input-pin.vue";
import { MutationTypes, useStore } from "@/store/vuex.store";
import getterHelpers from "@/helpers/helpers.getters";

const props = defineProps<{
    properties: {
        revertChanges: Function,
        doPublish:boolean,
        isReversible:boolean,
    }
}>();

const activeAccountId = computed(() => { return useStore().state.session.activeAccountId || "" })

const buttonOption = ref(new Button({
    "disabled": false,
    "loading": false,
    "icon": "fal fa-clock-rotate-left",
    "text": T("Revert changes"),
    "title": T("Revert not published changes"),
    "onClick": async () => {
        buttonOption.value.loading = true
        await props.properties.revertChanges();
        getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': activeAccountId.value })
        
    }
}))

const pin = ref("")

const errors = ref(<string[]>[])

const setError = (error:string) => {
    errors.value.push(error)
}
const clearErrors = () => {
    errors.value = []
}
const clearPin = () => {
    pin.value = ""
}
const onEnter = () => {
    document.getElementById('dialogbutton-1-publishConfig')?.click()
}
const checkConditions = () => {
    if (pin.value.length == 6 || !props.properties.doPublish) {
        getterHelpers.useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = false
    }
    else {
        getterHelpers.useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = true
    }
}
watch(pin, () => {
    checkConditions()
})

defineExpose({
    pin, 
    setError, 
    clearErrors,
    clearPin
})
onMounted(() => {
    checkConditions()
})

</script>
<template>
    <div class="content-2">
        <div class="flexrow">
            <div class="col-xs-24">
                <strong> {{ T("Attention:") }} </strong>
                {{ T('Deleting the core UTM removes the entire configuration from your UTMs.') }}
            </div>
        </div>
        <br>
        <div class="flexrow">
            <div class="col-xs-24">
                {{ T('Please note that this setting is published directly so that a clean reconfiguration can be started and you do not have the option of restoring the current status.') }}
            </div>
        </div>
        <br>
        <template v-if="props.properties.isReversible">
            <div class="flexrow">
                <div class="col-xs-24">
                    <span v-html='T("To retain the current configuration, please select \"<strong>Revert changes</strong>\".")'></span>
                </div>
            </div>
            <br>
            <div class="flexrow">
                <div class="col-xs-24 text-center">
                    <buttonComponent :button-options="buttonOption"></buttonComponent>
                </div>
            </div>
            <br>
        </template>
        <div class="flexrow">
            <div class="col-xs-24">
                <span v-html='T("To accept the deletion, please click on \"<strong>Delete</srong>\".")'></span>
                <span v-if="props.properties.doPublish">&nbsp;{{ T("To increase security, please enter the PIN of the Core-UTM.") }}</span>
            </div>
        </div>
        <br>
        <div class="text-center">
            <template v-for="error in errors">
                <p class="label bg-red">
                    <i class="fal fa-fw fa-exclamation"></i> {{ T(error) }}
                </p>
                <br>
            </template>
        </div>
        <div id="pinInputRow" v-if="props.properties.doPublish">
            <inputPin v-model="pin" :pinLength="6" :isDisabled="false" :numbersOnly="true" :focusOnMount="true" v-on:submit="onEnter" :hidePinOnMount="true" :canToggleVisibility="true"></inputPin>
        </div>
    </div>
</template>

<style scoped>
    #pinInputRow {
        display: flex;
        justify-content: center;
        justify-items: center;
    }
</style>