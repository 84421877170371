import ObjectType, { type ItemlistItemDetail, type Label, type MenuEntry, type ObjectTypeObject, type ObjectTypePayload } from "../../objectType";
import { T } from "@/classes/i18n"
import Button from "@/classes/buttons"
import tenantHelpers from "@/helpers/helpers.tenants"
import router from "@/router/router"

export interface TSecureDns {
  id: string
  name: string
  timeRange: {
      id: string
      schedule: number[]
  }[]
  blockedCountries: string[]
  allowlist: {
      ips: string[]
      hosts: string[]
  }
  denylist: {
      ips: string[]
      hosts: string[]
  }
  performance: {
      cacheBoost: boolean
  }
  security: {
      dnsRebindingProtection: boolean
      idnHomographProtection: boolean
      safeSearch: boolean
      youtubeRm: boolean
  }
  rewrites: {
      domain: string
      answer: string
  }[]
  logs: {
      enabled: boolean
      clientIps: boolean
      domains: boolean
  }
  blockPage: {
      enabled: boolean
  }
};


class SecureDns extends ObjectType<TSecureDns> {
  constructor(payload: ObjectTypePayload<TSecureDns>) {
    super(payload)

    this.itemlist.getSortingOptions = () => {
      return [
        {
          "id": "name",
          "text": T("Name")
        }
      ]
    }
    this.itemlist.getToolbarButtons = (accountId, itemlistComponent) => {
      let thisToolbarEntries = []

      thisToolbarEntries.push(
          {
            icon: 'fal fa-plus',
            title: T('Add profile'),
            link: '#add-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-secureDnsProfiles',
            id: 'vpnsButtonAdd',
            vIf: false
          }
        )


      return thisToolbarEntries
    }

    this.itemlistItem.hasCheckbox = () => {
      return true
    }

    this.itemlistItem.getMenuEntries = (accountId, item) => {
      let menuLinks = []
      const tenantDomain = tenantHelpers.getTenantDomain(accountId)

      menuLinks.push(
        new Button({
          title: T('Edit'),
          text: T('Edit'),
          link: '#edit-tenant-' + tenantDomain + '-secureDnsProfiles-' + item?.id,
          icon: 'fal fa-edit',
        })
    )

      menuLinks.push(
        new Button({
          title: T('Delete'),
          text: T('Delete'),
          onClick: () => {
            this.dialogs.getDeleteObjectDialog(accountId, item)
          },
          icon: 'fal fa-trash',
        })
      )
      return menuLinks
    }

    this.itemlistItem.getDetails = (accountId, item) => {
      if (!item) {
        return []
      }

      return [
        {
            iconClass: 'fal fa-fw fa-check',
            title: T('Allowed hosts'),
            key: T('Allowed hosts'),
            value: item.allowlist.hosts.length + item.allowlist.ips.length
        },
        {
            iconClass: 'fal fa-fw fa-ban',
            title: T('Blocked hosts'),
            key: T('Blocked hosts'),
            value: item.denylist.hosts.length + item.denylist.ips.length
        },
        {
          iconClass: 'fal fa-fw fa-filter',
          title: T('Filter categories'),
          key: T('Filter categories'),
          value: item.timeRange.length
        },
        {
            iconClass: 'fal fa-fw fa-globe',
            title: T('Filtered countries'),
            key: T('Filtered countries'),
            value: item.blockedCountries.length
        },
        {
            iconClass: 'fal fa-fw fa-bars',
            title: T('Logs'),
            key: T('Logs'),
            value: item.logs.enabled ? T('Enabled') : T('Disabled')
        }
      ]
    }

    this.itemlistItem.onClick = (accountId, item) => {
        router.navigate('#edit-tenant-' + accountId + '.sms-secureDnsProfiles-' + item?.id)
    }
  }
}

const secureDns = new SecureDns({
  "productType": "secureDns",
  "slug": "secureDnsProfiles",
  "objectType": "secureDnsProfiles",
  "hasStore": true,
  "appearance": {
    "iconClass": "fal fa-shield-alt",
    "text": {
      "plural": "Secure DNS",
      "title": "Secure DNS",
      "sidebarName": "Secure DNS",
      "singular": "Secure DNS"
    },
    "color": "red",
    "showInSidebar": true,
    "showOnDashboard": true,
  },
  "objectTypeInfo": {
    "primaryKeyProperty": {
      "property": "id",
      "pathToPrimaryProperty": undefined
    },
    "nameProperty": {
      "primary": "name",
      "pathToPrimaryProperty": undefined,
      "secondary": undefined,
      "pathToSecondaryProperty": undefined
    }
  },
  "apiInfo": {
    "url": "/sms-mgt-api/api/2.0",
    "getCountGETProperties": "?props[]=null&select=data.count",
    // GET
    "getObjectListResponseProperty": "securedns",
    "getObjectListMethod": "GET",
    "getObjectListPath": "/tenants/{tenantDomain}/secure-dns/configs",
    // Update
    "updateObjectMethod": "PUT",
    "updateObjectPath": "/tenants/{tenantDomain}/secure-dns/configs/{objectId}",
  },
})


export default secureDns